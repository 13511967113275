import arrow from "../../../../assets/arrow-down.svg";

function WebinarCard({
  content,
  onClick,
  webinarName,
  onStatusChange,
  isFinished,
  onAddRecord,
  webinarId,
}) {
  return (
    <div className="rounded-lg border-gray-300 border px-4 py-4 shadow-md">
      <div className="flex items-center justify-between w-full">
        <h2 className="text-xl font-semibold text-[#121212] max-w-[80%] truncate">
          {webinarName}
        </h2>

        {isFinished ? (
          <span className="w-fit rounded-lg border-[#273C97] border pr-8 pl-4 py-2 shadow-md bg-white text-sm text-[#273C97] appearance-none focus:border-gray-7e bg-no-repeat bg-[90%_center] bg-[length:0.6rem] ">
            Completed
          </span>
        ) : (
          <select
            value="notCompleted"
            onChange={(e) => onStatusChange(e.target.value, webinarId)}
            className="w-fit rounded-lg border-[#273C97] border pr-8 pl-4 py-2 shadow-md bg-white text-sm text-[#273C97] appearance-none focus:border-gray-7e bg-no-repeat bg-[90%_center] bg-[length:0.6rem] cursor-pointer"
            style={{
              backgroundImage: `url(${arrow})`,
            }}
          >
            <option value="completed"> Completed</option>
            <option value="notCompleted">Not Completed</option>
          </select>
        )}
      </div>

      <div className="grid grid-cols-2 gap-2 mt-5">
        {content.map((item, index) => (
          <div
            key={index}
            className="flex w-full  mb-4 text-[#828282] items-center"
          >
            <div className="flex items-center">
              {/* {item.icon && <item.icon className="mr-2 text-sm" />} */}
              {item.isText && <>{item.title}</>}
              {!item.isText && (
                <p className="text-sm font-semibold max-w-[90%] truncate">
                  {item.title}
                </p>
              )}
            </div>
            <p className="text-sm ">{item.content}</p>
          </div>
        ))}
      </div>

      <button
        className={` py-3 w-full mt-5 ${
          isFinished ? "btn-secondary" : "btn-primary"
        }`}
        onClick={isFinished ? onAddRecord : onClick}
      >
        {isFinished ? "Add Webinar Record" : "Join Webinar"}
      </button>
    </div>
  );
}

export default WebinarCard;
