const SearchIcon = ({ className }) => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10 19C14.4183 19 18 15.4183 18 11C18 6.58172 14.4183 3 10 3C5.58172 3 2 6.58172 2 11C2 15.4183 5.58172 19 10 19Z"
        stroke="#7E7E7E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9999 20.9999L15.6499 16.6499"
        stroke="#7E7E7E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
