import { useEffect, useState } from "react";
import FilterGroup from "../../../../Components/AdminDashboard/shared/Filters/FilterGroup";
import useFetch from "../../../../hooks/useFetch";
import {
    dashboardGigsStatusEndpoint,
    jobProfileRoundsEndPoint
} from "../../../../Services/endPoints";

const initialFilters = [
    {
        name: "Job Profile",
        key: "job_profile_round",
    },
    {
        name: "Status",
        key: "status",
    },
    {
        name: "date",
        key: "date",
        options: [
            {
                name: "date1",
                value: "1",
            },
            {
                name: "date2",
                value: "2",
            },
            {
                name: "date3",
                value: "3",
            },
        ],
    },
];

export default function WebinarsFilter() {
    const [filters, setFilters] = useState(initialFilters);
    const { data: jobProfileRounds } = useFetch(jobProfileRoundsEndPoint);
    const { data: gigsStatus } = useFetch(dashboardGigsStatusEndpoint);

    useEffect(() => {
        setFilters((prevFilters) =>
            prevFilters.map((filter) => {
                switch (filter.key) {
                    case "job_profile_round":
                        return {
                            ...filter,
                            options: jobProfileRounds
                                ? jobProfileRounds.map((round) => ({ name: round.code, value: round.code }))
                                : [],
                        };
                    case "status":
                        return {
                            ...filter,
                            options: gigsStatus
                                ? gigsStatus.map((status) => ({ name: status, value: status }))
                                : [],
                        };
                    default:
                        return filter;
                }
            })
        );
    }, [jobProfileRounds, gigsStatus]);

    return <FilterGroup filters={filters} />;
}
