import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { apiGetQuestions } from "../../../Services/apiGetQuestions";

import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import arrow from "../../../assets/arrow-down.svg";
import Loading from "../../../Components/shared/Loading";
import useFetch from "../../../hooks/useFetch";
import postQuestion from "../../../Services/apiPostQuestion";
import {
  categoryEndPoint,
  questionEndPoint,
} from "../../../Services/endPoints";
import styles from "./QA.module.css";

export default function QA() {
  const { ref, inView } = useInView();
  const [expanded, setExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState("");

  const filter =
    searchParams.get("filter") === "all" || !searchParams.get("filter")
      ? "all"
      : "my";

  const category = searchParams.get("category") || "";

  // Update endpoint based on filter and selected category
  const filteredQuestionEndPoint =
    filter === "all"
      ? `${questionEndPoint}?all=true${category ? `&category=${category}` : ""}`
      : `${questionEndPoint}${category ? `?category=${category}` : "?"}`;
  // Fetch categories and questions
  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useFetch(categoryEndPoint);

  // const {
  //   data: questionData,
  //   loading: questionLoading,
  //   error: questionError,
  // } = useFetch(filteredQuestionEndPoint);

  const {
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [
      "questions",
      searchParams.get("filter"),
      searchParams.get("category"),
    ],
    queryFn: ({ pageParam = "1" }) =>
      apiGetQuestions(pageParam, filteredQuestionEndPoint),
    initialPageParam: "1",
    getNextPageParam: (lastPage) => {
      if (lastPage.next === null) return undefined;
      const parsedUrl = new URL(lastPage.next);
      const pageNumber = parsedUrl.searchParams.get("page");
      return pageNumber;
    },
  });
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);
  useEffect(() => {
    if (categoryData && categoryData?.length > 0 && categoryData[0]?.id) {
      formik.setFieldValue("category", categoryData[0]?.id);
    }
  }, [categoryData]);

  const formik = useFormik({
    initialValues: {
      questionText: "",
      category: "",
    },
    validationSchema: Yup.object({
      questionText: Yup.string()
        .required("Question text is required")
        .max(500, "Question must be at most 500 characters"),

      category: Yup.string().required("Category is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await postQuestion(
          values.questionText,
          values.category
        );
        resetForm();
        setErrorMessage("");
        window.location.reload(); // Reload the page to fetch new questions
      } catch (error) {
        console.error("Failed to post question:", error);
        const message =
          error.response?.data?.detail ||
          "An error occurred. Please try again.";
        setErrorMessage(message);
      }
    },
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Category filter change handler
  const handleCategoryFilterChange = (event) => {
    const selectedCategory = event.target.value;
    setSelectedCategory(selectedCategory);
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set("category", selectedCategory);
    setSearchParams(updatedParams);
  };

  // Filter change handler for all/my questions
  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    const updatedParams = new URLSearchParams(searchParams);

    if (selectedFilter === "all") {
      updatedParams.set("filter", "");
      setSearchParams(updatedParams);
      return;
    }
    updatedParams.set("filter", "my");
    setSearchParams(updatedParams);
  };

  const questions = data?.pages[0]?.results || [];

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <p className="text-gray-5f text-center font-semibold w-full text-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        Something went wrong !
      </p>
    );
  }

  return (
    <div className={styles.qaContainer}>
      {/* Submit your question section */}
      <form
        onSubmit={formik.handleSubmit}
        className={styles.submitQuestionSection}
      >
        <div className="w-full flex gap-3 items-center justify-center flex-wrap">
          <input
            type="text"
            name="questionText"
            className={` w-80 border border-solid ${formik.touched.questionText && formik.errors.questionText
                ? "border-[red]"
                : "border-[#9D9D9D]"
              } ${styles.inputQA}`}
            placeholder="Write your Question"
            value={formik.values.questionText}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <select
            name="category"
            className="w-80 select-input text-base"
            style={{
              backgroundImage: `url(${arrow})`,
            }}
            value={formik.values.category}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="" disabled>
              Select Category
            </option>
            {categoryData &&
              categoryData?.map((category) => (
                <option
                  key={`${category?.id} qa-category`}
                  value={category?.id}
                >
                  {category?.name}
                </option>
              ))}
          </select>
        </div>

        {formik.touched.questionText && formik.errors.questionText ? (
          <div className="text-[red] text-[0.8rem] font-light ml-3">
            {formik.errors.questionText}
          </div>
        ) : null}
        {formik.touched.category && formik.errors.category ? (
          <div className="text-[red] text-[0.8rem] font-light ml-3">
            {formik.errors.category}
          </div>
        ) : null}

        <button type="submit" className={styles.submitQA}>
          Submit
        </button>
        <p className="text-gray-5f self-start text-base font-semibold mt-1">
          <strong>Note:</strong> The response will be within 48 hours.
        </p>
        {errorMessage && (
          <div className="border border-red-900 p-5 text-red-700">
            {errorMessage}
          </div>
        )}
      </form>

      {/* {questions.length === 0 && (
        <p className="text-gray-5f text-center text-lg font-semibold mt-5">
          This is no questions yet.
        </p>
      )} */}
      {
        <>
          <div className="flex place-content-end mb-4 gap-4">
            <select
              name="categoryFilter"
              className="select-input w-60"
              style={{
                backgroundImage: `url(${arrow})`,
              }}
              onChange={handleCategoryFilterChange}
              value={selectedCategory}
            >
              <option value="">All</option>
              {categoryData?.map((category) => (
                <option key={category?.id} value={category?.id}>
                  {category?.name}
                </option>
              ))}
            </select>
            <select
              name="allMyFilter"
              className="select-input w-60"
              style={{
                backgroundImage: `url(${arrow})`,
              }}
              onChange={handleFilterChange}
              value={filter}
            >
              <option value="my">My Questions</option>
              <option value="all">All Questions</option>
            </select>
          </div>
          <div className={styles.accordionQAContainer}>
            {!isLoading &&
              data?.pages?.map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>
                  {page.results.length === 0 && (
                    <p className="text-gray-5f text-center text-lg font-semibold mt-5">
                      No questions.
                    </p>
                  )}
                  {page.results.map((question, index) => (
                    <Accordion
                      key={question.id}
                      expanded={
                        question.answer && expanded === `panel${question.id}`
                      }
                      onChange={handleChange(`panel${question.id}`)}
                      className={`${styles.accordionQA} ${!question.answer ? styles.disabledAccordion : ""
                        }`}
                      disableGutters
                    >
                      <AccordionSummary
                        expandIcon={
                          question.answer ? (
                            <ExpandMoreIcon />
                          ) : (
                            <div className="invisible">
                              <ExpandMoreIcon />
                            </div>
                          )
                        }
                        aria-controls={`panel${question.id}-content`}
                        id={`panel${question.id}-header`}
                        className={
                          !question.answer ? styles.disabledSummary : ""
                        }
                      >
                        <div className={styles.accordionHeader}>
                          <h2 className="truncate max-w-[18rem] ">
                            {question.question_text}
                          </h2>
                          <div className="flex items-center gap-3">
                            {!question.answer && (
                              <h4 className="border-red-600 border rounded text-red-600 p-1 px-2 h-8 md:text-[1rem] text-[.4rem]">
                                Not Answered
                              </h4>
                            )}
                            <h3 className="border-[#273C97] me-2 border-2 rounded text-[#273C97] p-2 md:text-[1rem] text-[.5rem]">
                              {question.category.name}
                            </h3>
                          </div>
                        </div>
                      </AccordionSummary>
                      {question.answer && (
                        <AccordionDetails className="max-w-full break-words">
                          <p className="w-full">{question.answer.answer}</p>
                        </AccordionDetails>
                      )}
                    </Accordion>
                  ))}
                </React.Fragment>
              ))}

            {(isFetchingNextPage || isFetching) && <Loading />}

            <div
              ref={ref}
              style={{ height: "20px", background: "transparent" }}
            />
          </div>
        </>
      }
    </div>
  );
}
