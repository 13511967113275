import { Controller } from "react-hook-form";
import ErrorMessage from "../../../../shared/ErrorMessage";
import LabeledInput from "../../../../shared/LabeledInput";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PasswordInput from "../../../../shared/PasswordInput";

const CreateNewCoach = ({
  activeView,
  setActiveView,
  register,
  errors,
  control,
}) => {
  return (
    <div
      className={`mt-[1rem] px-5 ${
        activeView === "new" ? "bg-white py-5" : ""
      }`}
    >
      <div className="flex gap-2 items-center">
        <input
          id="new"
          type="radio"
          name={"new"}
          className="radio radio-primary border-solid border-gray-82 md:radio-sm radio-md"
          value={"new"}
          checked={activeView === "new"}
          onChange={() => setActiveView("new")}
        />
        <label
          htmlFor="new"
          className={`text-gray-5f text-base cursor-pointer ${
            activeView === "new" ? "text-primary text-lg font-semibold" : ""
          }`}
        >
          Create New Coach
        </label>
      </div>
      {activeView === "new" && (
        <div className="w-full flex gap-x-[15%] gap-y-4 mt-3 flex-wrap">
          <h2 className="text-sm font-semibold ms-2 text-primary">
            Basic info
          </h2>
          <div className="flex flex-col gap-4 max-w-[40rem]">
            <LabeledInput
              id={"coachCode"}
              label={"Coach Code"}
              register={register}
              placeholder={"Coach Code"}
              fieldName="coachCode"
              errors={errors}
            />
            <LabeledInput
              id={"name"}
              label={"Name"}
              register={register}
              placeholder={"Name"}
              fieldName="name"
              errors={errors}
            />

            <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
              <div>
                <label
                  htmlFor="phoneNumber"
                  className="text-primary text-base font-medium mb-2 block"
                >
                  Phone Number
                </label>
                <div className="input input-bordered rounded border-[#DFEAF2] w-full flex items-center">
                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <PhoneInput
                        className="w-full"
                        countrySelectProps={{ unicodeFlags: true }}
                        placeholder="Phone Number"
                        id="phoneNumber"
                        defaultCountry="EG"
                        onChange={onChange}
                        value={value}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </div>
                <ErrorMessage
                  isVisible={Boolean(errors.phoneNumber)}
                  message={errors.phoneNumber?.message}
                />
              </div>
              <LabeledInput
                id={"email"}
                label={"Email"}
                register={register}
                placeholder={"Email"}
                fieldName="email"
                errors={errors}
              />
            </div>
            <PasswordInput
              label="New Coach Password"
              placeholder="********"
              fieldName="password"
              register={register}
              errors={errors}
            />
            <PasswordInput
              label="Confirm Password"
              placeholder="********"
              fieldName="confirm_password"
              register={register}
              errors={errors}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateNewCoach;
