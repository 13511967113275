import React from "react";

export default function StudentGigDialog({ dialogRef, onClose, dialogData }) {
  return (
    <dialog
      ref={dialogRef}
      className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[30rem] min-h-[15rem] border-none bg-white rounded-xl outline-none p-5 overflow-visible shadow-lg"
    >
      <button
        onClick={onClose}
        className="text-[#71747B] hover:text-gray-900 flex ml-auto"
      >
        ✖
      </button>
      <div className="mt-2 flex items-center gap-4">
        <h2 className="text-lg font-semibold text-[#202020]">Job link:</h2>
        <a
          href={dialogData?.url}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-primary px-3 py-2"
        >
          View Gig
        </a>
      </div>
    </dialog>
  );
}
