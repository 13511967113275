import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import StudentAvatar from '../../../../Components/svgs/StudentAvatar';
import useFetch from '../../../../hooks/useFetch';
import { dashboardStudentsEndPoint } from '../../../../Services/endPoints';

export default function StudentInfo() {
    const { id } = useParams();
    const { data: studentData } = useFetch(`${dashboardStudentsEndPoint}${id}/`);

    const handleCopy = () => {
        if (studentData?.id) {
            navigator.clipboard.writeText(studentData.id);
            toast.success('ID copied to clipboard!');
        }
    };

    return (
        <div className='bg-white rounded-xl shadow-lg p-6'>
            {/* Header Section */}
            <div className='flex justify-between items-center mb-6'>
                {/* Profile Image */}
                <div className='flex items-center gap-4'>
                    <div className='w-20 h-20 bg-[#F1F8FF] rounded-2xl flex justify-center items-center'>
                        {studentData?.profile_image && (
                            <img
                                src={studentData.profile_image}
                                alt={`${studentData?.full_name}'s profile`}
                                className='w-full h-full object-cover rounded-2xl'
                            />
                        )}
                        {!studentData?.profile_image && <StudentAvatar />}
                    </div>
                    {/* Name and Status */}
                    <div>
                        <h2 className='text-xl font-medium text-[#273C97]'>
                            {studentData?.full_name || 'Unknown'}
                        </h2>
                        <span
                            className={`text-sm font-semibold ${studentData?.user?.is_joined && 'text-green-600'
                                } ${!studentData?.user?.is_joined && 'text-red-600'}`}
                        >
                            &#9679; {studentData?.user?.is_joined && 'Active'}
                            {!studentData?.user?.is_joined && 'Inactive'}
                        </span>
                    </div>
                </div>
                {/* Student ID */}
                <div className='border border-[#D3D2D2] rounded-md px-2'>
                    <div className='flex items-center gap-2'>
                        {studentData?.id && (
                            <button
                                onClick={handleCopy}
                                className='p-1 rounded hover:bg-gray-100'
                                aria-label='Copy ID'
                            >
                                <ContentCopyIcon fontSize='small' className='text-[#ACACAC]' />
                            </button>
                        )}
                        <p className='text-lg text-[#273C97]'>{studentData?.id || 'N/A'}</p>
                    </div>
                </div>
            </div>

            {/* Details Section */}
            <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                {/* Phone */}
                <div>
                    <h2 className='text-base text-[#273C97]'>Phone</h2>
                    <p className='text-base text-[#45464E]'>
                        {studentData?.phone || 'N/A'}
                    </p>
                </div>

                {/* Email */}
                <div>
                    <h2 className='text-base text-[#273C97]'>Email</h2>
                    <a
                        href={`mailto:${studentData?.user?.email}`}
                        className='text-base text-blue-500 underline'
                    >
                        {studentData?.user?.email || 'N/A'}
                    </a>
                </div>

                {/* Track */}
                <div>
                    <h2 className='text-base text-[#273C97]'>Track</h2>
                    <p className='text-base text-[#45464E]'>
                        {studentData?.track || 'N/A'}
                    </p>
                </div>

                {/* Coaching Rounds */}
                <div>
                    <h2 className='text-base text-[#273C97]'>Coaching Rounds</h2>
                    <p className='text-base text-[#45464E]'>
                        {studentData?.coaching_rounds || 'N/A'}
                    </p>
                </div>

                {/* Job Profiles */}
                <div>
                    <h2 className='text-base text-[#273C97]'>Job Profiles</h2>
                    <p className='text-base text-[#45464E]'>
                        {studentData?.job_profile_round || 'N/A'}
                    </p>
                </div>

                {/* Technical Provider */}
                <div>
                    <h2 className='text-base text-[#273C97]'>Technical Provider</h2>
                    <p className='text-base text-[#45464E]'>
                        {studentData?.technical_provider || 'N/A'}
                    </p>
                </div>
            </div>
        </div>
    );
}
