import axios from "axios";
import { controlPanelChangePasswordEndPoint } from "../endPoints";

export const apiPostChangePassword = async (data) => {
  const response = await axios.post(
    `${controlPanelChangePasswordEndPoint}`,
    data
  );
  return response;
};
