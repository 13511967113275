import { LuCopy } from "react-icons/lu";
import { useState } from "react";
import { IoCheckmark } from "react-icons/io5";
import { twMerge } from "tailwind-merge";
const CopyId = ({ id, className }) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(id);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };
  return (
    <button
      className={twMerge(
        "flex items-center gap-2 border border-solid border-[#D3D2D2] rounded-md px-3 py-1 min-w-[7rem]",
        className
      )}
      onClick={handleCopy}
    >
      {id && !copied && <LuCopy className="size-[1.2rem] text-[#ACACAC]" />}
      {copied && <IoCheckmark className="size-[1.2rem] text-[#ACACAC]" />}
      <p className={`text-base font-light text-primary `}>
        {!copied ? id || "N/A" : "Copied!"}
      </p>
    </button>
  );
};

export default CopyId;
