import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import apiPostWebinar from '../../../../../Services/apiPostWebinar';

const schema = yup.object({
    name: yup
        .string()
        .required('Webinar Topic is required')
        .max(70, 'Maximum 70 characters allowed'),
    date: yup.string()
        .required('Date is required')
        .matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format (YYYY-MM-DD)')
        .test('not-in-past', 'Date cannot be in the past', (value) => {
            if (!value) return false;
            const today = new Date();
            const selectedDate = new Date(value);
            today.setHours(0, 0, 0, 0);
            return selectedDate >= today;
        }),
    time: yup.string()
        .required('Time is required')
        .matches(/^\d{2}:\d{2}:\d{2}$/, 'Invalid time format (HH:mm:ss)')
        .test('not-in-past', 'Time cannot be in the past for the selected date', function (value) {
            const { date } = this.parent;
            if (!value || !date) return false;

            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const selectedDate = new Date(date);
            if (selectedDate > today) {
                return true;
            }

            const now = new Date();
            const [hours, minutes, seconds] = value.split(':').map(Number);
            const selectedTime = new Date();
            selectedTime.setHours(hours, minutes, seconds, 0);
            return selectedTime >= now;
        }),
    instructor_id: yup.string().required('Coach is required'),
    job_profile_round: yup.string().required('Job Profile Round is required'),
    group: yup.string().required('Group is required'),
    link: yup
        .string()
        .url('Enter a valid URL https://....')
        .matches(/^https:\/\//, 'URL must start with https://')
        .required('Webinar Link is required'),
});

export function useAddWebinarForm(closeDialog) {
    const mutation = useMutation({
        mutationFn: apiPostWebinar,
    });
    const { isPending } = mutation;
    const form = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });
    const onSubmit = (data) => {
        mutation.mutate(data, {
            onSuccess: () => {
                toast.success('Webinar updated successfully');
                form.reset();
                if (typeof closeDialog === 'function') {
                    closeDialog();
                } else {
                    console.error('closeDialog is not a function');
                }
            },
            onError: (err) => {
                form.setError('root', {
                    message: err.response?.data?.message || 'Something went wrong',
                });
                console.error('Submission error:', err.response?.data || err);
            },
        });
    };
    return { ...form, isPending, onSubmit };
}
