import React from "react";

export default function InstructorIcon({ className }) {
  return (
    <svg
      width={16}
      height={10}
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.2202 4.29982C11.2202 5.34745 12.0696 6.19685 13.1177 6.19685C14.1651 6.19685 15.0145 5.34747 15.0145 4.29982C15.0145 3.25222 14.165 2.40234 13.1177 2.40234C12.0696 2.40236 11.2202 3.25222 11.2202 4.29982Z"
        fill="currentcolor"
      />
      <path
        d="M13.341 7.27464L13.5611 8.71898H13.8245L14.2336 6.57102C14.1933 6.56795 14.1532 6.56494 14.1123 6.56494H12.123C12.0822 6.56494 12.0417 6.56795 12.0015 6.57102L12.4111 8.71898H12.6739L12.894 7.27464H12.9017L12.6856 6.87842H13.5487H13.5492L13.333 7.27464H13.341Z"
        fill="white"
      />
      <path
        d="M0.984375 4.29982C0.984375 5.34745 1.83334 6.19685 2.88185 6.19685C3.9296 6.19685 4.77907 5.34747 4.77907 4.29982C4.77907 3.25222 3.9296 2.40234 2.88185 2.40234C1.83334 2.40236 0.984375 3.25222 0.984375 4.29982Z"
        fill="currentcolor"
      />
      <path
        d="M11.9272 9.02516V7.85655C12.1053 8.20976 12.2131 8.60459 12.2217 9.02516H16V8.15928C16 7.17472 15.2021 6.37575 14.2167 6.37575H12.0201C11.8357 6.37575 11.6595 6.40798 11.4925 6.46014C11.0537 5.82913 10.3236 5.41504 9.49696 5.41504H6.5033C5.67651 5.41504 4.94673 5.82913 4.50754 6.46061C4.34066 6.40801 4.1643 6.37575 3.98 6.37575H1.7834C0.798456 6.37575 0 7.17474 0 8.15928V9.02516H3.78545C3.79326 8.6099 3.89912 8.22016 4.07305 7.87002V9.02516H11.9272ZM12.1238 6.56455H14.1132C14.154 6.56455 14.1942 6.56755 14.2344 6.57062L13.8253 8.71858H13.5619L13.3419 7.27425H13.3338L13.55 6.87803H13.5495H12.6865L12.9026 7.27425H12.8949L12.6748 8.71858H12.4119L12.0023 6.57062C12.0426 6.56755 12.083 6.56455 12.1238 6.56455ZM6.64455 5.67162H9.35598C9.41136 5.67162 9.46621 5.67598 9.52055 5.67994L8.96277 8.60772H8.60416H7.3962H7.03657L6.47918 5.67994C6.53407 5.67596 6.58889 5.67162 6.64455 5.67162ZM3.58858 8.71858H3.32508L3.10541 7.27425H3.0976L3.31331 6.87803H2.44973L2.66593 7.27425H2.65837L2.43806 8.71858H2.17494L1.7661 6.57062C1.80608 6.56755 1.84593 6.56455 1.88708 6.56455H3.87657C3.91746 6.56455 3.95797 6.56755 3.99768 6.57062L3.58858 8.71858Z"
        fill="currentcolor"
      />
      <path
        d="M3.10494 7.27464L3.32461 8.71898H3.58811L3.99721 6.57102C3.95748 6.56795 3.91699 6.56494 3.8761 6.56494H1.88661C1.84546 6.56494 1.80561 6.56795 1.76562 6.57102L2.17447 8.71898H2.43759L2.6579 7.27464H2.66546L2.44926 6.87842H3.31284L3.09713 7.27464H3.10494Z"
        fill="white"
      />
      <path
        d="M5.41406 2.58545C5.41406 4.01359 6.57189 5.17131 8.00002 5.17131C9.42739 5.17131 10.5852 4.01361 10.5852 2.58545C10.5852 1.15764 9.42739 0 8.00002 0C6.57189 0 5.41406 1.15764 5.41406 2.58545Z"
        fill="currentcolor"
      />
      <path
        d="M8.30517 6.63951H8.30555L8.60467 8.60798H8.96329L9.52107 5.6802C9.46673 5.67622 9.41188 5.67188 9.3565 5.67188H6.64506C6.58943 5.67188 6.53458 5.67624 6.47974 5.6802L7.03713 8.60798H7.39676L7.69576 6.63951H7.70679L7.41279 6.09904H8.58909L8.29456 6.63951H8.30517Z"
        fill="white"
      />
      <path
        d="M7.39697 8.60757H8.60491L8.30578 6.63911H8.3054H8.29475L8.58928 6.09863H7.41298L7.707 6.63911H7.69597L7.39697 8.60757Z"
        fill="currentcolor"
      />
    </svg>
  );
}
