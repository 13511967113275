import { Link } from "react-router-dom";

const stats = [
  {
    title: "All Coaches",
    name: "coaches",
  },
  {
    title: "Average response time",
    name: "average_answer_response_time",
  },
  {
    title: "Answer Questions",
    name: "answered_questions",
  },
  {
    title: "Pricing Questions",
    name: "pricing_questions",
  },
  {
    title: "Proposal Questions",
    name: "proposal_questions",
  },
  {
    title: "Tech Questions",
    name: "tech_questions",
  },
];
const QAStats = ({ data }) => {
  return (
    <div className="bg-[#F9F9FA] px-[1.5rem] pt-4 pb-[3rem] shadow rounded tablet:w-[18rem] w-[22rem]">
      <div className="w-full flex justify-between">
        <h2 className="tablet:text-sm text-base font-semibold">Q & A</h2>
        <Link
          to={"/qa"}
          className="text-white text-center rounded-full bg-primary hover:bg-primary-light transition-all duration-300 ease-in-out py-1 px-6 ms-auto tablet:text-xs text-sm"
        >
          View All
        </Link>
      </div>

      <div className="flex flex-col gap-5 w-full mt-[1.5rem]">
        {stats.map((stat) => (
          <div className="w-full flex justify-between">
            <span className="whitespace-nowrap tablet:text-sm text-base font-semibold">
              {stat.title}
            </span>
            <span className="tablet:text-sm text-base">
              {data?.[stat.name]}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QAStats;
