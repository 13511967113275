export const pageRoutes = {
  studentDashboard: "student-dashboard",
  dashboard: "dashboard",
  webinars: "webinars",
  gigs: "gigs",
  deliverables: "deliverables",
  reviews: "reviews",
  course: "course",
  lesson: "lesson",
  aiReviewer: "aiReviewer",
  history: "history",
  historyDetail: "history-detail",
  qa: "qa",
  settings: "settings",
  unauthorized: "unauthorized",
  login: "login",
  students: "students",
  studentDetails: "details",
  instructors: "instructors",
  instructorDetails: "details",
  aiInterviewing: "aiInterviewing",
  controlPanelStudents: "control-panel/cp-students",
  controlPanelInstructors: "control-panel/cp-instructors",
  controlPanelCreateNewGroup: "control-panel/create-new-group",
  controlPanelJobProfile: "job-profile",
  controlPanelCoachRound: "coach-round",
  controlPanelUploadStudents: "upload-students",
  controlPanelWebinars: "control-panel/cp-webinars",
  controlPanelGigs: "control-panel/cp-gigs",
};
export const pageLinks = {
  login: "/login",
  landingPage: "/",
};
export const roles = {
  studentRole: "student",
  instructorRole: "instructor-All",
  webinarInstructorRole: "instructor-Webinar",
  qaInstructorRole: "instructor-Q/A",
  admin: "staff",
  adminEditor: "staff-editor",
};
