import React from 'react';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IoCalendarClear } from 'react-icons/io5';
import ErrorMessage from '../../../../shared/ErrorMessage';
import dateFormat from "dateformat";

export default function DateTimePicker({ control, name, errors, label, type }) {
    return (
        <div className='my-5'>
            <label htmlFor={name} className='block text-sm font-medium text-gray-700'>
                {label}
            </label>
            <div className='mt-1 relative rounded-md shadow-sm'>
                {type === 'date' ? (
                    <Controller
                        control={control}
                        name={name}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <DatePicker
                                    id={name}
                                    selected={value ? new Date(value) : null}
                                    onChange={(date) =>
                                        onChange(date ? dateFormat(date, 'yyyy-MM-dd') : '')
                                    }
                                    dateFormat='yyyy-MM-dd'
                                    placeholderText='Date'
                                    className='block w-full h-10 pl-4 border-[#DFEAF2] rounded-md shadow-sm sm:text-sm placeholder:text-base'
                                    minDate={new Date()}
                                />
                                <IoCalendarClear className='absolute inset-y-3 right-3 flex items-center pointer-events-none text-gray-400' />
                            </>
                        )}
                    />
                ) : (
                    <input
                        type='time'
                        placeholder='Time'
                        id={name}
                        className='block w-full h-10 px-3 border-[#DFEAF2] rounded-md shadow-sm sm:text-sm'
                        {...control.register(name)}
                    />
                )}
            </div>
            {errors[name] && (
                <ErrorMessage
                    message={errors[name]?.message}
                    isVisible={Boolean(errors[name])}
                />
            )}
        </div>
    );
}
