import { useState } from "react";
import { FiFile } from "react-icons/fi";
import { twMerge } from "tailwind-merge";
import ErrorMessage from "../../shared/ErrorMessage";

const supportedFormats = ["CSV"];
const maxSize = 50;
const UploadCSVInput = ({ file, setFile }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState("");

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setIsDragging(true);
    } else if (e.type === "dragleave") {
      setIsDragging(false);
    }
  };

  const onFileSelect = (file) => {
    setFile(file);
    setError(undefined);
  };

  const validateFile = (file) => {
    setError(null);

    // Check file size
    if (file.size > maxSize * 1024 * 1024) {
      setError(`File size must be less than ${maxSize}MB`);
      return false;
    }

    // Check file type
    const fileType = file.type.split("/")[1]?.toUpperCase();
    if (!supportedFormats.includes(fileType)) {
      setError(`Supported formats are: ${supportedFormats.join(", ")}`);
      return false;
    }

    return true;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files?.[0];
    if (file && validateFile(file)) {
      onFileSelect(file);
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files?.[0];

    if (file && validateFile(file)) {
      onFileSelect(file);
    }
  };
  return (
    <div className="bg-white p-3">
      <div
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        className={twMerge(
          "relative flex flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 w-[20rem] aspect-video  transition-colors",
          isDragging && "border-primary bg-muted/50"
        )}
      >
        <label
          htmlFor="file-upload"
          className="relative w-full h-full flex items-center justify-center text-center text-[#242634] text-sm cursor-pointer"
        >
          Click to browse or
          <br />
          drag and drop your files
        </label>
        <input
          id="file-upload"
          type="file"
          className="hidden"
          onChange={handleFileSelect}
          accept={supportedFormats
            .map((format) => `.${format.toLowerCase()}`)
            .join(",")}
        />
      </div>
      {file && (
        <div className="mt-2 text-sm flex items-center gap-3 bg-gray-200 text-black-12 px-3 py-3 rounded max-w-[20rem]">
          <FiFile className="size-[1.2rem] text-[#ACACAC]" />
          <span> {file.name}</span>
        </div>
      )}
      {error && (
        <ErrorMessage centered isVisible={Boolean(error)} message={error} />
      )}
    </div>
  );
};

export default UploadCSVInput;
