import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import group from "../../../assets/Group2.png";
import student from "../../../assets/student.png";
import DownloadCsvButton from "../../../Components/AdminDashboard/shared/DownloadCsvButton";
import StudentFilters from "../../../Components/AdminDashboard/StudentPage/StudentFilters";
import { pageRoutes } from "../../../constants";
import useFetch from "../../../hooks/useFetch";
import usePaginationNumbers from "../../../hooks/usePaginationNumbers";
import {
  dashboardStudentsEndPoint,
  dashboardStudentsStatisticsEndPoint,
} from "../../../Services/endPoints";
import SearchInput from "../Common/SearchInput/SearchInput";
import Table from "../Common/Table/Table";
import TableSkeleton from "../Common/Table/TableSkeleton";
import CardsInfo from "../components/CardsInfo";
import Header from "../components/Header";

function Students() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const dynamicStudentsEndpoint = `${dashboardStudentsEndPoint}?${searchParams.toString()}`;

  const {
    data: dashboardStudentsData,
    page,
    totalPages,
    isLoading,
    error,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    nextPage,
    prevPage,
    setRowsPerPage: handleRowsPerPageChange,
  } = usePaginationNumbers(dynamicStudentsEndpoint);

  const results = dashboardStudentsData || [];

  const handleRowClick = (row) => {
    navigate(`/${pageRoutes.students}/${pageRoutes.studentDetails}/${row.id}`);
  };

  // const
  const { data: dashboardStudentStatisticsData } = useFetch(
    dashboardStudentsStatisticsEndPoint
  );

  // Cards Data
  const studentCards = [
    {
      img: <img src={group} alt="group" />,
      title: "Students",
      value: dashboardStudentStatisticsData?.total_students,
      active: dashboardStudentStatisticsData?.joined_students,
    },
    {
      img: <img src={student} alt="student" />,
      title: "Completed one gig",
      value: dashboardStudentStatisticsData?.completed_gigs,
    },
    {
      img: <img src={student} alt="student" />,
      title: "Completed course",
      value: dashboardStudentStatisticsData?.complete_course,
    },
  ];

  // Table Columns
  const tableColumns = [
    { header: "Student ID", accessor: "id" },
    { header: "Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "City", accessor: "city" },
    { header: "Job Profiles", accessor: "jobProfiles" },
    { header: "Coaching Rounds", accessor: "coachingRounds" },
    {
      header: "Course Progress",
      accessor: "course-progress",
    },
    { header: "Status", accessor: "status" },
  ];

  // Map API Data to Table Rows
  const tableData = results.map((student) => ({
    id: student.id, // Student ID
    name: student.full_name, // Full Name
    email: student.user.email, // Email from user object
    phone: student.phone, // Phone
    city: student.city ?? <>&#8211;</>,
    "course-progress": student.course_progress_precentage ?? "",
    jobProfiles: student.job_profile_round || "None", // Job Profile Round
    coachingRounds: student.coaching_rounds || "None", // Coaching Rounds
    status: student.user.is_joined ? "Active" : "Inactive", // Status
  }));

  return (
    <div className="p-4">
      <Header>
        <CardsInfo cards={studentCards} />
      </Header>

      {/* Filters Component */}
      <div className="w-full flex justify-between">
        <StudentFilters />
        <SearchInput placeholder="Search by student email" />
      </div>

      <div className="mt-6">
        <DownloadCsvButton
          text={"Export Students"}
          endpoint={dashboardStudentsEndPoint}
          csvFileName={"students"}
        />
        {isLoading ? (
          <TableSkeleton columns={tableColumns.length} rows={5} />
        ) : error ? (
          <p>Error fetching data</p>
        ) : results.length > 0 ? (
          <Table
            columns={tableColumns}
            data={tableData}
            rowsPerPageOptions={[5, 10, 20]}
            isLoading={isLoading}
            currentPage={page}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            prevPage={prevPage}
            nextPage={nextPage}
            onRowClick={handleRowClick}
          />
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
}

export default Students;
