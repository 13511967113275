import { useCookies } from "react-cookie";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { pageLinks } from "./constants";
import { roleRoutesLookup } from "./constants/roleRoutesLookup";
import Login from "./NewPages/Common/Login/Login";
import LandingPage from "./NewPages/LandingPage/LandingPage";
import axios from "axios";

function App() {
  const [cookies] = useCookies(["role", "access-token"]);
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  axios.defaults.headers.common["Authorization"] =
    `jwt ${cookies["access-token"]}` || "";
  axios.defaults.headers.common["accept"] = "application/json";

  return (
    <Router>
      <Routes>
        {/* Protected Routes */}
        {cookies.role && cookies["access-token"] ? (
          <Route path="/*" element={roleRoutesLookup[cookies.role]} />
        ) : (
          <>
            <Route path={pageLinks.login} element={<Login />} />
            <Route path={pageLinks.landingPage} element={<LandingPage />} />
            <Route
              path="/*"
              element={<Navigate to={pageLinks.landingPage} />}
            />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
