import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { format } from "date-fns";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import * as Yup from "yup";
import CustomButton from "../../../../../Components/shared/CustomButton";
import CalenderIcon from "../../../../../Components/svgs/CalenderIcon";
import useFetch from "../../../../../hooks/useFetch";
import { UserProfileURL } from "../../../../../Services/endPoints";
import dateFormat from "dateformat";
import apiPostWebinar from "../../../../../Services/apiPostWebinar";

const WebinarSchema = Yup.object().shape({
  name: Yup.string()
    .required("Webinar Topic is required")
    .max(70, "Maximum 70 characters allowed"),
  job_profile_round: Yup.string().required("Job Profile Code is required"),
  link: Yup.string()
    .url("Enter a valid URL https://....")
    .matches(/^https:\/\//, "URL must start with https://")
    .required("Webinar Link is required"),
  date: Yup.date()
    .required("Date is required")
    .test("not-in-past", "Date cannot be in the past", (value) => {
      if (!value) return false;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return value >= today;
    }),
  time: Yup.string()
    .required("Start Time is required")
    .test(
      "not-in-past",
      "Time cannot be in the past for the selected date",
      function (value) {
        const { date } = this.parent; // Access the date field
        if (!value || !date) return false;

        const selectedDate = new Date(date);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (selectedDate > today) {
          return true;
        } else {
          const now = new Date();
          const formattedTime = dateFormat(value, "HH:MM:ss");
          const [hours, minutes] = formattedTime.split(":");
          const selectedTime = new Date();
          selectedTime.setHours(hours, minutes, 0, 0);
          return selectedTime >= now;
        }
      }
    ),
  group: Yup.string()
    .oneOf(["1", "2"], "Invalid group selected")
    .required("Group is required"),
});

const AddWebinarModal = ({ isOpen, onClose, onSubmit }) => {
  const { data: instructorProfileData } = useFetch(UserProfileURL);
  const jobProfileRounds = instructorProfileData?.job_profile_rounds || [];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg w-[90%] sm:w-[60%] lg:w-[40%]">
        <h2 className="text-xl text-[#3F3F3F] font-bold mb-8">
          Add New Webinar
        </h2>
        <Formik
          initialValues={{
            name: "",
            job_profile_round: "",
            link: "",
            date: null,
            time: null,
            group: "",
            is_finished: false,
          }}
          validationSchema={WebinarSchema}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            const formattedDate = format(values.date, "yyyy-MM-dd");
            const formattedTime = dateFormat(values.time, "HH:MM:ss");

            try {
              const webinarData = {
                name: values.name,
                job_profile_round: values.job_profile_round,
                link: values.link,
                date: formattedDate,
                time: formattedTime,
                group: values.group,
              };
              await apiPostWebinar(webinarData);
              toast.success("Webinar added successfully!");
              onSubmit(); // Notify parent component to update the list
              onClose(); // Close modal after submission
            } catch (error) {
              const backendErrors = error.response?.data || {};
              setErrors(backendErrors);
              const errorMsg = backendErrors.detail || "Failed to add webinar.";
              toast.error(errorMsg);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form>
              {/* Name */}
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Webinar Topic
                </label>
                <Field
                  type="text"
                  name="name"
                  id="name"
                  className={`w-full border rounded-lg p-2 ${errors.name && touched.name ? "border-red-500" : ""
                    }`}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Webinar Link */}
              <div className="mb-4">
                <label
                  htmlFor="link"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Webinar Link
                </label>
                <Field
                  type="url"
                  name="link"
                  id="link"
                  className={`w-full border rounded-lg p-2 ${errors.link && touched.link ? "border-red-500" : ""
                    }`}
                />
                <ErrorMessage
                  name="link"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Date */}
              <div className="mb-4 ">
                <label
                  htmlFor="date"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Date
                </label>
                <div className="relative">
                  <DatePicker
                    selected={values.date}
                    onChange={(date) => setFieldValue("date", date)}
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    className={`w-full border rounded-lg p-2 cursor-pointer ${errors.date && touched.date ? "border-red-500" : ""
                      }`}
                  />

                  <CalenderIcon className="absolute right-3 top-[50%] translate-y-[-50%] w-[1.2rem] pointer-events-none" />
                </div>
                <ErrorMessage
                  name="date"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Time */}
              <div className="mb-4 w-full">
                <label
                  htmlFor="time"
                  className="block text-gray-700 font-medium mb-2 w-full"
                >
                  Start Time
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    sx={{ width: "100%" }}
                    value={values.time}
                    onChange={(time) => setFieldValue("time", time)}
                    renderInput={({ inputProps }) => (
                      <input
                        {...inputProps}
                        className={`w-full border rounded-lg p-2 ${errors.time && touched.time ? "border-red-500" : ""
                          }`}
                      />
                    )}
                  />
                </LocalizationProvider>

                <ErrorMessage
                  name="time"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Job Profile Code */}
              <div className="mb-4">
                <label
                  htmlFor="job_profile_round"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Job Profile Code
                </label>
                <Field
                  as="select"
                  name="job_profile_round"
                  id="job_profile_round"
                  className={`w-full border rounded-lg p-2 ${errors.job_profile_round && touched.job_profile_round
                      ? "border-red-500"
                      : ""
                    }`}
                >
                  <option value="">Select Job Profile Code</option>
                  {jobProfileRounds.map((round, index) => (
                    <option key={index} value={round.code}>
                      {round.code}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="job_profile_round"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Group */}
              <div className="mb-4">
                <label
                  htmlFor="group"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Group
                </label>
                <Field
                  as="select"
                  name="group"
                  className={`w-full border rounded-lg p-2 ${errors.group && touched.group ? "border-red-500" : ""
                    }`}
                >
                  <option value="">Select Group</option>
                  <option value="1">Group 1</option>
                  <option value="2">Group 2</option>
                </Field>
                <ErrorMessage
                  name="group"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="flex justify-end gap-4">
                <CustomButton type="submit" className="">
                  {isSubmitting ? "Submitting..." : "Add Webinar"}
                </CustomButton>
                <button
                  type="button"
                  className="p-3 border-solid border border-[#EE201C] rounded-lg hover:opacity-80 text-[#EE201C]"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddWebinarModal;
