import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { apiPatchStudentProfile } from "../../../../../../Services/ControlPanel/apiPatchStudentProfile";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const schema = yup.object({
  fullName: yup
    .string()
    .required("Full name is required")
    .min(3, "Full name must be at least 3 characters"),
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email address"),
  track: yup.string().required("Track is required"),
  coachingRounds: yup.string().required("Coaching rounds is required"),
  jobProfile: yup.string().required("Job profile is required"),
  group: yup.string().required("Group is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .min(8, "Phone number must be at least 8 characters")
    .max(15, "Phone number must be at most 15 characters"),
});

export const useEditStudentForm = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: apiPatchStudentProfile,
  });
  const { isPending } = mutation;
  const form = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const onSubmit = async (data) => {
    const requestBody = {
      id,
      email: data.email,
      full_name: data.fullName,
      phone: data.phoneNumber,
      coaching_rounds: data.coachingRounds,
      job_profile_round: data.jobProfile,
      group: data.group,
      track: data.track,
    };
    mutation.mutate(
      {
        id,
        requestBody,
      },
      {
        onSuccess: () => {
          toast.success("Profile updated successfully");
          queryClient.invalidateQueries({ queryKey: ["student-profile"] });
        },
        onError: (err) => {
          form.setError("root", {
            message: err.response.data.message || "Some thing went wrong",
          });
        },
      }
    );
  };
  return { ...form, isPending, onSubmit };
};
