import React from "react";

export default function WebinarsIcon({ className }) {
  return (
    <svg
      width={12}
      height={13}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.6832 1.64069C11.6832 1.26608 11.3796 0.962402 11.0049 0.962402H0.994938C0.62033 0.962402 0.31665 1.26608 0.31665 1.64069L0.31669 9.71539C0.31669 10.1971 0.700666 10.5878 1.18262 10.5878H4.94944V11.2523H3.49829C3.20689 11.2523 2.9706 11.4886 2.9706 11.78C2.9706 11.9258 3.02963 12.0576 3.12519 12.1531C3.22076 12.2487 3.35249 12.3077 3.49829 12.3077H8.50159C8.79299 12.3077 9.02927 12.0714 9.02927 11.78C9.02927 11.6342 8.97025 11.5025 8.87468 11.4069C8.77911 11.3114 8.64739 11.2523 8.50159 11.2523H7.05043V10.5878H10.8173C11.2992 10.5878 11.6832 10.1971 11.6832 9.71538L11.6832 1.64069ZM5.99994 10.0021C5.71928 10.0021 5.49179 9.77459 5.49179 9.49393C5.49179 9.21327 5.71929 8.98579 5.99994 8.98579C6.28059 8.98579 6.50808 9.21328 6.50808 9.49393C6.50808 9.77459 6.28059 10.0021 5.99994 10.0021ZM11.0628 8.40547H0.937059V1.81365C0.937059 1.68616 1.04041 1.58281 1.1679 1.58281H10.832C10.9595 1.58281 11.0628 1.68616 11.0628 1.81365L11.0628 8.40547Z"
        fill="currentcolor"
      />
      <path
        d="M7.49316 6.84909C7.66197 6.84909 7.79882 6.71564 7.79882 6.55103V5.42115C7.79882 5.00552 7.48616 4.65296 7.0645 4.59297L6.61871 4.52944L6.58385 5.23902L6.29281 6.03029L6.15036 4.9646L6.34944 4.62849C6.37374 4.58757 6.34329 4.53589 6.29454 4.53589L5.99987 4.48145L5.7052 4.5359C5.65662 4.5359 5.62601 4.58759 5.65046 4.6285L5.84954 4.96461L5.7071 6.03029L5.4159 5.23903L5.38119 4.52945L4.93524 4.59297C4.51358 4.65297 4.20109 5.00553 4.20093 5.42116V6.55104C4.20093 6.71565 4.33778 6.84909 4.50658 6.84909H7.49316V6.84909Z"
        fill="currentcolor"
      />
      <path
        d="M7.05611 3.13564V3.25152C7.05611 3.34378 6.98175 3.41905 6.88845 3.4237C6.82371 3.64981 6.68878 3.90043 6.54067 4.04692C6.39804 4.18808 6.20779 4.26583 6.00498 4.26583C5.80207 4.26583 5.61182 4.18808 5.46919 4.04692C5.32118 3.90043 5.18615 3.64981 5.12151 3.4237C5.02811 3.41906 4.95386 3.34378 4.95386 3.25152V3.13564C4.95386 3.08448 4.9964 3.04299 5.04888 3.04299H5.07289C5.05101 2.87565 5.01869 2.53998 5.08717 2.39764C5.18017 2.20412 5.37244 2.14969 5.37244 2.14969C5.37244 2.14969 5.81473 1.65202 6.38933 1.89305C6.64431 2.00004 6.83203 2.21716 6.90821 2.47736C6.95309 2.63067 6.98095 2.82705 6.93982 3.04299H6.96099C7.01346 3.04299 7.05611 3.08448 7.05611 3.13564Z"
        fill="currentcolor"
      />
      <path
        d="M3.84949 7.55346C3.83996 7.64366 3.84347 7.73337 3.8592 7.82006H2.71301C2.62063 7.82006 2.54565 7.74508 2.54565 7.6527C2.54565 7.56033 2.62063 7.48535 2.71301 7.48535H3.85937C3.85518 7.50778 3.85184 7.53054 3.84949 7.55346Z"
        fill="currentcolor"
      />
      <path
        d="M9.45424 7.6527C9.45424 7.74508 9.37926 7.82006 9.28689 7.82006H5.32031C5.3245 7.79763 5.32784 7.77487 5.33018 7.75194C5.33972 7.66174 5.33621 7.57221 5.32031 7.48535H9.28689C9.37926 7.48535 9.45424 7.56033 9.45424 7.6527Z"
        fill="currentcolor"
      />
      <path
        d="M5.07005 7.48535C4.99524 7.27047 4.77986 7.12102 4.53452 7.14697C4.33738 7.16772 4.17254 7.30361 4.10928 7.48535C4.09656 7.52117 4.08802 7.55865 4.08401 7.59748C4.07564 7.67597 4.08534 7.75144 4.10945 7.82006C4.18442 8.03494 4.39981 8.18438 4.64498 8.15844C4.84212 8.13769 5.00696 8.0018 5.07022 7.82006C5.08294 7.78424 5.09147 7.74675 5.09549 7.70793C5.10386 7.62944 5.09414 7.55414 5.07005 7.48535ZM4.53703 7.94072C4.50423 7.97034 4.45202 7.94708 4.45202 7.9029V7.40234C4.45202 7.35833 4.50423 7.33506 4.53703 7.36469L4.68514 7.48535L4.84446 7.61505C4.86688 7.63513 4.86688 7.67027 4.84446 7.69036L4.68514 7.82006L4.53703 7.94072Z"
        fill="currentcolor"
      />
    </svg>
  );
}
