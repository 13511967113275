import React from 'react'

export default function StudentAvatar({ className }) {
    return (
        <svg
            width={55}
            height={54}
            viewBox="0 0 55 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M27.0363 0.0455031C27.4099 -0.0177719 27.7956 -0.0177719 28.1692 0.0455031L52.274 4.26383C53.6239 4.49584 54.6001 5.53988 54.6001 6.7421C54.6001 7.94433 53.6239 8.98836 52.274 9.22037L43.0298 10.8444V16.8661C43.0298 24.322 36.1238 30.3648 27.6028 30.3648C19.0818 30.3648 12.1757 24.322 12.1757 16.8661V10.8444L6.3906 9.83203V16.6974L8.28282 24.9653C8.3913 25.4609 8.24667 25.9777 7.8851 26.3679C7.52353 26.7581 6.96912 26.9901 6.3906 26.9901H2.53384C1.95533 26.9901 1.41297 26.7686 1.03935 26.3679C0.665725 25.9671 0.521096 25.4609 0.64162 24.9653L2.53384 16.6974V9.12546C1.38887 8.77745 0.605463 7.82832 0.605463 6.7421C0.605463 5.53988 1.58171 4.49584 2.93157 4.26383L27.0363 0.0455031ZM14.0921 34.5514C15.3576 34.1929 16.7195 34.5936 17.6355 35.4478L26.1927 43.4099C26.952 44.1165 28.2416 44.1165 29.0009 43.4099L37.558 35.4478C38.474 34.5936 39.8359 34.1929 41.1014 34.5514C48.9355 36.7555 54.6001 43.1674 54.6001 50.7498C54.6001 52.5426 52.9369 53.9874 50.9 53.9874H4.30554C2.26869 53.9874 0.605463 52.5321 0.605463 50.7498C0.605463 43.1674 6.27008 36.7555 14.0921 34.5514Z"
                fill="#D9D9D9"
            />
        </svg>
    )
}
