import arrow from "../../assets/arrow-down.svg";
import ErrorMessage from "./ErrorMessage";
const SelectInput = ({
  wrapperClassName,
  label,
  placeholderText,
  name,
  items,
  register,
  errors,
  className,
  children,
  ...props
}) => {
  const registerField = items.length > 0 && register ? register(name) : {};
  return (
    <div className={wrapperClassName}>
      {label && (
        <label
          htmlFor={name}
          className="text-primary text-base font-medium mb-2 block"
        >
          {label}
        </label>
      )}

      <select
        id={name}
        {...registerField}
        {...props}
        className={`select select-bordered rounded border-[#DFEAF2] w-full mt-1 bg-[length:0.8rem] ${className}`}
        style={{
          backgroundImage: `url(${arrow})`,
        }}
      >
        <option disabled value="">
          {placeholderText}
        </option>
        {items?.map((item) => (
          <option key={item?.name || item} value={item?.value}>
            {item?.name || item}
          </option>
        ))}
      </select>
      <ErrorMessage
        message={errors[name]?.message}
        isVisible={Boolean(errors[name])}
      />
      {children}
    </div>
  );
};

export default SelectInput;
