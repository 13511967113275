import React from "react";
import userImage from "../../../../../assets/instructorProfileImage.png";
import EmailIcon from "../../../../../Components/svgs/EmailIcon";
import PhoneIconSVG from "../../../../../Components/svgs/PhoneIconeSVG";
import InfoAccordion from "./InfoAccordion";
const InstructorInfo = ({ instructorProfileData, instructorSystemInfo }) => {
  return (
    <>
      <h2 className="w-fit text-1rem font-medium self-start">Your Profile</h2>
      <div className="w-fit flex flex-col items-center ">
        <div className="w-[6rem] aspect-square rounded-[50%] overflow-hidden">
          <img
            className="cover-image"
            src={instructorProfileData?.profile_image || userImage}
            alt="user-image"
          />
        </div>
        <h3 className="text-[1rem] text-primary font-semibold mt-2">
          {instructorProfileData?.full_name}
        </h3>
        <h4 className="text-xs text-gray-7e font-medium mt-1">
          {instructorProfileData?.track}
        </h4>
        <div className="w-full ps-[1.5rem] flex items-center mt-2 gap-2">
          <PhoneIconSVG className="text-gray-7e" />
          <span className="block text-xs text-gray-5f font-medium">
            {instructorProfileData?.phone}
          </span>
        </div>
        <div className="w-full ps-[1.5rem]  flex items-center mt-1 gap-2">
          <EmailIcon className="text-gray-7e" />
          <span className="block text-xs text-gray-5f font-medium">
            {instructorProfileData?.user?.email}
          </span>
        </div>
      </div>
      <div className="self-start ps-2 mt-3 flex flex-col gap-2">
        {instructorSystemInfo.map((info) => (
          <React.Fragment key={info.name}>
            {info?.fieldName === "id" && (
              <div className="relative w-fit flex flex-col">
                <h4 className="text-primary text-xs text-center font-semibold mb-2">
                  {info?.name}
                </h4>
                <span className="block text-gray-5f font-normal text-xs">
                  {instructorProfileData?.[info?.fieldName]
                    ? instructorProfileData?.[info?.fieldName]
                    : "N/A"}
                </span>
              </div>
            )}
            {info?.fieldName !== "id" && (
              <InfoAccordion
                infoName={info?.name}
                infoData={instructorProfileData?.[info?.fieldName]}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default InstructorInfo;
