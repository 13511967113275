import imageRecorded from "../../../../assets/icon (1).png";
import imageRecorded2 from "../../../../assets/icon (2).png";

function AiInterviewCards({
  id,
  email,
  name,
  phone,
  status,
  englishScoreVideo,
  englishScoreText,
  QuestionScore,
  category,
  handleStatusUpdate,
}) {
  return (
    <div className="flex flex-col md:flex-row gap-4">
      {/* User Info Card */}
      <div className="flex flex-col bg-white p-4 rounded-lg shadow w-full md:w-[35rem] h-[11rem]">
        <div className="flex items-center gap-4">
          <div className="flex justify-between w-full items-center">
            <div className="flex items-start">
              <img src={imageRecorded} alt="" />
              <div className="ms-2">
                <h2 className="text-lg font-semibold">{name}</h2>
                <p className="text-sm text-gray-500">{category}</p>
                <span
                  className={`text-sm font-medium ${
                    status === "Accepted"
                      ? "text-green-500"
                      : status === "Rejected"
                      ? "text-red-500"
                      : "text-yellow-500"
                  }`}
                >
                  ● {status}
                </span>
              </div>
            </div>
            <div className="mt-4 md:mt-0">
              <select
                className="px-3 py-1 border border-gray-300 rounded text-sm"
                value={status}
                onChange={(e) => handleStatusUpdate(id, e.target.value)} // Pass id and newStatus
              >
                <option value="Pending">Pending</option>
                <option value="Accepted">Accept</option>
                <option value="Rejected">Reject</option>
              </select>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-2 justify-between mt-auto">
          <div>
            <h2 className="text-sm mb-2 font-light text-primary">Phone:</h2>
            <span className="block  text-base font-medium text-[#45464E]">
              {phone}
            </span>
          </div>
          <div>
            <p className="text-sm mb-2 font-light text-primary">Email:</p>
            <span className="block text-base font-medium text-[#45464E]">
              {email}
            </span>
          </div>
        </div>
      </div>

      {/* Interview Results Card */}
      <div className="bg-white flex flex-col py-4 px-4 rounded-lg shadow w-full md:w-[35rem]">
        <div className="flex items-center gap-4">
          <img src={imageRecorded2} alt="" />
          <div>
            <h2 className="text-lg font-medium text-primary">
              Interview Result
            </h2>
          </div>
        </div>
        <div className="flex flex-wrap gap-[2.5rem] mt-auto">
          <div className="">
            <span className="block text-sm whitespace-nowrap font-light mb-2 text-primary">
              English Score (Video)
            </span>
            <span className="block ms-2 text-xl font-mdeium text-[#45464E]">
              {englishScoreVideo === -1 ? "-" : englishScoreVideo}
            </span>
          </div>
          <div>
            <span className="block text-sm  whitespace-nowrap  font-light mb-2 text-primary">
              English Score (Text)
            </span>
            <span className="block ms-2 text-xl font-mdeium text-[#45464E]">
              {englishScoreText === -1 ? "-" : englishScoreText}
            </span>
          </div>
          <div>
            <span className="block text-sm font-light mb-2 text-primary">
              Technical Score
            </span>
            <span className="block ms-2 text-xl font-medium text-[#45464E]">
              {QuestionScore}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AiInterviewCards;
