import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FiInfo } from "react-icons/fi";

import PasswordInput from "../../../shared/PasswordInput";
import CustomButton from "../../../shared/CustomButton";
import { useMutation } from "@tanstack/react-query";
import { apiPostConfirmIdentity } from "../../../../Services/ControlPanel/apiPostConfirmIdentity";
import { useDialog } from "../../../shared/CustomDialog";
import ErrorMessage from "../../../shared/ErrorMessage";
const schema = yup.object({
  password: yup
    .string()
    .required("Password is required")
    .max(30, "Too long password"),
});

const ConfirmIdentityForm = ({ setIsIdentityConfirmed }) => {
  const { closeDialog } = useDialog();
  const mutation = useMutation({
    mutationFn: apiPostConfirmIdentity,
  });
  const { isPending } = mutation;
  const form = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = form;

  const onSubmit = async (data) => {
    mutation.mutate(data, {
      onSuccess: () => {
        setIsIdentityConfirmed(true);
      },
      onError: (err) => {
        form.setError("root", {
          message:
            err.response.data.message || "Wrong password , please try again",
        });
      },
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
      <div role="alert" className="alert bg-[#28499D17] mb-[1.5rem]">
        <FiInfo className="text-primary size-[1.2rem]" />
        <p>
          To proceed with changing the student's password, please enter your
          password to verify your identity.
        </p>
      </div>
      <PasswordInput
        label="Password"
        placeholder="********"
        fieldName="password"
        register={register}
        errors={errors}
      />
      <div className="flex gap-2 justify-end items-center mt-[1.5rem]">
        <CustomButton
          type="submit"
          className="min-w-[5rem] py-3"
          isLoading={isPending}
        >
          Next
        </CustomButton>
        <CustomButton
          className="bg-[#00103D0F] text-[#2C2D2E] inline-block w-[5rem] py-3 hover:bg-gray-300"
          onClick={closeDialog}
        >
          Close
        </CustomButton>
      </div>
      <ErrorMessage
        message={errors.root?.message}
        isVisible={Boolean(errors.root)}
        centered
      />
    </form>
  );
};

export default ConfirmIdentityForm;
