import React from 'react';
import TableSkeleton from '../../Common/Table/TableSkeleton';
import Table from '../../Common/Table/Table';

const QATableRenderer = ({
    isLoading,
    error,
    results,
    tableData,
    tableColumns,
    page,
    totalPages,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    handleRowsPerPageChange,
    prevPage,
    nextPage,
}) => {
    if (isLoading) {
        return <TableSkeleton columns={tableColumns.length} rows={5} />;
    }

    if (error) {
        return <p>Error fetching data</p>;
    }

    if (results.length === 0) {
        return <p>No data available</p>;
    }

    return (
        <Table
            columns={tableColumns}
            data={tableData}
            rowsPerPageOptions={[5, 10, 20]}
            currentPage={page}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            prevPage={prevPage}
            nextPage={nextPage}
        />
    );
};

export default QATableRenderer;