import React from 'react';
import CalenderIcon from '../../../../Components/svgs/CalenderIcon';

export default function CollapsibleCard({
    title,
    subtitle,
    status,
    statusColor,
    details,
    buttonText,
    onButtonClick,
}) {
    return (
        <div className='collapse collapse-arrow bg-base-200 mb-3'>
            <input type='checkbox' defaultChecked />
            <div className='collapse-title text-xl font-medium flex justify-between items-center flex-wrap'>
                <h1 className='text-[#5F5F5F] font-semibold text-base'>{title}</h1>
                <div className='flex items-center gap-2'>
                    <CalenderIcon className='text-white' />
                    <h2 className='text-[#2D3748] font-medium text-base'>{subtitle}</h2>
                </div>
                <h3 className={`${statusColor} text-base mt-2 md:mt-0`}>
                    <span>&#9679;</span> {status}
                </h3>
            </div>
            <div className='collapse-content flex justify-between items-center'>
                {details.map((detail, index) => (
                    <div key={index}>
                        <h2 className='text-[#273C97] text-sm font-medium'>{detail.label}</h2>
                        <h3 className='text-[#45464E] text-base font-medium'>{detail.value}</h3>
                    </div>
                ))}
                <div
                    className={`${buttonText ? 'opacity-100' : 'opacity-0 pointer-events-none'
                        } transition-opacity duration-300 ease-in-out flex justify-end`}
                >
                    {buttonText && (
                        <button
                            onClick={onButtonClick}
                            className='btn-primary px-3 py-2 self-end mt-10'
                        >
                            {buttonText}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
