import React, { useRef } from 'react';

export default function QADetails({ answer, question, student }) {
    const dialogRef = useRef(null);

    const openDialog = () => dialogRef.current?.showModal();
    const closeDialog = () => dialogRef.current?.close();
    return (
        <>
            {/* if answer is exist */}
            <span>
                {answer && (
                    <button
                        onClick={openDialog}
                        className='btn-primary bg-[#273C97] font-medium px-5 py-3 rounded-lg'
                    >
                        view
                    </button>
                )}
            </span>

            {/* if answer is null */}
            <span>
                {!answer && (
                    <div
                        className={
                            'inline-flex items-center gap-1 px-3 py-2 rounded-full text-sm font-semibold text-[#FFC107] bg-[#FFF5D7]'
                        }
                    >
                        &#9679;
                        <span>Pending</span>
                    </div>
                )}
            </span>

            {/* Dialog */}

            <dialog ref={dialogRef} className='fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[30rem] min-h-[15rem] border-none bg-[#fff] rounded-xl outline-none p-5 overflow-visible'>
                <div className='flex justify-end items-center mb-4'>
                    <button
                        onClick={closeDialog}
                        className='text-[#71747B] hover:text-gray-900'
                    >
                        ✖
                    </button>
                </div>
                <div className='w-full text-left'>
                    <div className='flex gap-2 mb-4'>
                        <h2 className='text-lg font-semibold text-[#202020]'>
                            Asked by:
                        </h2>
                        <h3 className='font-normal text-lg text-[#202020]'>{student}</h3>
                    </div>
                    <p className='text-[#828282] mb-3 text-lg'>{question}</p>
                    <p className='text-lg font-semibold text-[#202020]'>
                        Instructor Answer:
                    </p>
                    <p className='text-[#828282] mb-3 text-lg break-words w-full whitespace-pre-wrap'>
                        {answer}
                    </p>
                </div>
            </dialog>
        </>
    );
}
