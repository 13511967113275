import SliderIcon from "../../../svgs/SliderIcon";
const status = {
  completed_freelance: {
    name: "Approved",
    color: "#259800",
  },
  submitted_freelance: {
    name: "Proposal Submitted",
    color: "#273C97",
  },

  rejected_freelance: {
    name: "Rejected",
    color: "#F13E3E",
  },
};
const GigProgressBar = ({ data }) => {
  const totalProposals =
    data?.completed_freelance +
    data?.submitted_freelance +
    data?.rejected_freelance;
  return (
    <div className="sm:w-[30rem] w-full sm:ms-[10rem] ms-0">
      <div className="sm:w-[20rem] w-full h-[8px] flex items-center rounded-full overflow-hidden">
        {Object.entries(data).map(([key, value]) => (
          <div
            key={key}
            style={{
              width: `${(value / totalProposals) * 100}%`,
              backgroundColor: status[key].color,
            }}
            className="h-full"
          />
        ))}
      </div>
      <div className="sm:w-[20rem] w-full mt-2 flex items-center justify-between">
        {Object.entries(data).map(([key, value]) => (
          <div key={key} className="flex gap-1">
            <SliderIcon
              className="size-[1.2rem]"
              style={{
                color: status[key].color,
              }}
            />
            <div>
              <span className="block tablet:text-xs text-sm text-[#96A5B8]">
                {status[key].name}
              </span>
              <span className="block tablet:text-xs text-sm mt-1 ms-1">
                {value}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GigProgressBar;
