import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import eyouthLogo from '../../../assets/EYouthLogo.png';
import LangBtn from '../../../Components/LangBtn/LangBtn';
import ItidaNewLogo from '../../../Components/svgs/ItidaNewLogo';
import { pageLinks } from '../../../constants';

export default function LandingNavBar() {
    const { t } = useTranslation();
    return (
        <div className='flex justify-between items-center mx-12'>
            <div className='flex gap-3'>
                <div className='w-28'>
                    <img src={eyouthLogo} alt="EYouth logo" className="cover-image" />
                </div>
                <div className='w-24 h-20'>
                    <ItidaNewLogo className="cover-image h-auto w-auto" />
                </div>
            </div>
            <div className='flex gap-6'>
                <LangBtn />
                <Link to={pageLinks.login} className='btn-primary rounded-2xl font-medium px-4 py-2 w-fit h-fit'>{t("login")}</Link>
            </div>
        </div>
    )
}
