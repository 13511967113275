import React from 'react'

export default function QAanswered({ className }) {
    return (
        <svg
            width={73}
            height={73}
            viewBox="0 0 73 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle
                cx={36.7107}
                cy={36.6031}
                r={36.0992}
                fill="url(#paint0_linear_1424_4471)"
            />
            <path
                d="M35.5859 31.0596H43.8984"
                stroke="#273C97"
                strokeWidth={2.85}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.1016 31.0596L27.2891 32.2471L30.8516 28.6846"
                stroke="#273C97"
                strokeWidth={2.85}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M35.5859 42.1436H43.8984"
                stroke="#273C97"
                strokeWidth={2.85}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.1016 42.1436L27.2891 43.3311L30.8516 39.7686"
                stroke="#273C97"
                strokeWidth={2.85}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M30.2498 51.8332H39.7498C47.6665 51.8332 50.8332 48.6665 50.8332 40.7498V31.2498C50.8332 23.3332 47.6665 20.1665 39.7498 20.1665H30.2498C22.3332 20.1665 19.1665 23.3332 19.1665 31.2498V40.7498C19.1665 48.6665 22.3332 51.8332 30.2498 51.8332Z"
                stroke="#273C97"
                strokeWidth={2.85}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1424_4471"
                    x1={64.6876}
                    y1={0.503908}
                    x2={36.7107}
                    y2={72.7023}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#859CFF" stopOpacity={0.44} />
                    <stop offset={1} stopColor="#F1F8FF" />
                </linearGradient>
            </defs>
        </svg>
    )
}
