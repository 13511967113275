import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { dashboardInstructorsEndPoint } from "../../../../Services/endPoints";
import { pageRoutes } from "../../../../constants";
import InstructorFilters from "../../../../NewPages/AdminDashboard/components/InstructorFilters";
import AdminUserInfo from "../../../../NewPages/AdminDashboard/components/AdminUserInfo";
import TableSkeleton from "../../../../NewPages/AdminDashboard/Common/Table/TableSkeleton";
import Table from "../../../../NewPages/AdminDashboard/Common/Table/Table";
import usePaginationNumbers from "../../../../hooks/usePaginationNumbers";
import SearchInput from "../../../../NewPages/AdminDashboard/Common/SearchInput/SearchInput";

function CPInstructorsPage() {
  const [searchParams] = useSearchParams();

  // Extract filter parameters from the URL

  // Construct the dynamic API URL with filters
  const dynamicInstructorsEndpoint = `${dashboardInstructorsEndPoint}?${searchParams.toString()}`;

  const {
    data: dashboardInstructorsData,
    page,
    totalPages,
    isLoading,
    error,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    nextPage,
    prevPage,
    setRowsPerPage: handleRowsPerPageChange,
  } = usePaginationNumbers(dynamicInstructorsEndpoint, null);

  const tableColumns = [
    { header: "Coach ID", accessor: "id" },
    { header: "Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "Coach Type", accessor: "coachType" },
    { header: "Job Profiles", accessor: "jobProfiles" },
    { header: "Coaching Rounds", accessor: "coachingRounds" },
    { header: "Status", accessor: "status" },
    { header: "Edit", accessor: "edit" },
  ];

  const tableData =
    dashboardInstructorsData?.map((instructor) => ({
      id: instructor.id,
      name: instructor.name,
      email: instructor.user.email,
      phone: instructor.phone,
      coachType: instructor.role,
      jobProfiles:
        instructor.job_profile_rounds?.map((job) => job.code).join(", ") ||
        "None",
      coachingRounds:
        instructor.coaching_rounds?.map((round) => round.code).join(", ") ||
        "None",
      status: instructor.user.is_joined ? "Active" : "Inactive",
      edit: (
        <Link
          to={`/${pageRoutes.controlPanelInstructors}/${instructor.id}`}
          className="btn-primary px-[2rem] py-3"
        >
          Edit
        </Link>
      ),
    })) || [];

  return (
    <div className="p-4">
      <div className="flex items-start justify-between w-full">
        <InstructorFilters />
        <AdminUserInfo />
      </div>
      <SearchInput placeholder="Search by instructor email" />

      <div className="mt-6">
        {isLoading ? (
          <TableSkeleton columns={tableColumns.length} rows={5} />
        ) : error ? (
          <p>Error fetching data</p>
        ) : (
          <Table
            columns={tableColumns}
            data={tableData}
            rowsPerPageOptions={[5, 10, 20]}
            isLoading={isLoading}
            currentPage={page}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        )}
      </div>
    </div>
  );
}

export default CPInstructorsPage;
