import { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import {
  city,
  CoachingRoundEndPoint,
  dashboardStudentsTrack,
  jobProfileRoundsEndPoint,
} from "../../../Services/endPoints";
import FilterGroup from "../shared/Filters/FilterGroup";
const initialFilters = [
  {
    name: "Coaching Round",
    key: "coaching_round",
  },
  {
    name: "Job Profile Code",
    key: "job_profile_round",
  },

  {
    name: "Track",
    key: "track",
  },
  {
    name: "City",
    key: "city",
  },
  {
    name: "Status",
    key: "is_active",
    options: [
      {
        name: "Active",
        value: "True",
      },
      {
        name: "Inactive",
        value: "False",
      },
    ],
  },
];
const StudentFilters = () => {
  const [filters, setFilters] = useState(initialFilters);
  const { data: coachingRounds } = useFetch(CoachingRoundEndPoint);
  const { data: jobProfileRounds } = useFetch(jobProfileRoundsEndPoint);
  const { data: trackData } = useFetch(dashboardStudentsTrack);
  const { data: cities } = useFetch(city);

  useEffect(() => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => {
        switch (filter.key) {
          case "coaching_round":
            return {
              ...filter,
              options: coachingRounds?.map((round) => round.code) || [],
            };
          case "job_profile_round":
            return {
              ...filter,
              options: jobProfileRounds?.map((round) => round.code) || [],
            };
          case "city":
            return {
              ...filter,
              options: cities?.map((city) => city.name) || [],
            };

          case "track":
            return {
              ...filter,
              options: trackData?.map((track) => track.name) || [],
            };
          default:
            return filter;
        }
      })
    );
  }, [coachingRounds, jobProfileRounds, trackData, cities]);
  return <FilterGroup filters={filters} />;
};

export default StudentFilters;
