import { ArrowDownward } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import axios from "axios";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { dashboardAIInterveiwingEndPoint } from "../../../Services/endPoints";
import imageRecorded from "../../../assets/icon (1).png";
import imageRecorded2 from "../../../assets/icon (2).png";
import useFetch from "../../../hooks/useFetch";
import AdminUserInfo from "../components/AdminUserInfo";
import AiInterviewCards from "../components/AiReviwer/AiInterviewCards";

function AiInterviewDetailsPage() {
  const { id } = useParams();
  const [refetchTrigger, setRefetchTrigger] = useState(false);
  const { data: dashboardAIInterveiwingData, refetch } = useFetch(
    `${dashboardAIInterveiwingEndPoint}${id}/`,
    [refetchTrigger]
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  if (!dashboardAIInterveiwingData) {
    return <p>Loading...</p>;
  }

  const { name, email, phone, status, first_interview, second_interview } =
    dashboardAIInterveiwingData;

  const openModal = (url) => {
    setVideoUrl(url);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setVideoUrl("");
  };

  const handleStatusUpdate = async (id, newStatus) => {
    try {
      const response = await axios.patch(
        `https://dfppback.eyouthlearning.com/api/leads-status/${id}/`,
        { status: newStatus }
      );

      if (response.status === 200) {
        setRefetchTrigger((prev) => !prev);
        refetch(); // Re-fetch the data to update the status in the UI
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <div className="p-6 space-y-8">
      <div className="flex items-center w-full">
        <Link
          to={`/ai-interviewing/${dashboardAIInterveiwingData?.next_lead?.id}`}
          className="flex gap-2 items-center border border-primary bg-white rounded-lg hover:bg-gray-100 text-lg text-black px-3 py-3 "
        >
          <span className=" size-[2.5rem] flex justify-center items-center aspect-square  rounded-full bg-primary">
            <ArrowDownward
              className="text-white rotate-[-90deg]"
              fontSize="medium"
            />
          </span>
          Next , {dashboardAIInterveiwingData?.next_lead?.name}
        </Link>
        <div className="mb-4 tablet:block hidden w-fit ms-auto">
          <AdminUserInfo />
        </div>
      </div>
      <AiInterviewCards
        id={id}
        handleStatusUpdate={handleStatusUpdate}
        email={email}
        phone={phone}
        status={status}
        name={name}
        englishScoreVideo={first_interview?.int_score}
        englishScoreText={first_interview?.text_score}
        QuestionScore={second_interview?.score}
        category={second_interview?.category}
      />

      {first_interview && (
        <div className="bg-white rounded-lg shadow p-6">
          {first_interview?.text_score !== -1 && (
            <>
              <div className="flex items-center space-x-4">
                <img
                  src={imageRecorded}
                  alt="Video Thumbnail"
                  className="w-16 h-16 object-cover rounded-full"
                />
                <h2 className="text-lg font-semibold whitespace-nowrap">
                  Interviewer Answer
                </h2>
              </div>

              <p className="mt-6 text-gray-700 text-sm whitespace-pre-wrap  ms-3">
                {first_interview?.transcription}
              </p>
            </>
          )}
          {first_interview?.int_score !== -1 && (
            <>
              <div className="flex items-center space-x-4">
                <img
                  src={imageRecorded}
                  alt="Video Thumbnail"
                  className="w-16 h-16 object-cover rounded-full"
                />
                <div>
                  <h2 className="text-lg font-semibold whitespace-nowrap">
                    Recorded Video
                  </h2>
                </div>
                <div className="flex justify-end w-[75%]">
                  <span className="px-3 py-1 text-sm font-medium text-white bg-blue-500 rounded">
                    English Score:
                    <span className="inline-block ms-2">
                      first_interview?.int_score
                    </span>
                  </span>
                </div>
              </div>
              <div
                className="relative w-[17rem] ms-[1rem] mt-3 rounded-md overflow-hidden cursor-pointer"
                onClick={() => openModal(first_interview.video_file)}
              >
                <video
                  src={first_interview.video_file}
                  className="cover-image"
                  controls={false}
                  muted
                />
                <PlayCircleFilledWhiteOutlinedIcon
                  className="absolute-center z-[100] text-white cursor-pointer"
                  style={{
                    fontSize: "70px",
                  }}
                />
              </div>
            </>
          )}
          <div className="mt-6">
            <div className="flex items-center">
              <img src={imageRecorded2} alt="Interview Comments" />
              <h3 className="text-lg font-semibold border-b pb-2 ml-2">
                Interview Comments
              </h3>
            </div>
            <p className="mt-4 text-gray-700 text-sm whitespace-pre-wrap">
              {first_interview?.ai_response}
            </p>
          </div>
        </div>
      )}

      {/* Technical Test Section */}
      {second_interview && (
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex justify-between items-center border-b pb-2 mb-4">
            <h3 className="text-lg font-semibold">Technical Test</h3>
            <span className="px-3 py-1 text-sm font-medium text-white bg-green-500 rounded">
              Technical Score: {second_interview?.score}
            </span>
          </div>
          <div className="space-y-6">
            {second_interview.questions_answers.map((qa) => {
              const isCorrectAnswer = qa.correct_answer === qa.answer;

              return (
                <div key={qa.id} className="p-4 border rounded-lg bg-white">
                  <p className="font-medium text-gray-800">{qa.question}</p>
                  <ul className="mt-2 space-y-2">
                    {qa.choices.map((choice, index) => {
                      const choiceNumber = (index + 1).toString();
                      const isCorrect = qa.correct_answer === choiceNumber;
                      const isUserAnswer = qa.answer === choiceNumber;

                      return (
                        <li key={index} className="flex items-center space-x-2">
                          <span
                            className={`block w-4 h-4 rounded-full ${isCorrect
                                ? "bg-green-500"
                                : isUserAnswer
                                  ? "bg-red-500"
                                  : "bg-gray-300"
                              }`}
                          ></span>
                          <span className="text-sm text-gray-700">
                            {choice}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* Video Popup Modal */}
      {isModalOpen && (
        <div className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[99999] w-[40rem] max-w-[90%] aspect-video bg-white rounded-lg shadow-lg p-6">
          <div className="flex justify-end my-2">
            <CloseIcon className="cursor-pointer" onClick={closeModal} />
          </div>
          <h1 className="text-2xl font-semibold mb-3">{name} Interview </h1>
          <video controls src={videoUrl} className="w-full h-full rounded-md" />
          <div className="flex justify-center">
            <button
              onClick={closeModal}
              className="btn btn-error my-2 mx-auto text-white"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AiInterviewDetailsPage;
