import React from "react";

function Task({ className }) {
  return (
    <svg
      width="16"
      className={className}
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.24658 6.55518H11.7466"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.25342 6.55518L4.75342 7.05518L6.25342 5.55518"
        stroke="#202020"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.24658 11.2219H11.7466"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.25342 11.2219L4.75342 11.7219L6.25342 10.2219"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99992 15.3018H9.99992C13.3333 15.3018 14.6666 13.9685 14.6666 10.6352V6.63517C14.6666 3.30184 13.3333 1.96851 9.99992 1.96851H5.99992C2.66659 1.96851 1.33325 3.30184 1.33325 6.63517V10.6352C1.33325 13.9685 2.66659 15.3018 5.99992 15.3018Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Task;
