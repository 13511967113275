import CustomButton from "../../../shared/CustomButton";
import { DialogProvider } from "../../../shared/CustomDialog";
import ActionsIcon from "../../../svgs/ActionsIcon";
import TakeActionButtons from "./TakeActionButtons";

const TakeActionDialog = ({ leadsCount }) => {
  return (
    <DialogProvider
      title="Take Action for leads"
      className="w-full pb-[1.5rem] aspect-video md:w-[35rem] flex-col"
      titleClassName="text-2xl font-bold text-[#3E3E3E] pb-2"
      contentClassName="flex-1 flex flex-col"
      renderOpenButton={(openDialog) => (
        <CustomButton
          className="btn-primary w-fit py-3 mb-3 flex items-center justify-center gap-2 whitespace-nowrap"
          onClick={openDialog}
        >
          <ActionsIcon className="size-[1.4rem]" />
          Take Action
        </CustomButton>
      )}
    >
      <p className="text-gray-5f my-[1rem] text-lg">
        You are about to take an action regarding the leads that you have
        filtered and their number are
        <span className="text-primary font-bold"> {leadsCount} leads.</span>
      </p>
      <div className="text-black-12">
        <span className="text-error font-bold">Caution:</span>
        <br /> &nbsp; Before taking an action please check the filter you have
        applied.
      </div>
      <TakeActionButtons />
    </DialogProvider>
  );
};

export default TakeActionDialog;
