const ActionsIcon = ({ className }) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.10538 4.15311H6.03265C6.03267 4.15178 6.03305 4.15053 6.03305 4.14919V1.18262C6.03305 0.961941 5.85479 0.783691 5.63552 0.783691H1.18073C0.961452 0.783691 0.783203 0.961941 0.783203 1.18262V4.14919C0.783203 4.36846 0.961452 4.5467 1.18073 4.5467H2.49142V6.13397L4.25306 4.60884V7.51861C4.25306 7.73789 4.43132 7.91614 4.65059 7.91614H5.96058H5.96128L7.79469 9.5034V7.91614H9.10538C9.32466 7.91614 9.50291 7.73789 9.50291 7.51861V4.55206C9.50291 4.33136 9.32466 4.15311 9.10538 4.15311Z"
        fill="#FAFAFA"
      />
      <path
        d="M7.894 7.05023L7.89398 7.05024C7.79532 7.1489 7.63536 7.1489 7.5367 7.05024L6.87852 6.39207L6.22035 7.05024C6.12168 7.1489 5.96171 7.1489 5.86306 7.05023V7.05023C5.7644 6.95157 5.76441 6.79161 5.86306 6.69296L6.52124 6.03477L5.86306 5.37659C5.7644 5.27793 5.7644 5.11797 5.86306 5.01931C5.96171 4.92065 6.12167 4.92065 6.22033 5.01931L6.87852 5.67749L7.5367 5.01931C7.63536 4.92065 7.79532 4.92065 7.89398 5.0193L7.89398 5.01931C7.99265 5.11797 7.99265 5.27793 7.89399 5.37659L7.23581 6.03476L7.89399 6.69295C7.99266 6.79161 7.99266 6.95157 7.894 7.05023Z"
        fill="#DC2626"
      />
      <path
        d="M4.49253 1.62511L2.99951 2.98563L2.51516 2.50128C2.20149 2.20869 1.76501 2.64579 2.05736 2.95907L2.76048 3.66218C2.76539 3.66683 2.77516 3.67728 2.7803 3.68157C2.9066 3.79716 3.10269 3.78848 3.21828 3.66218L4.79756 1.93015C4.9773 1.72486 4.69714 1.44519 4.49253 1.62511Z"
        fill="#259800"
      />
      <path
        d="M9.1049 4.0128H6.1737V1.18269C6.1737 0.885203 5.93225 0.643066 5.63552 0.643066H1.18076C0.884031 0.643066 0.642578 0.885203 0.642578 1.18269V4.14919C0.642578 4.44585 0.884031 4.6873 1.18076 4.6873H2.3508V6.13394C2.3508 6.18902 2.38294 6.23901 2.433 6.26181C2.45168 6.27047 2.47159 6.27458 2.49144 6.27458C2.52461 6.27458 2.55743 6.26291 2.58346 6.24025L4.11216 4.91678V7.51892C4.11216 7.81517 4.35382 8.05621 4.65083 8.05621H5.90911L7.70206 9.60971C7.72816 9.63224 7.76098 9.64405 7.79415 9.64405C7.81393 9.64405 7.83391 9.63993 7.85259 9.63128C7.90265 9.60847 7.93479 9.55848 7.93479 9.50341V8.05621H9.10489C9.4019 8.05621 9.64355 7.81517 9.64355 7.51892V4.55147C9.64355 4.25439 9.4019 4.0128 9.1049 4.0128ZM2.49143 4.40602H1.18076C1.03909 4.40602 0.923854 4.29079 0.923854 4.14919V1.18269C0.923854 1.04027 1.03909 0.924349 1.18076 0.924349H5.63552C5.77719 0.924349 5.89242 1.04027 5.89242 1.18269V4.14919C5.89242 4.29079 5.77719 4.40602 5.63552 4.40602H4.32484C4.29105 4.40602 4.25837 4.41824 4.23282 4.44035L2.63207 5.82615V4.54666C2.63207 4.46892 2.5691 4.40602 2.49143 4.40602ZM9.36227 7.51892C9.36227 7.66011 9.24684 7.77493 9.10489 7.77493H7.79415C7.71648 7.77493 7.65351 7.83783 7.65351 7.91557V9.19548L6.05366 7.80927C6.02811 7.78715 5.99405 7.77493 5.9602 7.77493H4.65083C4.50888 7.77493 4.39345 7.66011 4.39345 7.51892V4.6873H5.63552C5.88171 4.6873 6.08759 4.52013 6.15135 4.29408H9.1049C9.24684 4.29408 9.36228 4.40959 9.36228 4.55147L9.36227 7.51892Z"
        fill="white"
      />
      <path
        d="M7.43473 6.03461L7.99352 5.47589C8.14673 5.32248 8.14673 5.07306 7.99345 4.91964C7.83997 4.76637 7.59048 4.76637 7.43728 4.91964L6.87856 5.4785L6.31977 4.91964C6.16657 4.76637 5.91708 4.76637 5.7636 4.91964C5.61039 5.07306 5.61039 5.32248 5.76366 5.47589L6.32238 6.03461L5.76366 6.59332C5.68936 6.66749 5.64844 6.76624 5.64844 6.87144C5.64844 6.97651 5.68937 7.07526 5.7636 7.14943C5.83784 7.22374 5.93659 7.26467 6.04172 7.26467H6.04179C6.14679 7.26467 6.24554 7.22387 6.31985 7.14957L6.87856 6.59071L7.43728 7.14957C7.59076 7.30298 7.84011 7.30284 7.99346 7.14943H7.99353C8.06776 7.07512 8.10869 6.97638 8.10869 6.87144C8.10869 6.76624 8.06776 6.66749 7.99346 6.59332L7.43473 6.03461ZM7.79457 6.95055V6.95069C7.75103 6.99437 7.67996 6.9945 7.63614 6.95069L6.97799 6.2924C6.92525 6.23966 6.83186 6.23966 6.77912 6.2924L6.12096 6.95069C6.09981 6.97184 6.07172 6.98338 6.04178 6.98338C6.01185 6.98338 5.98376 6.9717 5.96254 6.95055C5.94139 6.9294 5.92971 6.90124 5.92971 6.87144C5.92971 6.8415 5.94139 6.81335 5.96254 6.7922L6.62069 6.13404C6.67563 6.07911 6.67563 5.99011 6.62069 5.93517L5.96261 5.27702C5.91893 5.23334 5.91893 5.1622 5.96254 5.11852C5.98437 5.09668 6.01301 5.08583 6.04172 5.08583C6.07035 5.08583 6.09906 5.09668 6.12089 5.11852L6.77912 5.77681C6.83186 5.82955 6.92525 5.82955 6.97799 5.77681L7.63614 5.11852C7.67982 5.07498 7.75096 5.07484 7.7945 5.11852C7.83818 5.1622 7.83818 5.23334 7.79457 5.27702L7.13642 5.93517C7.08148 5.99011 7.08148 6.07911 7.13642 6.13404L7.79457 6.7922C7.81572 6.81335 7.8274 6.8415 7.8274 6.87144C7.8274 6.90124 7.81572 6.9294 7.79457 6.95055Z"
        fill="#F13E3E"
      />
      <path
        d="M2.68577 3.78526C2.76947 3.86203 2.87668 3.90352 2.9895 3.90352C2.9963 3.90352 3.00316 3.90338 3.00996 3.9031C3.1302 3.89775 3.24118 3.84597 3.32262 3.75697L4.9038 2.02272C5.04931 1.85654 5.00639 1.6508 4.88883 1.5335C4.77133 1.41607 4.56566 1.37391 4.40009 1.51949C4.39946 1.52005 4.39885 1.52059 4.39823 1.52114L3.00447 2.7913L2.61153 2.39836C2.39178 2.19331 2.11736 2.24591 1.95997 2.40345C1.80243 2.56112 1.75018 2.83553 1.95839 3.05844L2.66784 3.7681C2.67601 3.77634 2.68535 3.78512 2.68577 3.78526ZM2.15891 2.60232C2.17224 2.589 2.2223 2.54409 2.28946 2.54409C2.32737 2.54409 2.37077 2.55837 2.41616 2.60068L2.9005 3.08509C2.95366 3.13851 3.03922 3.14002 3.09464 3.08962L4.58654 1.73005C4.63379 1.68926 4.67155 1.71425 4.69016 1.73266C4.70884 1.75134 4.73398 1.78979 4.69408 1.83539L3.11503 3.56716C3.08447 3.60054 3.04272 3.62004 2.99753 3.6221C2.95145 3.62388 2.90902 3.60851 2.87379 3.57623L2.86033 3.56276L2.16063 2.863C2.04011 2.73389 2.13803 2.6232 2.15891 2.60232Z"
        fill="#14A800"
      />
    </svg>
  );
};

export default ActionsIcon;
