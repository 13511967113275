import { DialogProvider } from "../../../shared/CustomDialog";
import CustomButton from "../../../shared/CustomButton";
import ChangePasswordForm from "./ChangePasswordForm";
import ConfirmIdentityForm from "./ConfirmIdentityForm";
import { useState } from "react";

const ChangePasswordDialog = ({ role, email }) => {
  const [isIdentityConfirmed, setIsIdentityConfirmed] = useState(false);
  return (
    <DialogProvider
      title={
        isIdentityConfirmed
          ? `Change ${role} Password`
          : "Confirm Your Identity"
      }
      className="w-full pt-4 pb-[2rem] md:w-[35rem]"
      headerClassName="pt-[1rem] bg-transparent border-none"
      titleClassName="text-2xl font-bold text-[#3E3E3E]"
      renderOpenButton={(openDialog) => (
        <CustomButton
          className="font-bold px-4 text-sm block py-3"
          onClick={() => {
            openDialog();
            setIsIdentityConfirmed(false);
          }}
        >
          Change Password
        </CustomButton>
      )}
    >
      {isIdentityConfirmed && <ChangePasswordForm role={role} email={email} />}
      {!isIdentityConfirmed && (
        <ConfirmIdentityForm
          setIsIdentityConfirmed={setIsIdentityConfirmed}
          role={role}
        />
      )}
    </DialogProvider>
  );
};

export default ChangePasswordDialog;
