import React from "react";
import userDefultImage from "../../../assets/profile.png";
import { useCookies } from "react-cookie";
import { twMerge } from "tailwind-merge";

function AdminUserInfo({ className }) {
  const [cookies] = useCookies(["email"]);
  return (
    <div className={twMerge("flex items-center gap-2 mr-3", className)}>
      <div className="size-[3rem] rounded-full overflow-hidden">
        <img
          className="cover-image"
          src={userDefultImage}
          alt="userDefultImage"
        />
      </div>
      <div className="text-sm">
        <p>{cookies.email}</p>
        <p className="text-gray-400 font-normal">Admin</p>
      </div>
    </div>
  );
}

export default AdminUserInfo;
