import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FiInfo } from "react-icons/fi";
import * as yup from "yup";

import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { apiPostChangePassword } from "../../../../Services/ControlPanel/apiPostChangePassword";
import CustomButton from "../../../shared/CustomButton";
import { useDialog } from "../../../shared/CustomDialog";
import PasswordInput from "../../../shared/PasswordInput";
const schema = yup.object({
  new_password: yup
    .string()
    .required("Password is required")
    .min(8, "Too short password")
    .max(30, "Too long password"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ChangePasswordForm = ({ email }) => {
  const { closeDialog } = useDialog();
  const mutation = useMutation({
    mutationFn: apiPostChangePassword,
  });
  const { isPending } = mutation;
  const form = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = form;

  const onSubmit = async (data) => {
    const requestBody = {
      ...data,
      email,
    };
    mutation.mutate(requestBody, {
      onSuccess: () => {
        closeDialog();
        toast.success("Password changed successfully");
      },
    });
    try {
    } catch (err) {
      form.setError("root", {
        message: err.response.data.message || "Some thing went wrong",
      });
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
      <div role="alert" className="alert bg-[#28499D17] mb-[1.5rem]">
        <FiInfo className="text-primary size-[1.2rem]" />
        <p>Enter and confirm the new password for the student.</p>
      </div>
      <PasswordInput
        label="New Password"
        wrapperClassName="mb-[1.5rem]"
        placeholder="********"
        fieldName="new_password"
        register={register}
        errors={errors}
      />
      <PasswordInput
        label="Confirm Password"
        placeholder="********"
        fieldName="confirm_password"
        register={register}
        errors={errors}
      />
      <div className="flex gap-2 justify-end items-center mt-[1.5rem]">
        <CustomButton
          type="submit"
          className="min-w-[5rem] py-3"
          isLoading={isPending}
        >
          Next
        </CustomButton>
        <CustomButton
          className="bg-[#00103D0F] text-[#2C2D2E] inline-block w-[5rem] py-3 hover:bg-gray-300"
          onClick={closeDialog}
        >
          Close
        </CustomButton>
      </div>
    </form>
  );
};

export default ChangePasswordForm;
