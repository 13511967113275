import React from "react";

const CardSkeleton = () => {
  return (
    <div className="rounded-lg border-gray-300 border px-7 py-4 shadow-md relative ">
      <div className="absolute top-2 right-2 h-4 w-4 bg-gray-200 rounded"></div>

      {/* Skeleton for content */}
      {[...Array(3)].map((_, index) => (
        <div key={index} className="flex items-center animate-pulse mb-4">
          <div className="flex items-center mr-1 ml-1">
            <div className="h-5 w-5 bg-gray-200 rounded-full mr-2"></div>
            <div className="h-4 w-20 bg-gray-200 rounded"></div>
          </div>
          <div className="h-4 w-40 bg-gray-200 rounded ml-2"></div>
        </div>
      ))}

      {/* Skeleton for button */}
      <div className="h-10 bg-gray-200 rounded mt-4"></div>
    </div>
  );
};

export default CardSkeleton;
