import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import AdminUserInfo from "../../../../../NewPages/AdminDashboard/components/AdminUserInfo";
import { apiGetStudentProfile } from "../../../../../Services/ControlPanel/apiGetStudentProfile";
import Loading from "../../../../shared/Loading";
import { EditStudentForm } from "./EditSudentForm/EditStudentForm";

const CPStudentDetailsPage = () => {
  const { id } = useParams();
  const { data, loading } = useQuery({
    queryKey: ["student-profile", id],
    queryFn: () => apiGetStudentProfile({ id }),
  });

  return (
    <div className="mt-[1.5rem] flex items-start justify-between">
      {loading && <Loading />}
      {!loading && data && (
        <EditStudentForm currentStudentData={data} isLoading={loading} />
      )}

      <AdminUserInfo className="tablet:flex hidden " />
    </div>
  );
};

export default CPStudentDetailsPage;
