import SelectInput from "../../../../shared/SelectInput";

const ChooseFromExisting = ({
  activeView,
  setActiveView,
  coachEmailList,
  register,
  errors,
}) => {
  return (
    <div
      className={`px-5 ${activeView === "existing" ? "bg-white py-5 " : ""}`}
    >
      <div className="flex gap-2 items-center">
        <input
          id="existing"
          type="radio"
          name={"existing"}
          className="radio radio-primary border-solid border-gray-82 md:radio-sm radio-md"
          value={"existing"}
          checked={activeView === "existing"}
          onChange={() => setActiveView("existing")}
        />
        <label
          htmlFor="existing"
          className={`text-gray-5f text-base cursor-pointer ${
            activeView === "existing"
              ? "text-primary text-lg font-semibold"
              : ""
          }`}
        >
          Choose From Existing
        </label>
      </div>
      {activeView === "existing" && (
        <SelectInput
          wrapperClassName="max-w-[30rem] mt-4 ms-[1.5rem]"
          items={coachEmailList}
          defaultValue={""}
          name={"id"}
          label={"Choose Coach Email"}
          register={register}
          placeholderText={"Choose Coach Email"}
          errors={errors}
        />
      )}
    </div>
  );
};

export default ChooseFromExisting;
