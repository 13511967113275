import { twMerge } from "tailwind-merge";

const ErrorMessage = ({
  message = "",
  centered = false,
  isVisible = true,
  className,
}) => {
  if (!isVisible) return null;
  return (
    <p
      className={`${twMerge(
        `${centered ? "text-center" : ""} text-[red] ms-1 text-sm mt-3 w-full`,
        className
      )}`}
    >
      {message}
    </p>
  );
};

export default ErrorMessage;
