import Tabs from "./Tabs";
import AdminUserInfo from "../../../../NewPages/AdminDashboard/components/AdminUserInfo";
import { Outlet } from "react-router-dom";

const CreateNewGroupLayout = () => {
  return (
    <>
      <div className="w-full flex justify-between items-start mt-[2rem]">
        <h1 className="text-[2rem] font-semibold text-[#202020]">
          Create New Group
        </h1>

        <AdminUserInfo className="tablet:flex hidden mr-[5%]" />
      </div>
      <Tabs />
      <Outlet />
    </>
  );
};

export default CreateNewGroupLayout;
