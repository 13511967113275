import { FiDownload } from "react-icons/fi";

const DownloadTemplateButton = ({ text, file }) => {
  return (
    <a
      className="btn-primary w-fit px-6 py-3 mb-3 flex items-center justify-center gap-2 whitespace-nowrap"
      href={file}
      download
    >
      <FiDownload className="text-lg size-[1.3rem]" />
      {text}
    </a>
  );
};

export default DownloadTemplateButton;
