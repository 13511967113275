import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import { pageRoutes } from "../../../constants";
const chartOptions = {
  chart: {
    type: "donut",
  },
  colors: ["#1e40af", "#ef4444", "#fbbf24"], // dark blue, red, yellow
  labels: ["Total number of Leads", "Rejected", "Pending Review"],
  legend: {
    show: false,
  },
  plotOptions: {
    pie: {
      donut: {
        size: "55%",
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
};
export default function AIInterviewStats({ data }) {
  const labels = [
    {
      name: "Total number of Leads",
      value: data?.lead_count ?? 0,
      color: "#1e40af",
    },
    { name: "Rejected", value: data?.rejected_leads ?? 0, color: "#ef4444" },
    {
      name: "Pending Review",
      value: data?.pending_leads ?? 0,
      color: "#fbbf24",
    },
  ];
  const series = [
    data?.lead_count ?? 0,
    data?.rejected_leads ?? 0,
    data?.pending_leads ?? 0,
  ];

  return (
    <div className="max-w-[50rem] xl:w-[45%] lg:w-[55%] w-full bg-[#F9F9FA] p-6 shadow rounded">
      <div className="flex items-center justify-between">
        <h2 className="tablet:text-sm text-base font-semibold">AI Interview</h2>
        <Link
          to={`/${pageRoutes.aiReviewer}`}
          className="text-white text-center rounded-full bg-primary hover:bg-primary-light transition-all duration-300 ease-in-out py-1 px-6 ms-auto tablet:text-xs text-sm"
        >
          View All
        </Link>
      </div>
      <div className="w-full flex items-center justify-between tablet:flex-nowrap flex-wrap gap-8 mt-[1.5rem]">
        <div className="flex flex-col gap-3 w-fit ">
          {labels.map((item, index) => (
            <div key={index} className="w-full flex items-center gap-4">
              <div
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: item.color }}
              />
              <span className="tablet:text-sm text-base text-gray-700 whitespace-nowrap">
                {item.name}
              </span>
              <span className="tablet:text-sm text-base font-semibold text-gray-900 ms-auto">
                {item.value}
              </span>
            </div>
          ))}
        </div>
        <div className="h-[13rem] aspect-[4/3]">
          <ReactApexChart
            options={chartOptions}
            series={series}
            type="donut"
            height={"100%"}
            width={"100%"}
          />
        </div>
      </div>
    </div>
  );
}
