const SliderIcon = ({ className, style }) => {
  return (
    <svg
      width="17"
      height="8"
      viewBox="0 0 17 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M2 3.89844H15.3594"
        stroke="currentcolor"
        stroke-width="2.10938"
        stroke-linecap="round"
      />
      <circle cx="8.67969" cy="3.89844" r="3.16406" fill="currentcolor" />
    </svg>
  );
};

export default SliderIcon;
