import { twMerge } from "tailwind-merge";

const CustomButton = ({
  className = "",
  isLoading = false,
  children,
  disabled,
  ...props
}) => {
  return (
    <button
      className={twMerge(
        "text-white text-center rounded-lg bg-primary hover:bg-primary-light transition-all duration-300 ease-in-out disabled:bg-primary-light disabled:cursor-not-allowed; py-3 px-4",
        className
      )}
      disabled={isLoading || disabled}
      {...props}
    >
      {children}
      {isLoading && (
        <span className="loading loading-spinner loading-sm text-[#C2E5FF] ms-3 inline-block align-middle"></span>
      )}
    </button>
  );
};

export default CustomButton;
