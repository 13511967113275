import React from "react";
import EditIcon from "../../../../../Components/svgs/EditIcon";

function Card({
  content,
  onClick,
  buttonClassName,
  buttonContent,
  disabledButton,
  editIcon,
  onEditClick,
}) {
  return (
    <div className="rounded-lg max-w-[30rem] border-gray-300 border px-3 pt-[1.8rem] pb-4 shadow-md relative">
      {editIcon && (
        <div
          className="absolute top-2 right-2 cursor-pointer"
          onClick={onEditClick}
        >
          <EditIcon className="size-[1.3rem]" />
        </div>
      )}

      {content.map((item, index) => (
        <div key={index} className="flex items-center mb-4 text-gray-400">
          <div className="flex items-center mr-1 ">
            <item.icon className="mr-2 size-[1.3rem]" />
            <p className="text-sm font-semibold whitespace-nowrap">
              {item.title}
            </p>
          </div>
          <p className="text-sm truncate">{item.content}</p>
        </div>
      ))}

      <button
        disabled={disabledButton}
        className={`${buttonClassName} ${
          disabledButton ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={!disabledButton ? onClick : undefined}
      >
        {buttonContent}
      </button>
    </div>
  );
}

export default Card;
