import React, { useEffect } from 'react';
import CustomButton from '../../../../shared/CustomButton';
import LabeledInput from '../../../../shared/LabeledInput';
import SelectInput from '../../../../shared/SelectInput';
import DateTimePicker from '../AddWebinarForm/DateTimePicker';
import { useEditWebinarForm } from './useEditWebinarForm';
import { useWatch } from 'react-hook-form';
import { useGetSelectionWebinarAddData } from '../AddWebinarForm/useGetSelectionData';

export default function EditWebinarForm({ id, closeDialog, webinar }) {
    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
        onSubmit,
        isPending,
    } = useEditWebinarForm({ id, webinar, closeDialog });

    const selectedCoach = useWatch({ control, name: 'instructor_id' });

    const { selectionOptions } = useGetSelectionWebinarAddData(selectedCoach);

    useEffect(() => {
        if (webinar) {
            setValue('name', webinar.name || '');
            setValue('link', webinar.link || '');
            setValue('date', webinar.date || '');
            setValue('time', webinar.time || '');
            setValue('instructor_id', webinar.instructor_id || '');
            setValue('job_profile_round', webinar.job_profile_round || '');
            setValue('group', webinar.group || '');
        }
    }, [webinar, setValue]);

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} id='edit-webinar-form'>
                <LabeledInput
                    id={'name'}
                    register={register}
                    placeholder={'Webinar Topic'}
                    fieldName='name'
                    errors={errors}
                    className={'mb-4'}
                />

                <LabeledInput
                    id={'link'}
                    register={register}
                    placeholder={'Webinar Link'}
                    fieldName='link'
                    errors={errors}
                />

                <DateTimePicker
                    control={control}
                    name='date'
                    errors={errors}
                    type='date'
                />

                <DateTimePicker
                    control={control}
                    name='time'
                    errors={errors}
                    label='Start Time'
                    type='time'
                />

                <SelectInput
                    items={selectionOptions.coach}
                    name='instructor_id'
                    register={register}
                    placeholderText='Choose Coach'
                    errors={errors}
                    className={'mb-4'}
                />

                {/* Job Profile Round Select */}
                <SelectInput
                    items={selectionOptions.jobProfileRound}
                    name='job_profile_round'
                    register={register}
                    placeholderText='Choose Job Profile Round'
                    errors={errors}
                    className={'mb-4'}
                />

                {/* Group Select */}
                <SelectInput
                    items={selectionOptions.group}
                    name='group'
                    register={register}
                    placeholderText='Choose Group'
                    errors={errors}
                />
            </form>

            <div className='flex justify-center mt-6 gap-4'>
                <CustomButton
                    form='edit-webinar-form'
                    type='submit'
                    className='font-bold px-4 text-sm block py-4 mt-5'
                    isLoading={isPending}
                >
                    Edit Webinar
                </CustomButton>
                <CustomButton onClick={() => closeDialog()} className='font-bold px-4 text-sm block py-4 mt-5 bg-[#FFFFFF] border border-solid border-[#EE201C] text-[#EE201C] hover:bg-red-100'>
                    Cancel
                </CustomButton>
            </div>
        </div>
    );
}
