import React, { useEffect } from 'react';
import { useAddWebinarForm } from './useAddWebinarForm';
import CustomButton from '../../../../shared/CustomButton';
import LabeledInput from '../../../../shared/LabeledInput';
import DateTimePicker from './DateTimePicker';
import SelectInput from '../../../../shared/SelectInput';
import { useWatch } from 'react-hook-form';
import { useGetSelectionWebinarAddData } from './useGetSelectionData';

export default function AddWebinarForm({ closeDialog }) {
    const {
        register,
        setValue,
        handleSubmit,
        control,
        formState: { errors },
        onSubmit,
        isPending,
    } = useAddWebinarForm(closeDialog);

    const selectedCoach = useWatch({ control, name: 'instructor_id' });
    const { selectionOptions } = useGetSelectionWebinarAddData(
        selectedCoach
    );
    useEffect(() => {
        const defaultInputValues = {
            instructor_id: "",
            job_profile_round: ""
        };
        Object.entries(defaultInputValues).forEach(([key, value]) => {
            setValue(key, value);
        });
    }, [setValue]);

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} id='add-webinar-form'>
                <LabeledInput
                    id={'name'}
                    register={register}
                    placeholder={'Webinar Topic'}
                    fieldName='name'
                    errors={errors}
                    className={'mb-4'}
                />

                <LabeledInput
                    id={'link'}
                    register={register}
                    placeholder={'Webinar Link'}
                    fieldName='link'
                    errors={errors}
                />

                <DateTimePicker
                    control={control}
                    name='date'
                    errors={errors}
                    type='date'
                />

                <DateTimePicker
                    control={control}
                    name='time'
                    errors={errors}
                    type='time'
                />

                {/* Coach Select */}
                <SelectInput
                    items={selectionOptions.coach}
                    name='instructor_id'
                    register={register}
                    placeholderText='Choose Coach'
                    errors={errors}
                    className={'mb-4'}
                />

                {/* Job Profile Round will be *only* for the chosen coach */}
                <SelectInput
                    items={selectionOptions.jobProfileRound}
                    name='job_profile_round'
                    register={register}
                    placeholderText='Choose Job Profile Round'
                    errors={errors}
                    className={'mb-4'}
                />

                {/* Group Select */}
                <SelectInput
                    items={selectionOptions.group}
                    name='group'
                    register={register}
                    placeholderText='Choose Group'
                    errors={errors}
                />
            </form>
            <div className='flex justify-center mt-6 gap-4'>
                <CustomButton
                    form='add-webinar-form'
                    type='submit'
                    className='font-bold px-4 text-sm block py-4 mt-5'
                    isLoading={isPending}
                >
                    Add Webinar
                </CustomButton>
                <CustomButton onClick={() => closeDialog()} className='font-bold px-4 text-sm block py-4 mt-5 bg-[#FFFFFF] border border-solid border-[#EE201C] text-[#EE201C] hover:bg-red-100'>
                    Cancel
                </CustomButton>
            </div>
        </div>
    );
}
