import TableSkeleton from "./TableSkeleton";
import StatusColumn from "./StatusColumn";
import ScoreColumn from "./ScoreColumn";
import CircularProgress from "../../../../Components/shared/CircleProgress";
const statusColumns = [
  "status",
  "adminStatus",
  "reviewCompletedStatus",
  "reviewFeedBackStatus",
];

function Table({
  columns,
  data = [],
  rowsPerPageOptions = [5, 10, 20],
  isLoading,
  currentPage,
  totalPages,
  rowsPerPage,
  setRowsPerPage,
  setCurrentPage,
  handlePageChange,
  prevPage,
  nextPage,
  onRowClick,
  handleActionChange,
}) {
  // Function to generate page numbers with ellipses
  const getPageNumbers = () => {
    const range = 2; // Number of pages to show after the current page
    const pages = [];

    pages.push(currentPage); // Start with the current page

    // Add next pages after the current page
    for (let i = 1; i <= range; i++) {
      if (currentPage + i < totalPages) {
        pages.push(currentPage + i);
      }
    }

    // Add ellipses and the last page
    if (currentPage + range < totalPages - 1) {
      pages.push("...");
    }

    if (currentPage + range < totalPages) {
      pages.push(totalPages);
    }

    return pages;
  };

  if (isLoading) {
    return <TableSkeleton columns={columns.length} rows={5} />;
  }

  if (!data || data.length === 0) {
    return <p className="text-center p-4">No data available</p>;
  }

  return (
    <div className="w-full overflow-x-auto">
      <table className="table-auto w-full border-separate border-spacing-y-4">
        <thead className="bg-[#273C97] text-white text-nowrap shadow ">
          <tr>
            {columns.map((col, colIndex) => (
              <th
                key={colIndex}
                className={`text-center border-none px-3 py-3 text-sm font-medium text-white  ${
                  colIndex === 0 ? "rounded-l-md" : ""
                } ${colIndex === columns.length - 1 ? "rounded-r-md" : ""} `}
              >
                {col.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={`bg-white even:bg-gray-100 text-nowrap  shadow ${
                onRowClick ? "hover:bg-gray-200  cursor-pointer" : ""
              }`}
              onClick={(e) => {
                const isActionColumn =
                  e.target.tagName === "SELECT" || e.target.closest("select");
                if (!isActionColumn && onRowClick) {
                  onRowClick(row);
                }
              }}
            >
              {columns.map((col, colIndex) => (
                <td
                  key={colIndex}
                  className={`px-4 py-3 border-none text-sm text-center 
                    ${colIndex === 0 ? "rounded-l-md" : ""}
                    ${colIndex === columns.length - 1 ? "rounded-r-md" : ""}
                    ${col.accessor === "gigTitle" ? "whitespace-pre-wrap" : ""}
                    `}
                  style={
                    col.accessor === "gigTitle"
                      ? { whiteSpace: "pre-wrap" }
                      : {}
                  }
                >
                  {statusColumns.includes(col.accessor) ? (
                    <StatusColumn col={row[col.accessor]} />
                  ) : col.accessor === "score_questions" ||
                    col.accessor === "score_video" ||
                    col.accessor === "score_text" ? (
                    <ScoreColumn col={row[col.accessor]} />
                  ) : col.accessor === "action" ? (
                    <select
                      className="border border-gray-300 rounded p-1 text-sm"
                      value={row.status}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleActionChange(row.id, e.target.value);
                      }}
                    >
                      <option value="Pending">Pending</option>
                      <option value="Accepted">Accepted</option>
                      <option value="Rejected">Rejected</option>
                    </select>
                  ) : col.accessor.includes("progress") ? (
                    <CircularProgress
                      value={row[col.accessor]}
                      size={50}
                      strokeWidth={5}
                      color="#259800"
                    />
                  ) : (
                    row[col.accessor]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex items-center mt-4">
        <div className="flex items-center gap-2">
          <span>Show</span>
          <select
            value={rowsPerPage}
            onChange={(e) => setRowsPerPage(Number(e.target.value))}
            className="border border-gray-300 rounded p-1 text-sm"
          >
            {rowsPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <span>Rows</span>
        </div>

        <div className="flex items-center justify-center gap-2 w-[60%]">
          <button
            onClick={() => handlePageChange("previous")}
            className={`px-2 py-1 rounded ${
              !prevPage ? "" : "bg-gray-400 hover:bg-gray-400"
            }`}
            disabled={!prevPage}
          >
            &lt;
          </button>

          {getPageNumbers().map((page, index) =>
            page === "..." ? (
              <span key={page} className="px-2 py-1">
                ...
              </span>
            ) : (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={`px-2 py-1 rounded ${
                  page === currentPage
                    ? "bg-[#273C97] text-white"
                    : "bg-gray-300 hover:bg-gray-400"
                }`}
              >
                {page}
              </button>
            )
          )}

          <button
            onClick={() => handlePageChange("next")}
            className={`px-2 py-1 rounded ${
              !nextPage ? "" : "bg-gray-400 hover:bg-gray-400"
            }`}
            disabled={!nextPage}
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
}

export default Table;
