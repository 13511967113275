import axios from "axios";
import { controlPanelStudentDetailsEndpoint } from "../endPoints";

export const apiPatchStudentProfile = async ({ id, requestBody }) => {
  const response = await axios.patch(
    `${controlPanelStudentDetailsEndpoint}${id}/`,
    requestBody
  );
  return response;
};
