import studentPlaceholder from "../../../assets/defultUserImage.svg";
import instructorPlaceholder from "../../../assets/instructorProfileImage.png";

const ProfileImageViewer = ({ role, profileImage, isLoading }) => {
  const placeholderImage =
    role === "Student" ? studentPlaceholder : instructorPlaceholder;
  return (
    <div className="size-[6.25rem] rounded-full flex items-center justify-center border border-gray-250 overflow-hidden">
      {isLoading && (
        <span className="border-gray-300 size-[3.75rem] animate-spin rounded-full border-4 border-t-blue-600 block"></span>
      )}
      {!isLoading && (
        <img
          src={profileImage || placeholderImage}
          alt="Profile-Image"
          className="object-cover w-full h-full"
        />
      )}
    </div>
  );
};

export default ProfileImageViewer;
