import axios from "axios";
import { InstructorWebinar } from "../endPoints";

export const apiPatchCPWebinars = async ({ id, requestBody }) => {
    const response = await axios.patch(
        `${InstructorWebinar}${id}/`,
        requestBody
    );
    return response;
};
