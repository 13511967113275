import { useEffect, useState } from "react";
import useFetch from "../../../../../../hooks/useFetch";
import {
  CoachingRoundEndPoint,
  dashboardStudentsTrack,
  jobProfileRoundsEndPoint,
} from "../../../../../../Services/endPoints";

const initialOptions = {
  tracks: [],
  coachingRounds: [],
  jobProfiles: [],
};

export const useGetSelectionData = () => {
  const { data: coachingRounds } = useFetch(CoachingRoundEndPoint);
  const { data: jobProfileRounds } = useFetch(jobProfileRoundsEndPoint);

  const { data: trackData } = useFetch(dashboardStudentsTrack);

  const [selectionOptions, setSelectionOptions] = useState(initialOptions);
  useEffect(() => {
    setSelectionOptions({
      tracks: trackData?.map((track) => track.name) || [],
      coachingRounds: coachingRounds?.map((round) => round.code) || [],
      jobProfiles: jobProfileRounds?.map((round) => round.code) || [],
    });
  }, [coachingRounds, jobProfileRounds, trackData]);
  return {
    selectionOptions,
  };
};
