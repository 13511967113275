import CustomButton from "../../../shared/CustomButton";
import { useDialog } from "../../../shared/CustomDialog";
import { useMutation } from "@tanstack/react-query";
import { apiPostLeadsChangeStatus } from "../../../../Services/apiPostLeadsChangeStatus";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

const TakeActionButtons = () => {
  const { closeDialog } = useDialog();
  const [searchParams] = useSearchParams();

  const mutation = useMutation({
    mutationFn: apiPostLeadsChangeStatus,
  });
  const { isPending } = mutation;
  const changeStatusLeads = (status) => {
    const data = {
      status,
      query: searchParams.toString(),
    };
    mutation.mutate(data, {
      onSuccess: () => {
        closeDialog();
        toast.success(`Leads ${status} Successfully`);
      },
    });
  };

  return (
    <div className="w-full flex items-center justify-center gap-3 mt-auto">
      <CustomButton
        className="bg-success hover:bg-green-600"
        isLoading={isPending}
        onClick={() => changeStatusLeads("Accepted")}
      >
        Accept Leads
      </CustomButton>
      <CustomButton
        className="bg-error hover:bg-red-600"
        isLoading={isPending}
        onClick={() => changeStatusLeads("Rejected")}
      >
        Reject Leads
      </CustomButton>
    </div>
  );
};

export default TakeActionButtons;
