import { useSearchParams } from "react-router-dom";
import Filter from "./Filter";
import SelectedFilter from "./SelectedFilter";

const FilterGroup = ({ filters, children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleClearFilters = () => {
    setSearchParams(new URLSearchParams());
  };
  const isClearAllVisible =
    searchParams.size > 0 &&
    !(
      searchParams.size === 1 &&
      (searchParams.has("email") || searchParams.has("student_email"))
    );

  return (
    <div>
      <div className="w-fit flex items-center gap-4 flex-wrap pb-[1.5rem] border-b border-[#CCD1D2]">
        {filters?.map((filter) => (
          <Filter key={filter.key} filter={filter} />
        ))}
        {children}
      </div>
      <div className="mt-4 flex items-center flex-wrap gap-3 ms-4">
        {filters?.map((filter) => (
          <SelectedFilter
            key={filter.key}
            filter={filter}
            options={filter.options}
          />
        ))}
        {isClearAllVisible && (
          <button
            onClick={handleClearFilters}
            className="px-4 py-2 text-sm font-medium bg-[#EFEFEF] border border-gray-300 rounded-full hover:bg-gray-200"
          >
            Clear All
          </button>
        )}
      </div>
    </div>
  );
};

export default FilterGroup;
