import React from 'react';

export default function DeliverableFeedbackDialog({ dialogRef, feedbackData, onClose }) {
    return (
        <dialog
            ref={dialogRef}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[30rem] min-h-[15rem] border-none bg-white rounded-xl outline-none p-5 overflow-visible shadow-lg"
        >
            <div className="flex justify-between items-center mb-4">
                <button onClick={onClose} className="text-[#71747B] hover:text-gray-900">
                    ✖
                </button>
            </div>
            <div className="mt-4">
                {/* Render the list if feedbackData has items */}
                {feedbackData?.length > 0 && (
                    <ul className="space-y-3">
                        {feedbackData.map((item) => (
                            <>
                                <div className="flex flex-row gap-2 items-center">
                                    <h2 className="text-lg font-bold text-[#202020]">Mockup :</h2>
                                    <a
                                        href={item.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500 underline"
                                    >
                                        {item.url}
                                    </a>
                                </div>
                                <li
                                    key={item.id}
                                    className="bg-[#F9FAFB] p-4 rounded-md shadow-sm hover:bg-[#F0F4FF]"
                                >
                                    <p className="mb-1 text-[#273C97] font-semibold">
                                        Instructor Degree : {item.feedback?.degree ?? 'N/A'}
                                    </p>
                                    <p className="mb-1 text-gray-800">
                                        Instructor Description : {item.feedback?.description ?? 'N/A'}
                                    </p>
                                </li>
                            </>
                        ))}
                    </ul >
                )}

                {/* Render fallback if there are no items */}
                {!(feedbackData?.length > 0) && (
                    <p className="text-gray-500">No feedback available yet.</p>
                )}
            </div>
        </dialog>
    );
}
