import { Link } from "react-router-dom";
import SettingsIcon from "../../svgs/SettingsIcon";
import { FaChevronRight } from "react-icons/fa6";
import { pageRoutes } from "../../../constants";
import { useWindowSize } from "../../../hooks/useWindowSize";
const routes = [
  {
    name: "Create New Group",
    href: pageRoutes.controlPanelCreateNewGroup,
  },
  {
    name: "Webinars",
    href: pageRoutes.controlPanelWebinars,
  },
  {
    name: "Gigs",
    href: pageRoutes.controlPanelGigs,
  },
];
const ControlPanelTab = ({ isSideBarOpen, openSideBar }) => {
  const { width } = useWindowSize();
  const isCollapseEnabled = isSideBarOpen || width <= 992;
  return (
    <li
      className={`w-full list-none overflow-visible ${
        isCollapseEnabled ? "collapse collapse-arrow" : ""
      } `}
      onClick={openSideBar}
    >
      {isCollapseEnabled && <input type="checkbox" />}

      <div
        className={`${
          isCollapseEnabled ? "collapse-title" : ""
        }  hover:bg-blue-600 hover:bg-opacity-25 flex gap-2 items-center h-10  px-3 rounded-lg transition-colors duration-150 ease-in-out overflow-hidden cursor-pointer`}
      >
        <SettingsIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0 text-[#202020]" />
        <span
          className={`duration-300 ease-in-out ${
            isCollapseEnabled ? "opacity-100" : "opacity-0"
          }`}
        >
          Control Panel
        </span>
      </div>
      {isCollapseEnabled && (
        <div className="collapse-content flex flex-col gap-2 tablet:w-full w-full ">
          <div className="group w-full relative">
            <div className="cursor-pointer px-3 py-2 rounded-lg w-full flex items-center justify-between hover:bg-[#F1F8FF] hover:text-primary text-base font-semibold border border-[#0000001A]">
              <span className="block">Users</span>

              <FaChevronRight className="size-[0.9rem] text-inherit" />
            </div>
            <ul className="shadow-lg bg-white rounded-lg px-3 py-4 group-hover:flex hidden absolute top-[0] tablet:right-[-8.5rem] right-[2rem] flex-col z-[100] w-[10rem]  ">
              <Link
                className="hover:bg-blue-600 hover:bg-opacity-25 rounded px-4 py-2"
                to={`/${pageRoutes.controlPanelStudents}`}
              >
                Students
              </Link>
              <Link
                className="hover:bg-blue-600 hover:bg-opacity-25 rounded px-4 py-2"
                to={`/${pageRoutes.controlPanelInstructors}`}
              >
                Instructors
              </Link>
            </ul>
          </div>
          {routes.map((route, index) => (
            <Link
              key={index}
              className="hover:bg-blue-600 w-full block text-nowrap hover:bg-opacity-25 rounded px-4 py-2"
              to={`/${route.href}`}
            >
              {route.name}
            </Link>
          ))}
        </div>
      )}
    </li>
  );
};

export default ControlPanelTab;
