import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { toast } from "react-toastify";
import coachRoundTemplate from "../../../../../assets/files/Coaching Round Coaches Template.xlsx";
import { apiPostCoachRound } from "../../../../../Services/ControlPanel/apiPostCoachRound";
import CustomButton from "../../../../shared/CustomButton";
import DownloadTemplateButton from "../../../shared/DownloadTemplateButton";
import UploadCSVInput from "../../../shared/UploadCSVInput";
const CoachRoundPage = () => {
  const [file, setFile] = useState(null);
  const mutation = useMutation({
    mutationFn: apiPostCoachRound,
  });
  const { isPending } = mutation;
  const uploadCsv = () => {
    const formData = new FormData();
    formData.append("file", file);
    mutation.mutate(formData);
    toast.success("Coach Rounds uploaded successfully");
    setFile(null);
  };

  return (
    <>
      <div className="mt-[2rem] w-full flex flex-wrap gap-y-[1.25rem] gap-x-[20%]">
        <div className="max-w-[20rem]">
          <h2 className="text-primary text-base font-semibold mb-2 block">
            Upload Coaches sheet
          </h2>
          <p className="text-[#7C8493] text-xs mb-[1.5rem]">
            Please ensure the Coaches file you upload is in CSV or Excel format
            (.csv, .xls, .xlsx). To avoid issues, you can download the provided
            template to ensure your file meets the required format.
          </p>
          <DownloadTemplateButton
            text="Download Template"
            file={coachRoundTemplate}
          />
        </div>
        <UploadCSVInput file={file} setFile={setFile} />
      </div>
      <CustomButton
        className="py-3 block mx-auto mt-[3rem] w-[15rem] font-semibold text-base"
        isLoading={isPending}
        onClick={uploadCsv}
        disabled={!file}
      >
        Save
      </CustomButton>
    </>
  );
};

export default CoachRoundPage;
