import React from "react";

function GraduatuedCap({ className }) {
  return (
    <svg
      className={className}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1316 5.651V11.0751C14.1316 11.4491 14.1316 11.636 14.0622 11.7997C14.0394 11.8535 14.0113 11.905 13.9782 11.9532C13.8777 12.0998 13.7201 12.2005 13.405 12.402L9.84847 14.6761C9.48327 14.9097 9.30067 15.0264 9.09935 15.0494C9.03334 15.057 8.96668 15.057 8.90066 15.0494C8.69935 15.0264 8.51674 14.9097 8.15154 14.6761L4.59496 12.402C4.27987 12.2005 4.12233 12.0998 4.02181 11.9532C3.98873 11.905 3.96057 11.8535 3.93775 11.7997C3.86842 11.636 3.86842 11.449 3.86842 11.0751V5.651M16.5 4.50438L9.65858 1.35516C9.37109 1.22282 9.22734 1.15665 9.0754 1.14351C9.02523 1.13917 8.97477 1.13917 8.9246 1.14351C8.77266 1.15665 8.62891 1.22282 8.34142 1.35516L1.5 4.50438L8.3137 7.80308C8.61278 7.94787 8.76232 8.02027 8.92121 8.03463C8.97363 8.03937 9.02638 8.03937 9.0788 8.03463C9.23769 8.02027 9.38723 7.94787 9.68631 7.80308L16.5 4.50438ZM16.5 4.50438V12.5562C16.5 12.8989 16.5 13.0703 16.4405 13.2233C16.4209 13.2738 16.3966 13.3223 16.368 13.3683C16.2813 13.5077 16.1442 13.6105 15.87 13.8162L14.625 14.7499"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default GraduatuedCap;
