import { useInfiniteQuery } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import dateFormat from 'dateformat';
import { apiGetSingleStudentGigs } from '../../../../Services/apiGetSingleStudentGigs';
import DonutChart from '../DonutChart';
import CollapsibleCard from './CollapsibleCard';
import StudentGigDialog from './StudentGigDialog';

export default function StudentDetailsGigs({ id, statistics }) {
    const studentGigsDialog = useRef(null);
    const [studentGigData, setStudentGigData] = useState(null);

    const {
        data,
        isLoading,
        isError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: ['studentWebinars', id],
        queryFn: ({ pageParam = 1 }) => apiGetSingleStudentGigs(id, pageParam),
        getNextPageParam: (lastPage) => {
            if (!lastPage.next) {
                return undefined;
            }
            const nextURL = new URL(lastPage.next);
            const nextPage = nextURL.searchParams.get('page');
            return nextPage;
        },
    });

    const openStudentGigDialog = (studentGigData) => {
        setStudentGigData(studentGigData);
        studentGigsDialog.current?.showModal();
    };

    const closeStudentGigDialog = () => {
        setStudentGigData(null);
        studentGigsDialog.current?.close();
    };

    if (!statistics) {
        return <p>Loading...</p>;
    }

    const { total_freelance_jobs, submitted_freelance, rejected_freelance, pending_freelance, completed_freelance } = statistics;
    const chartColors = ["#273C97", '#F13E3E', "#FFC107"];

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (isError) {
        return <p>Something went wrong while loading Gigs.</p>;
    }

    return (
        <div className='flex flex-row gap-4 lg:flex-nowrap flex-wrap'>
            <div className='flex flex-row gap-4 mt-4 flex-grow basis-3/4'>
                <div className='flex-grow basis-3/4'>
                    {data.pages.map((page, pageIndex) => (
                        <React.Fragment key={pageIndex}>
                            {page.results.map((gigs) => (
                                <CollapsibleCard
                                    key={gigs.id}
                                    title={gigs.title}
                                    subtitle={dateFormat(gigs.created_on, "dd/mm/yyyy")}
                                    status={gigs.status}
                                    statusColor={
                                        gigs.status
                                            ? 'text-[#259800]'
                                            : 'text-[#F13E3E]'
                                    }
                                    details={[
                                        { label: 'Category', value: gigs.category },
                                        { label: 'Platform', value: gigs.platform },
                                        {
                                            label: 'Price',
                                            value: gigs.price
                                        },
                                    ]}
                                    buttonText="View details"
                                    onButtonClick={() => openStudentGigDialog(gigs)}
                                />
                            ))}
                        </React.Fragment>
                    ))}

                    {hasNextPage && (
                        <button
                            onClick={() => fetchNextPage()}
                            disabled={isFetchingNextPage}
                            className='text-[#2D7CDE] text-base mt-2'
                        >
                            {isFetchingNextPage ? 'Loading more...' : 'Show more...'}
                        </button>
                    )}
                </div>
            </div>

            <div className="flex-grow basis-1/4">
                {completed_freelance > 0 &&
                    <h2 className="text-[#259800] text-lg p-2 mb-2 border-2 border-[#D3D2D2] text-center rounded-md">Completed One Gig</h2>
                }
                <h2 className="text-[#000000] text-xl font-medium mb-2">
                    Gigs
                </h2>
                <h3 className="text-lg text-[#273C97] font-normal mb-4">
                    total number of gigs:
                    <span className="text-lg text-[#263238] font-medium">
                        {total_freelance_jobs}
                    </span>
                </h3>
                <DonutChart
                    data={[submitted_freelance, rejected_freelance, pending_freelance]}
                    labels={['Proposal Submitted', 'Rejected', "Pending Review"]}
                    customOptions={{
                        colors: chartColors,
                    }}
                />
            </div>
            <StudentGigDialog
                dialogRef={studentGigsDialog} // If you want to use a ref, you can pass it here
                onClose={closeStudentGigDialog}
                dialogData={studentGigData} // Pass the selected gig data
            />
        </div>
    );
}
