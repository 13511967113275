import React, { forwardRef } from 'react';
import { apiPatchRejectGig } from '../../../../Services/ControlPanel/apiPatchRejectGig';
import * as yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import CustomButton from '../../../shared/CustomButton';
import ErrorMessage from '../../../shared/ErrorMessage';

const schema = yup.object({
    comment: yup
        .string()
        .required('Password is required')
        .min(20, 'Comment must be at least 20 characters')
        .max(100, 'Comment cannot exceed 100 characters'),
});

const RejectDialog = forwardRef(({ onClose, id, handleRefetch }, ref) => {
    const mutation = useMutation({
        mutationFn: apiPatchRejectGig,

    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const onSubmit = (data) => {
        mutation.mutate({ id, data }, {

            onSuccess: () => {
                toast.success('Gig rejected successfully');
                onClose();
                handleRefetch();
            },
            onError: (err) => {
                toast.error('Error rejecting gig');
            },
        }
        );
    };

    const { isPending } = mutation;

    return (
        <dialog
            ref={ref}
            className='fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[30rem] min-h-[15rem] border-none bg-[#fff] rounded-xl outline-none p-5 overflow-visible'
        >
            <div className='flex justify-end items-center mb-4'>
                <button
                    onClick={onClose}
                    className='text-[#71747B] hover:text-gray-900'
                >
                    ✖
                </button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex flex-col items-start mt-4 gap-2'>
                    <span className='text-[#000000] text-base font-bold whitespace-pre-wrap text-left mb-4'>
                        Please provide a reason for rejecting this gig. This feedback will
                        be shared with the student.
                    </span>
                    <label className='form-control'>
                        <textarea
                            className={`textarea textarea-bordered h-28 w-96 ${errors.comment ? 'border-red-500' : ''
                                }`}
                            placeholder='Write your Comments'
                            {...register('comment')}
                        ></textarea>
                        <ErrorMessage message={errors.comment?.message} isVisible={Boolean(errors.comment)} />
                        <div className='label'>
                            <span className='label-text-alt'>Minimum 100 characters</span>
                            <span className='label-text-alt'>100 to 500 characters</span>
                        </div>
                    </label>
                </div>
                <div className='flex justify-center mt-6'>
                    <CustomButton
                        type="submit"
                        className=' font-semibold text-base px-5 py-3'
                        isLoading={isPending}
                    >
                        Confirm
                    </CustomButton>
                    <button
                        type='button'
                        onClick={onClose}
                        className='font-semibold text-base px-5 py-3 rounded-lg ml-3 border text-[#71747B] border-solid border-gray-300 bg-white'
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </dialog>
    );
});

export default RejectDialog;
