import { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";

import FilterGroup from "../../../Components/AdminDashboard/shared/Filters/FilterGroup";
import {
  CoachingRoundEndPoint,
  jobProfileRoundsEndPoint,
} from "../../../Services/endPoints";
const initialFilters = [
  {
    name: "Coaching Round",
    key: "coaching_round",
  },
  {
    name: "Job Profile Code",
    key: "job_profile_round",
  },
  {
    name: "Coach Type",
    key: "role",
    options: [
      {
        name: "Webinar Coach",
        value: "1",
      },
      {
        name: "Q/A Coach",
        value: "2",
      },
      {
        name: "All",
        value: "3",
      },
    ],
  },
];
const InstructorFilters = () => {
  const [filters, setFilters] = useState(initialFilters);
  const { data: coachingRounds } = useFetch(CoachingRoundEndPoint);
  const { data: jobProfileRounds } = useFetch(jobProfileRoundsEndPoint);

  useEffect(() => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => {
        switch (filter.key) {
          case "coaching_round":
            return {
              ...filter,
              options: coachingRounds?.map((round) => round.code) || [],
            };
          case "job_profile_round":
            return {
              ...filter,
              options: jobProfileRounds?.map((round) => round.code) || [],
            };

          default:
            return filter;
        }
      })
    );
  }, [coachingRounds, jobProfileRounds]);
  return <FilterGroup filters={filters} />;
};

export default InstructorFilters;
