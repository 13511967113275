import { Link, useLocation } from "react-router-dom";
import { pageRoutes } from "../../../../constants";

const tabs = [
  {
    name: "Job Profile",
    href: pageRoutes.controlPanelJobProfile,
  },
  {
    name: "Coach Round",
    href: pageRoutes.controlPanelCoachRound,
  },
  {
    name: "Upload Students",
    href: pageRoutes.controlPanelUploadStudents,
  },
];
const Tabs = () => {
  const pathname = useLocation().pathname;
  return (
    <div className="flex gap-[15%] border-b border-[#D6DDEB] sm:w-[70%] mt-[2rem] overflow-auto">
      {tabs.map((tab) => (
        <Link
          className={`text-primary font-semibold pb-4 whitespace-nowrap ${pathname.includes(tab.href) ? "border-b-[2px] border-primary" : ""
            }`}
          key={tab.name}
          to={tab.href}
        >
          {tab.name}
        </Link>
      ))}
    </div>
  );
};

export default Tabs;
