import React, { useState } from "react";
import styles from "./ChangePassword.module.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import postChangerPassword from "../../Services/apiPostChangePassword";
import { useCookies } from "react-cookie";

export default function ChangePassword() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([
    "role",
    "access-token",
    "refresh-token",
    "email",
  ]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const logout = () => {
    setCookie("role", "", {
      maxAge: 0,
    });
    setCookie("access-token", "", {
      maxAge: 0,
    });
    setCookie("refresh-token", "", {
      maxAge: 0,
    });
    setCookie("email", "", {
      maxAge: 0,
    });

    navigate("/login");
  };

  // Validation schema
  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
      .min(8, "New password must be at least 8 characters")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Passwords must match")
      .required("Confirm password is required"),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        await postChangerPassword(
          values.oldPassword,
          values.newPassword,
          values.confirmPassword
        );
        setShowSuccessPopup(true);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          setErrors({ submit: error.response.data.detail });
        } else {
          setErrors({ submit: "Failed to change password. Please try again." });
        }
        console.error("Error:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className={styles.tabContent}>
      <div className="flex-row items-start gap-3 my-3 mb-4">
        <h3 className="text-[#000000DE] font-medium text-2xl md:text-xl">
          Reset your password
        </h3>
        <h4 className="text-[#000000DE] text-lg md:text-base">
          Type in your new password
        </h4>
      </div>
      <form onSubmit={formik.handleSubmit} className={styles.passwordForm}>
        <label>
          <h6 className="mb-2">Old password *</h6>
          <input
            type="password"
            name="oldPassword"
            placeholder="Old password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.oldPassword}
          />
          {formik.touched.oldPassword && formik.errors.oldPassword ? (
            <div className="text-red-600 text-sm mt-1">
              {formik.errors.oldPassword}
            </div>
          ) : null}
        </label>
        <label>
          <h6 className="mb-2">New password *</h6>
          <input
            type="password"
            name="newPassword"
            placeholder="New password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
          />
          {formik.touched.newPassword && formik.errors.newPassword ? (
            <div className="text-red-600 text-sm mt-1">
              {formik.errors.newPassword}
            </div>
          ) : null}
        </label>
        <label>
          <h6 className="mb-2">Retry new password *</h6>
          <input
            type="password"
            name="confirmPassword"
            placeholder="Retry new password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="text-red-600 text-sm mt-1">
              {formik.errors.confirmPassword}
            </div>
          ) : null}
        </label>
        {formik.errors.submit && (
          <div className="text-red-600 text-sm mt-1">
            {formik.errors.submit}
          </div>
        )}
        <button
          type="submit"
          className={`mt-5 w-1/4 md:w-fit ${styles.resetButton}`}
          disabled={formik.isSubmitting}
        >
          RESET
          <ArrowForwardIcon className="ml-2" />
        </button>
      </form>
      {showSuccessPopup && (
        <div className={styles.successPopup}>
          <span>
            Password changed successfully.
            <br />
            Please log in again.{" "}
          </span>
          <button className="btn-primary w-fit m-auto" onClick={logout}>
            OK
          </button>
        </div>
      )}
    </div>
  );
}
