import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const usePaginationNumbers = (url, refetchTrigger, disabled = false) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [searchParams] = useSearchParams();
  const fetchData = async (fetchUrl) => {
    // Remove trailing `&` or `?` from the base URL to prevent duplication
    const cleanedUrl = fetchUrl.replace(/[&?]$/, "");
    const separator = cleanedUrl.includes("?") ? "&" : "?";
    const finalUrl = `${cleanedUrl}${separator}page=${page}&page_size=${rowsPerPage}`;

    setIsLoading(true);
    try {
      const response = await axios.get(finalUrl);
      setResponse(response.data);
      setData(response.data.results);
      setTotalPages(Math.ceil(response.data.count / rowsPerPage));
      setNextPage(response.data.next);
      setPrevPage(response.data.previous);
      setError(null);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (disabled) return;
    fetchData(url);
  }, [url, page, rowsPerPage, disabled, refetchTrigger]);

  const handlePageChange = (direction) => {
    if (direction === "next" && nextPage) {
      setPage((prevPage) => prevPage + 1);
    } else if (direction === "previous" && prevPage) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };
  useEffect(() => {
    setPage(1);
  }, [searchParams]);

  return {
    response,
    data,
    page,
    totalPages,
    rowsPerPage,
    isLoading,
    error,
    setCurrentPage: setPage,
    setRowsPerPage: handleRowsPerPageChange,
    handlePageChange,
    nextPage,
    prevPage,
  };
};

export default usePaginationNumbers;
