import React from "react";
import { Link } from "react-router-dom";
import { pageRoutes } from "../../../../constants";
import Khamsat from "../../../../assets/5msat.svg";
import Mostaqal from "../../../../assets/Ellipse 378.svg";
import Fiver from "../../../../assets/images 1.svg";
import DefaultFreelanceIcon from "../../../../assets/JOB icon.svg";
import FreelanceYard from "../../../../assets/Mostaqel.svg";
import PeoplePerHr from "../../../../assets/pepoleperhr.svg";
import Upwork from "../../../../assets/upwork 1.svg";
import GigProgressBar from "./GigProgressBar";
import circlesImage from "../../../../assets/dashboard-circles.svg";

const platformLogos = {
  upwork: Upwork,
  fiverr: Fiver,
  peopleperhour: PeoplePerHr,
  mostaql: Mostaqal,
  freelanceyard: FreelanceYard,
  khamsat: Khamsat,
  tasmeemme: DefaultFreelanceIcon,
  other: DefaultFreelanceIcon,
};
const GigsStats = ({ data }) => {
  return (
    <div className="w-full relative flex flex-wrap gap-[1rem] bg-[#F9F9FA] p-6 shadow rounded">
      <Link
        to={`/${pageRoutes.gigs}`}
        className="text-white text-center rounded-full bg-primary hover:bg-primary-light transition-all duration-300 ease-in-out py-1 px-6 ms-auto tablet:text-xs text-sm absolute right-4 top-4"
      >
        View All
      </Link>
      <div className="sm:w-[65%] w-full ">
        <div className="flex items-center justify-between">
          <h2 className="tablet:text-sm text-base font-semibold">Gigs</h2>
        </div>
        <p className="text-sm mt-2 mb-[1.5rem]">
          Analyze the top 3 freelance platforms where our students successfully
          secure gigs.
        </p>
        <div className="flex flex-col gap-[1.5rem]">
          {Object.entries(data?.platform_data ?? {})?.map(([key, value]) => (
            <div
              className="flex sm:flex-nowrap flex-wrap gap-y-6 items-start"
              key={key}
            >
              <div className="w-[10rem] flex items-center">
                <img
                  className="size-[2rem]"
                  src={platformLogos[key.toLowerCase()] ?? DefaultFreelanceIcon}
                  alt={key}
                />
                <span className="ms-3">{key}</span>
              </div>

              <GigProgressBar data={value} />
            </div>
          ))}
        </div>
      </div>

      <div className="h-[15rem] mx-auto self-center flex-shrink-0 relative">
        <img className="cover-image" src={circlesImage} alt="" />
        <div className="text-white text-center absolute top-[12%] left-[15%]">
          <span className="block text-lg">{data?.completed_freelance}</span>
          <span className="tablet:text-xs text-sm text-center ms-1">
            Approved
          </span>
        </div>
        <div className="text-white text-center absolute top-[31%] right-[10%]">
          <span className="block text-3xl">{data?.submitted_freelance}</span>
          <span className="tablet:text-xs text-sm text-center">
            Proposal Submitted
          </span>
        </div>
        <div className="text-white text-center absolute top-[62%] left-[10%]">
          <span className="block text-lg">{data?.rejected_freelance}</span>
          <span className="tablet:text-xs text-sm text-center ms-1">
            Rejected
          </span>
        </div>
      </div>
    </div>
  );
};

export default GigsStats;
