import React from "react";
import { useCookies } from "react-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ControlPanelTab from "../../Components/AdminDashboard/ControlPanel/ControlPanelTab";
import LogoutIcon from "../../Components/svgs/LogoutIcon";
import SettingsIcon from "../../Components/svgs/SettingsIcon";
import { pageRoutes, roles } from "../../constants";
import { sidebarLookup } from "../../constants/sidebarLookup";
import { useWindowSize } from "../../hooks/useWindowSize";

const NavigationTabs = ({ openSideBar, isSideBarOpen, closeDrawer }) => {
  const { width } = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([
    "role",
    "access-token",
    "refresh-token",
    "email",
  ]);
  const tabs = sidebarLookup[cookies.role];

  const logout = () => {
    setCookie("role", "", {
      maxAge: 0,
    });
    setCookie("access-token", "", {
      maxAge: 0,
    });
    setCookie("refresh-token", "", {
      maxAge: 0,
    });
    setCookie("email", "", {
      maxAge: 0,
    });
    localStorage.clear();

    navigate("/login");
  };
  return (
    <>
      {tabs.map((tab) => (
        <li
          key={tab.pageRoute}
          className="w-full list-none"
          onClick={closeDrawer}
        >
          <Link
            className={`${
              location.pathname.includes(`/${tab.pageRoute}`)
                ? "bg-primary text-white hover:bg-primary"
                : "hover:bg-blue-600 hover:bg-opacity-25"
            } flex gap-2 items-center h-10 px-3 rounded-lg transition-colors duration-150 ease-in-out  overflow-hidden`}
            to={`/${tab.pageRoute}`}
          >
            {tab.icon}
            <span
              className={`duration-300 ease-in-out ${
                isSideBarOpen || width <= 992 ? "opacity-100" : "opacity-0"
              }`}
            >
              {tab.label}
            </span>
          </Link>
        </li>
      ))}
      {cookies.role === roles.adminEditor && (
        <ControlPanelTab
          openSideBar={openSideBar}
          isSideBarOpen={isSideBarOpen}
          closeDrawer={closeDrawer}
        />
      )}
      <li className="w-full list-none mt-auto" onClick={closeDrawer}>
        <Link
          className={`${
            location.pathname === pageRoutes.settings
              ? "bg-primary text-white"
              : "hover:bg-blue-600 hover:bg-opacity-25"
          } flex gap-2 items-center h-10 px-3 rounded-lg transition-colors duration-150 ease-in-ou overflow-hidden ${
            isSideBarOpen || width <= 992 ? "opacity-100" : "opacity-0"
          }`}
          to={pageRoutes.settings}
        >
          <SettingsIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0 text-[#202020]" />
          <span className={`duration-300 ease-in-out`}>Settings</span>
        </Link>
      </li>
      <li className="w-full list-none">
        <button
          className={`flex gap-2 items-center h-10 px-3 rounded-lg transition-colors duration-150 ease-in-out overflow-hidden text-red-250 hover:bg-red-100 ${
            isSideBarOpen || width <= 992 ? "opacity-100" : "opacity-0"
          }`}
          onClick={logout}
        >
          <LogoutIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />
          <span className={`text-red-250 duration-300 ease-in-out`}>
            Logout
          </span>
        </button>
      </li>
    </>
  );
};

export default NavigationTabs;
