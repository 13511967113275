import { useEffect } from "react";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CustomButton from "../../../../../shared/CustomButton";
import LabeledInput from "../../../../../shared/LabeledInput";
import ChangePasswordDialog from "../../../../shared/ChangePassword/ChangePasswordDialog";
import CopyId from "../../../../shared/CopyId";
import ProfileImageViewer from "../../../../shared/ProfileImageViewer";
import SelectInputEdit from "./SelectInputEdit";
import { useEditInstructorForm } from "./useEditInstructorForm";

const EditInstructorForm = ({ currentInstructorData, isLoading }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors },
    isPending,
    onSubmit,
  } = useEditInstructorForm();
  useEffect(() => {
    const defaultInputValues = {
      name: currentInstructorData?.name ?? "",
      email: currentInstructorData?.user.email ?? "",
      phoneNumber: currentInstructorData?.phone ?? "",
      coachingRounds: "",
      jobProfiles: "",
    };
    Object.entries(defaultInputValues).forEach(([key, value]) => {
      setValue(key, value);
    });
  }, [currentInstructorData]);

  return (
    <div className="mt-[2rem] tablet:w-[73%] w-full max-w-[90rem] rounded-lg shadow bg-white px-[1.5rem] py-[1.5rem]">
      <div className="flex md:flex-nowrap flex-wrap items-start gap-[2rem]">
        <ProfileImageViewer
          role={"Instructor"}
          profileImage={currentInstructorData?.profile_image}
          isLoading={isLoading}
        />
        <div className="flex md:flex-row items-start gap-[2rem] flex-col-reverse flex-1">
          <form
            id="edit-student-form"
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-[30rem] flex flex-col gap-[1.25rem]"
          >
            <LabeledInput
              id={"fullName"}
              label={"Name"}
              register={register}
              placeholder={"Full Name"}
              fieldName="name"
              errors={errors}
            />
            <LabeledInput
              id={"email"}
              label={"Email"}
              register={register}
              placeholder={"Email"}
              fieldName="email"
              errors={errors}
            />

            <div>
              <label
                htmlFor="phoneNumber"
                className="text-primary text-base font-medium mb-2 block"
              >
                Phone
              </label>
              <div className="input input-bordered rounded border-[#DFEAF2] w-full flex items-center">
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <PhoneInput
                      className="w-full"
                      countrySelectProps={{ unicodeFlags: true }}
                      placeholder="Phone Number"
                      id="phoneNumber"
                      defaultCountry="EG"
                      onChange={onChange}
                      value={value}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
              {errors.phoneNumber && (
                <p className="text-[red] ms-2 text-sm mt-2 w-full">
                  {errors.phoneNumber.message}
                </p>
              )}
            </div>

            <SelectInputEdit
              label={"Coaching Rounds"}
              placeholderText={"Add New Coaching Rounds"}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              watch={watch}
              inputKey={"coachingRounds"}
              apiCurrentValues={currentInstructorData?.coaching_rounds}
            />
            <SelectInputEdit
              label={"Job Profile"}
              placeholderText={"Add New Job Profile"}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
              watch={watch}
              inputKey={"jobProfiles"}
              apiCurrentValues={currentInstructorData?.job_profile_rounds}
            />

            {errors.root && (
              <p className="text-[#DC2626] text-sm mt-2 w-fit mx-auto">
                {errors.root.message}
              </p>
            )}
          </form>
          <div className="flex flex-wrap ms-auto gap-x-2 gap-y-4 justify-end items-center">
            <CopyId id={currentInstructorData?.id} />
            <ChangePasswordDialog
              role={"Instructor"}
              email={currentInstructorData?.user?.email}
            />
          </div>
        </div>
      </div>
      <CustomButton
        form="edit-student-form"
        type="submit"
        className="font-bold px-4 text-sm block ms-auto py-4 mt-5"
        isLoading={isPending}
      >
        Save Changes
      </CustomButton>
    </div>
  );
};
export default EditInstructorForm;
