import React, { useRef } from 'react';
import dateFormat from 'dateformat';
import RejectDialog from '../../../../Components/AdminDashboard/ControlPanel/CPGigsPage/RejectDialog';
import ApproveDialog from '../../../../Components/AdminDashboard/ControlPanel/CPGigsPage/ApproveDialog';

export default function GigsDetails({
    id,
    name,
    email,
    url,
    proposal_file,
    status_proof_screenshot,
    title,
    date,
    platform,
    price,
    buttonType = 'single',
    handleRefetch
}) {
    const dialogRef = useRef(null);
    const approveDialogRef = useRef(null);
    const rejectDialogRef = useRef(null);

    const openDialog = () => dialogRef.current?.showModal();
    const closeDialog = () => dialogRef.current?.close();

    const openApproveDialog = () => {
        closeDialog();
        approveDialogRef.current?.showModal();
    };

    const closeApproveDialog = () => approveDialogRef.current?.close();

    const openRejectDialog = () => {
        closeDialog();
        rejectDialogRef.current?.showModal();
    };

    const closeRejectDialog = () => rejectDialogRef.current?.close();

    return (
        <div>
            <span>
                {
                    <button
                        onClick={openDialog}
                        className='btn-primary bg-[#273C97] font-medium px-5 py-3 rounded-lg'
                    >
                        view
                    </button>
                }
            </span>
            <dialog
                ref={dialogRef}
                className='fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-fit min-h-[15rem] border-none bg-[#fff] rounded-xl outline-none p-5 overflow-visible'
            >
                <div className='flex justify-end items-center mb-4'>
                    <button
                        onClick={closeDialog}
                        className='text-[#71747B] hover:text-gray-900'
                    >
                        ✖
                    </button>
                </div>
                <div className='flex flex-row gap-4 w-full'>
                    <div className='w-1/2 pr-2 border-r-2 border-[#E3E8EF]'>
                        <h2 className='text-base font-bold text-[#111729] text-left'>
                            Student Details
                        </h2>
                        <div className='border border-[#E3E8EF] p-3 rounded-xl my-4'>
                            <div className='flex gap-4 items-center'>
                                <h3 className='text-base font-bold text-[#111729]'>Name</h3>
                                <p className='text-[#677489]'>{name}</p>
                            </div>
                            <div className='flex gap-4 items-center'>
                                <h3 className='text-base font-bold text-[#111729]'>Email</h3>
                                <p className='text-[#677489]'>{email}</p>
                            </div>
                        </div>
                        <h2 className='text-base font-bold text-[#111729] text-left'>
                            Gig links
                        </h2>
                        <div className='border border-[#E3E8EF] p-3 rounded-xl my-4'>
                            <div className='flex flex-col items-start'>
                                <h3 className='text-base font-bold text-[#111729]'>URL</h3>
                                {url ? (
                                    <a
                                        href={url}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='text-[#677489]'
                                    >
                                        View URL
                                    </a>
                                ) : (
                                    <p className='text-[#677489]'>N/A</p>
                                )}
                            </div>
                            <div className='flex flex-col items-start'>
                                <h3 className='text-base font-bold text-[#111729]'>Proposal</h3>
                                {proposal_file ? (
                                    <a
                                        href={proposal_file}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='text-[#677489]'
                                    >
                                        View Proposal
                                    </a>
                                ) : (
                                    <p className='text-[#677489]'>N/A</p>
                                )}
                            </div>
                            <div className='flex flex-col items-start'>
                                <h3 className='text-base font-bold text-[#111729]'>
                                    Proof of Completion
                                </h3>
                                {status_proof_screenshot ? (
                                    <a
                                        href={status_proof_screenshot}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='text-[#677489]'
                                    >
                                        View Proof
                                    </a>
                                ) : (
                                    <p className='text-[#677489]'>N/A</p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='w-1/2 pl-2'>
                        <h2 className='text-base font-bold text-[#111729] text-left'>
                            Gig Details
                        </h2>
                        <div className='border border-[#E3E8EF] p-3 rounded-xl my-4'>
                            <div className='flex gap-4 items-center'>
                                <h3 className='text-base font-bold text-[#111729]'>
                                    Gig Title
                                </h3>
                                <p className='text-[#677489] whitespace-pre-wrap'>{title}</p>
                            </div>
                            <div className='flex gap-4 items-center'>
                                <h3 className='text-base font-bold text-[#111729]'>Date</h3>
                                <p className='text-[#677489]'>
                                    {dateFormat(date, 'dd/mm/yyyy')}
                                </p>
                            </div>
                            <div className='flex gap-4 items-center'>
                                <h3 className='text-base font-bold text-[#111729]'>Price</h3>
                                <p className='text-[#677489]'>{price}</p>
                            </div>
                            <div className='flex gap-4 items-center'>
                                <h3 className='text-base font-bold text-[#111729]'>Platform</h3>
                                <p className='text-[#677489]'>{platform}</p>
                            </div>
                            {/* <div className='flex flex-col items-start'>
                                <h3 className='text-base font-bold text-[#111729]'>
                                    Description
                                </h3>
                                <p className='text-[#677489]'>chemist.ahmedkamel@gmail.com</p>
                            </div> */}
                            <div className='flex flex-col items-start'>
                                <h3 className='text-base font-bold text-[#111729]'>
                                    Admin Comments
                                </h3>
                                <p className='text-[#677489]'>chemist.ahmedkamel@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <button onClick={closeDialog} className='btn-primary px-8 py-2 mt-3'>OK</button> */}
                {/* Conditional rendering of buttons based on buttonType */}
                {buttonType === 'single' ? (
                    <button onClick={closeDialog} className='btn-primary px-8 py-2 mt-3'>
                        OK
                    </button>
                ) : (
                    <div className='mt-3'>
                        <button
                            onClick={openApproveDialog}
                            className='btn-primary !bg-[#259800] font-medium px-5 py-3 rounded-lg mr-2'
                        >
                            Approve
                        </button>
                        <button
                            onClick={openRejectDialog}
                            className='btn-primary !bg-[#DC2626] font-medium px-5 py-3 rounded-lg'
                        >
                            Reject
                        </button>
                    </div>
                )}
            </dialog>
            <ApproveDialog
                ref={approveDialogRef}
                onClose={closeApproveDialog}
                id={id}
                handleRefetch={handleRefetch}
            />
            <RejectDialog
                ref={rejectDialogRef}
                onClose={closeRejectDialog}
                id={id}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}
