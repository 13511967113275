import React from "react";
import AdminUserInfo from "./AdminUserInfo";

function Header({ children }) {
  return (
    <div className="py-4 rounded-md flex gap-2 md:justify-start justify-center ">
      <div className="tablet:w-full lg:w-[80%]"> {children}</div>
      <div className="mb-4 tablet:block hidden">
        <AdminUserInfo />
      </div>
    </div>
  );
}

export default Header;
