import React from 'react';
import Benefits1 from '../../../Components/svgs/Benefits1';
import Benefits2 from '../../../Components/svgs/Benefits2';
import Benefits3 from '../../../Components/svgs/Benefits3';
import { useTranslation } from 'react-i18next';

export default function Benefits() {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.language === 'ar';

    return (
        <div
            style={{
                background:
                    'linear-gradient(90deg, rgba(157, 204, 255, 0.7) 0%, rgba(163, 180, 200, 0.7) 100%)',
            }}
            className='py-12 my-12'
        >
            <div className='custom-container flex sm:flex-row flex-col justify-around sm:gap-10 sm:items-center'>
                <div className='sm:w-1/5'>
                    <div className={`${isRTL ? 'flex flex-row gap-4' : 'sm:block flex flex-row gap-4'}`}>
                        <h1 className='text-5xl font-normal text-[#0A0338]'>
                            {t('extra')}
                        </h1>
                        <span className='text-5xl font-semibold text-[#2F327D]'>{t('benefits')}</span>
                    </div>
                    <h3 className='font-normal text-xl text-[#000000] pt-6'>
                        {t('benefitsDescription')}
                    </h3>
                </div>
                <div className='sm:w-2/5'>
                    <div className={`flex gap-4 items-center sm:my-10 ${isRTL ? 'sm:mr-14 sm:w-max' : ''}`}>
                        <Benefits1 className={`${isRTL ? 'scale-x-[-1]' : ''}`} />
                        <h2 className='text-xl font-normal text-[#000000]'>
                            {t('benefits1')}
                        </h2>
                    </div>
                    <div className={`flex gap-4 items-center sm:my-10 sm:ml-12 ${isRTL ? 'sm:w-max' : ''}`}>
                        <Benefits2 className={`${isRTL ? 'scale-x-[-1]' : ''}`} />
                        <h2 className='text-xl font-normal text-[#000000]'>
                            {t('benefits2')}
                        </h2>
                    </div>
                    <div className={`flex gap-4 items-center sm:my-10 ${isRTL ? 'sm:mr-14 sm:w-max' : ''}`}>
                        <Benefits3 className={`${isRTL ? 'scale-x-[-1]' : ''}`} />
                        <h2 className='text-xl font-normal text-[#000000]'>
                            {t('benefits3')}
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );
}
