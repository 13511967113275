import axios from "axios";
import { controlPanelInstructorDetailsEndpoint } from "../endPoints";

export const apiPatchInstructorProfile = async ({ id, requestBody }) => {
  const response = await axios.patch(
    `${controlPanelInstructorDetailsEndpoint}${id}/`,
    requestBody
  );
  return response;
};
