import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import { pageRoutes } from "../../../constants";
const chartOptions = {
  chart: {
    type: "donut",
  },
  colors: ["#1e40af", "#FFC107"],
  labels: ["Submitted to Coach", "Reviewed by AI"],
  legend: {
    show: false,
  },
  plotOptions: {
    pie: {
      donut: {
        size: "55%",
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
};
export default function ProposalsStats({ data }) {
  const labels = [
    {
      name: "Submitted to Coach",
      value: data?.proposals_count ?? 0,
      color: "#1e40af",
    },
    {
      name: "Reviewed by AI",
      value: data?.ai_proposal_count ?? 0,
      color: "#FFC107",
    },
  ];
  const series = [data?.proposals_count ?? 0, data?.ai_proposal_count ?? 0];

  return (
    <div className="max-w-[50rem] xl:w-[45%] lg:w-[55%] w-full bg-[#F9F9FA] p-6 shadow rounded">
      <div className="flex flex-row items-center justify-between">
        <h2 className="tablet:text-sm text-base font-semibold">Proposals</h2>
        <Link
          to={`/${pageRoutes.reviews}`}
          className="text-white text-center rounded-full bg-primary hover:bg-primary-light transition-all duration-300 ease-in-out py-1 px-6 ms-auto tablet:text-xs text-sm"
        >
          View All
        </Link>
      </div>
      <div className="w-full flex justify-between tablet:flex-nowrap flex-wrap gap-8 mt-[1.5rem]">
        <div className="flex flex-col gap-3 tablet:w-[40%] w-fit">
          <p className="text-primary font-semibold mb-[1.5rem]">
            Total number of Proposals :
            <span className="text-[#263238] font-medium ms-2">
              {data?.["all_proposal_count"]}
            </span>
          </p>
          {labels.map((item, index) => (
            <div key={index} className="w-full flex items-center gap-2">
              <div
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: item.color }}
              />
              <span className="tablet:text-sm text-base text-gray-700 whitespace-nowrap">
                {item.name}
              </span>
              <span className="tablet:text-sm text-base font-semibold text-gray-900 ms-auto">
                {item.value}
              </span>
            </div>
          ))}
        </div>
        <div className="h-[13rem] aspect-[4/3]">
          <ReactApexChart
            options={chartOptions}
            series={series}
            type="donut"
            width={"100%"}
            height={"100%"}
          />
        </div>
      </div>
    </div>
  );
}
