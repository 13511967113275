import { useEffect, useState } from "react";
import useFetch from "../../../../../hooks/useFetch";
import { instructorChoicesEndPoint, instructorJobRoundChoicesEndPoint } from "../../../../../Services/endPoints";
import axios from "axios";

export const useGetSelectionWebinarAddData = (selectedCoach) => {
    const { data: coachData } = useFetch(instructorChoicesEndPoint, []);
    const [jobProfileRound, setJobProfileRound] = useState([]);

    useEffect(() => {
        if (!selectedCoach || selectedCoach === "") {
            setJobProfileRound([]);
            return;
        }
        (async () => {
            try {
                const response = await axios.get(
                    `${instructorJobRoundChoicesEndPoint}${selectedCoach}/`
                );
                const codes = (response.data?.job_profile_rounds || []).map(
                    (item) => item.code
                );
                setJobProfileRound(codes);
            } catch (error) {
                console.error("Error fetching job profile rounds:", error);
            }
        })();
    }, [selectedCoach]);

    const selectionOptions = {
        coach:
            coachData?.map((coach) => ({
                name: coach.email,
                value: coach.id,
            })) ?? [],
        jobProfileRound: jobProfileRound.map((round) => ({
            name: round,
            value: round,
        })),
        group: [
            { name: "1", value: "1" },
            { name: "2", value: "2" },
        ],
    };

    return {
        selectionOptions,
    };
};