import React, { useState } from "react";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import arrow from "../../../../assets/arrow-down.svg";
import AddIcon from "../../../../Components/svgs/AddIcon";
import CalenderIcon from "../../../../Components/svgs/CalenderIcon";
import NetworkingIcon from "../../../../Components/svgs/NetworkingIcon";
import Person from "../../../../Components/svgs/Person";
import TimeIcon from "../../../../Components/svgs/TimeIcon";
import ZoomIcon from "../../../../Components/svgs/ZoomIcon";
import usePagination from "../../../../hooks/usePagination";
import updateWebinarStatus from "../../../../Services/apiupdateWebinarStatus";
import { InstructorWebinar } from "../../../../Services/endPoints";
import CardSkeleton from "../../Components/Common/Card/CardSkeleton";
import WebinarCard from "../../Components/WebinarCard/WebinarCard";
import AddWebinarModal from "./AddWebinarModal/AddWebinarModal";

import AddWebinarRecordModal from "./AddWebinarRecordModal/AddWebinarRecordModal";

function Webinars() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isRecordModalOpen, setIsRecordModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); // State for Add Webinar Modal
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false); // State for confirmation modal
  const [selectedWebinarId, setSelectedWebinarId] = useState(null); // Track selected webinar for confirmation
  const [refetch, setRefetch] = useState(false);
  const [selectedWebinar, setSelectedWebinar] = useState(null);
  const getIsFinishedQuery = () => {
    if (searchParams.get("filter") === "completed") {
      return "is_finished=true";
    } else if (searchParams.get("filter") === "notCompleted") {
      return "is_finished=false";
    } else {
      return "";
    }
  };

  const {
    data: webinars,
    isLoading: webinarsLoading,
    error: webinarsError,
  } = usePagination(`${InstructorWebinar}?${getIsFinishedQuery()}`, refetch);

  if (webinarsError)
    return (
      <p className="text-gray-5f text-center font-semibold w-full text-xl absolute-center">
        Something went wrong !
      </p>
    );

  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    if (selectedFilter === "all") {
      setSearchParams({});
    } else {
      setSearchParams({ filter: selectedFilter });
    }
  };

  const handleAddRecord = (webinar) => {
    setSelectedWebinar(webinar);
    setIsRecordModalOpen(true);
  };

  const handleRecordModalSubmit = (link) => {
    setIsRecordModalOpen(false);
    setSelectedWebinar(null);
    setRefetch((prev) => !prev);
  };

  const handleRecordModalClose = () => {
    setIsRecordModalOpen(false);
    setSelectedWebinar(null);
  };

  const handleAddWebinar = () => {
    setIsAddModalOpen(true);
  };

  const handleAddModalSubmit = () => {
    setIsAddModalOpen(false);
    setRefetch((prev) => !prev);
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
  };

  const handleConfirmModalOpen = (webinarId) => {
    setSelectedWebinarId(webinarId);
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
    setSelectedWebinarId(null);
  };

  const handleStatusChange = async () => {
    try {
      // Call API to update the status
      await updateWebinarStatus(selectedWebinarId, true);
      setRefetch((prev) => !prev);
      toast.success("Webinar status updated to completed!");
    } catch (error) {
      console.error("Error updating webinar status:", error);
      toast.error("Failed to update webinar status.");
    } finally {
      handleConfirmModalClose();
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between m-4">
        <button
          className="btn-primary w-fit rounded-xl flex items-center gap-2 py-8 px-6 text-[#F0F0F0]"
          onClick={handleAddWebinar}
        >
          <AddIcon className="w-7 h-7" />
          Add New Webinar
        </button>
        {webinars?.length === 0 && !webinarsLoading && (
          <p className="text-gray-5f text-center font-semibold w-full text-xl absolute-center">
            No webinars added yet.
          </p>
        )}

        <select
          name="webinarFilter"
          id="webinarFilter"
          className="w-[10rem] text-sm py-2 px-4 bg-white border border-gray-350 rounded-2xl appearance-none focus:border-gray-7e bg-no-repeat bg-[85%_center] bg-[length:0.6rem] cursor-pointer"
          value={searchParams.get("filter") || "all"}
          onChange={handleFilterChange}
          style={{
            backgroundImage: `url(${arrow})`,
          }}
        >
          <option value="all">All</option>
          <option value="completed">Completed</option>
          <option value="notCompleted">Not Completed</option>
        </select>
      </div>
      {webinars?.length > 0 && (
        <>
          <div className="grid 3xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
            {webinars?.map((webinar) => {
              const content = [
                {
                  title: <CalenderIcon className="mr-2" />,
                  content: webinar.date,
                },
                {
                  title: <Person className="mr-2" />,
                  content: webinar.instructor_name,
                },
                { title: <TimeIcon className="mr-2" />, content: webinar.time },
                { title: <ZoomIcon className="mr-2" />, content: "Zoom" },
                {
                  title: (
                    <div className="flex w-fit gap-2 mr-2">
                      <NetworkingIcon className=" size-[1.4rem]" />
                      <span className=" text-[#828282] text-base font-normal block">
                        Job Profile Round:
                      </span>
                    </div>
                  ),
                  content: webinar.job_profile_round,
                  isText: true,
                },
                {
                  title: (
                    <div className="flex w-fit gap-2 mr-2">
                      <MdOutlinePeopleAlt className=" size-[1.4rem] text-[#D3D2D2]" />
                      <span className=" text-[#828282] text-base font-normal block">
                        Group:
                      </span>
                    </div>
                  ),
                  content: webinar.group,
                  isText: true,
                },
              ];

              return (
                <WebinarCard
                  key={webinar.id}
                  content={content}
                  webinarName={webinar.name}
                  onClick={() => {
                    if (!webinar.is_finished) {
                      window.open(webinar.link, "_blank");
                    }
                  }}
                  onStatusChange={
                    webinar.is_finished
                      ? null // Disable toggle for completed webinars
                      : () => handleConfirmModalOpen(webinar.id)
                  }
                  isFinished={webinar.is_finished}
                  onAddRecord={() => handleAddRecord(webinar)}
                  webinarId={webinar.id}
                />
              );
            })}
            {webinarsLoading && (
              <>
                <CardSkeleton />
                <CardSkeleton />
              </>
            )}
          </div>
        </>
      )}
      {isConfirmModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-[90%] sm:w-[60%] lg:w-[40%]">
            <h2 className="text-xl font-bold mb-4">Confirm Status Change</h2>
            <p>Are you sure you want to mark this webinar as completed?</p>
            <div className="flex justify-end gap-4 mt-6">
              <button
                onClick={handleStatusChange}
                className="px-4 py-2 bg-blue-600 text-white rounded-md"
              >
                Confirm
              </button>
              <button
                onClick={handleConfirmModalClose}
                className="px-4 py-2 bg-gray-300 text-black rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <AddWebinarRecordModal
        isOpen={isRecordModalOpen}
        onClose={handleRecordModalClose}
        onSubmit={handleRecordModalSubmit}
        webinarId={selectedWebinar?.id}
      />

      <AddWebinarModal
        isOpen={isAddModalOpen}
        onClose={handleAddModalClose}
        onSubmit={handleAddModalSubmit}
      />
    </div>
  );
}

export default Webinars;
