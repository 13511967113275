import React from 'react'
import { useSearchParams } from 'react-router-dom'
import SearchInput from '../../../../NewPages/AdminDashboard/Common/SearchInput/SearchInput'
import Table from '../../../../NewPages/AdminDashboard/Common/Table/Table'
import TableSkeleton from '../../../../NewPages/AdminDashboard/Common/Table/TableSkeleton'
import Header from '../../../../NewPages/AdminDashboard/components/Header'
import { dashboardWebinars } from '../../../../Services/endPoints'
import usePaginationNumbers from '../../../../hooks/usePaginationNumbers'
import EditWebinarDialog from './EditWebinarDialog'
import WebinarsFilter from './WebinarsFilter'
import AddWebinarDialog from './AddWebinarDialog'

export default function CPWebinarsPage() {
    const [searchParams] = useSearchParams();

    const dynamicWebinarsEndpoint = `${dashboardWebinars}?${searchParams.toString()}`;

    // Fetch paginated data using the dynamic endpoint
    const {
        data: dashboardWebinarsData,
        page,
        totalPages,
        isLoading,
        error,
        rowsPerPage,
        handlePageChange,
        setCurrentPage,
        nextPage,
        prevPage,
        setRowsPerPage: handleRowsPerPageChange,
    } = usePaginationNumbers(dynamicWebinarsEndpoint);

    // Table columns
    const tableColumns = [
        { header: "Coach ID", accessor: "id" },
        { header: "Phone", accessor: "phone" },
        { header: "Webinar Title", accessor: "webinarTitle" },
        { header: "Webinar Date", accessor: "webinarDate" },
        { header: "Job Profile", accessor: "jobProfile" },
        { header: "Status", accessor: "status" },
        { header: "Edit", accessor: "edit" },
    ];

    // Format table data
    const tableData = dashboardWebinarsData?.map((webinar) => ({
        id: webinar.id,
        phone: webinar.instructor_phone,
        webinarTitle: webinar.name,
        webinarDate: webinar.date,
        jobProfile: webinar.job_profile_round,
        status: webinar.is_finished ? "Completed" : "Upcoming",
        edit: <EditWebinarDialog id={webinar.id} webinar={webinar} />
    })) || [];

    return (
        <div className='p-4'>
            <Header>
                <AddWebinarDialog />
            </Header>
            {/* Filters Component */}
            <div className='w-full flex justify-between flex-wrap mt-4'>
                <WebinarsFilter />
                <SearchInput
                    name={'coaching_id'}
                    placeholder='Search by Coach ID'
                />
            </div>
            <div className="mt-6">
                {isLoading ? (
                    <TableSkeleton columns={tableColumns.length} rows={5} />
                ) : error ? (
                    <p>Error fetching data</p>
                ) : (
                    <Table
                        columns={tableColumns}
                        data={tableData}
                        rowsPerPageOptions={[5, 10, 20]}
                        isLoading={isLoading}
                        currentPage={page}
                        totalPages={totalPages}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={handleRowsPerPageChange}
                        setCurrentPage={setCurrentPage}
                        handlePageChange={handlePageChange}
                        prevPage={prevPage}
                        nextPage={nextPage}
                    />
                )}
            </div>
        </div>
    )
}
