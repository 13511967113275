import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export default function CircularProgress({
  value,
  size = 100,
  strokeWidth = 8,
  color = "#273C97",
  className,
  showPercentage = true,
}) {
  const [progress, setProgress] = useState(0);

  // Animate the progress
  useEffect(() => {
    const timer = setTimeout(() => {
      setProgress(value);
    }, 100);
    return () => clearTimeout(timer);
  }, [value]);

  // Calculate circle properties
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div
      className={twMerge(
        "relative inline-flex items-center justify-center",
        className
      )}
    >
      <svg
        className="transform -rotate-90"
        style={{ width: size, height: size }}
      >
        {/* Background circle */}
        <circle
          className="text-gray-50"
          style={{
            strokeWidth,
          }}
          fill="currentcolor"
          cx={size / 2}
          cy={size / 2}
          r={radius}
        />
        {/* Progress circle */}
        <circle
          style={{
            strokeWidth,
            stroke: color,
            strokeDasharray: circumference,
            strokeDashoffset,
            transition: "stroke-dashoffset 0.5s ease",
          }}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
        />
      </svg>
      {showPercentage && (
        <span className="absolute text-sm font-medium" style={{ color }}>
          {Math.round(progress)}%
        </span>
      )}
    </div>
  );
}
