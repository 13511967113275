const successStyles = "bg-[#F0FEED] text-[#259800] text-sm";
const rejectedStyles = "bg-[#FEEDED] text-[#F13E3E] text-sm";

const ScoreColumn = ({ col }) => {
  const getStyles = (column) => {
    if (parseInt(column) >= 5) return successStyles;
    else return rejectedStyles;
  };
  return (
    <div
      className={`inline-flex items-center gap-1 px-3 py-2 rounded-full font-semibold ${
        col === -1 ? "text-[#F13E3E] font-bold text-lg" : getStyles(col)
      }`}
    >
      {col === -1 && <span>-</span>}
      {col !== -1 && (
        <>
          &#9679; <span>{col}</span>
        </>
      )}
    </div>
  );
};

export default ScoreColumn;
