import React from 'react';

export default function FreelancePlatformsDialog({ dialogRef, dialogData, onClose }) {
    return (
        <dialog
            ref={dialogRef}
            className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[30rem] min-h-[15rem] border-none bg-white rounded-xl outline-none p-5 overflow-visible shadow-lg"
        >
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-bold text-[#202020]">Freelance Platforms</h2>
                <button onClick={onClose} className="text-[#71747B] hover:text-gray-900">
                    ✖
                </button>
            </div>

            <div className="mt-4">
                {/* Render the list if dialogData has items */}
                {dialogData?.length > 0 && (
                    <ul className="space-y-3">
                        {dialogData.map((platform, index) => (
                            <li
                                key={index}
                                className="bg-[#F9FAFB] p-4 rounded-md shadow-sm hover:bg-[#F0F4FF]"
                            >
                                <a
                                    href={platform.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-[#273C97] font-medium underline text-lg"
                                >
                                    {platform.platform_name}
                                </a>
                            </li>
                        ))}
                    </ul>
                )}

                {/* Render fallback if there are no items */}
                {!(dialogData?.length > 0) && (
                    <p className="text-gray-500">No freelance platforms submitted yet.</p>
                )}
            </div>
        </dialog>
    );
}
