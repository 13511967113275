import { useEffect } from "react";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CustomButton from "../../../../../shared/CustomButton";
import LabeledInput from "../../../../../shared/LabeledInput";
import SelectInput from "../../../../../shared/SelectInput";
import { useEditStudentForm } from "./useEditStudentForm";
import { useGetSelectionData } from "./useGetSelectionData";
import CopyId from "../../../../shared/CopyId";
import ChangePasswordDialog from "../../../../shared/ChangePassword/ChangePasswordDialog";
import ProfileImageViewer from "../../../../shared/ProfileImageViewer";

export const EditStudentForm = ({ currentStudentData, isLoading }) => {
  const { selectionOptions } = useGetSelectionData();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    isPending,
    onSubmit,
  } = useEditStudentForm();
  useEffect(() => {
    const defaultInputValues = {
      fullName: currentStudentData?.full_name ?? "",
      email: currentStudentData?.user.email ?? "",
      phoneNumber: currentStudentData?.phone ?? "",
      track: currentStudentData?.track ?? "",
      coachingRounds: currentStudentData?.coaching_rounds,
      jobProfile: currentStudentData?.job_profile_round ?? "",
      group: currentStudentData?.group ?? "",
    };
    Object.entries(defaultInputValues).forEach(([key, value]) => {
      setValue(key, value);
    });
  }, [currentStudentData]);
  return (
    <div className="mt-[2rem] tablet:w-[73%] w-full flex-1 max-w-[90rem] rounded-lg shadow bg-white px-[1.5rem] py-[1.5rem]">
      <div className="flex md:flex-nowrap flex-wrap items-start gap-[2rem]">
        <ProfileImageViewer
          role={"Student"}
          profileImage={currentStudentData?.profile_image}
          isLoading={isLoading}
        />
        <div className="flex md:flex-row items-start gap-[2rem] flex-col-reverse flex-1">
          <form
            id="edit-student-form"
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-[30rem] flex flex-col gap-[1.25rem]"
          >
            <LabeledInput
              id={"fullName"}
              label={"Name"}
              register={register}
              placeholder={"Full Name"}
              fieldName="fullName"
              errors={errors}
            />
            <LabeledInput
              id={"email"}
              label={"Email"}
              register={register}
              placeholder={"Email"}
              fieldName="email"
              errors={errors}
            />

            <div>
              <label
                htmlFor="phoneNumber"
                className="text-primary text-base font-medium mb-2 block"
              >
                Phone
              </label>
              <div className="input input-bordered rounded border-[#DFEAF2] w-full flex items-center">
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <PhoneInput
                      className="w-full"
                      countrySelectProps={{ unicodeFlags: true }}
                      placeholder="Phone Number"
                      id="phoneNumber"
                      defaultCountry="EG"
                      onChange={onChange}
                      value={value}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
              {errors.phoneNumber && (
                <p className="text-[red] ms-2 text-sm mt-2 w-full">
                  {errors.phoneNumber.message}
                </p>
              )}
            </div>

            <SelectInput
              items={selectionOptions["tracks"]}
              name={"track"}
              label={"Track"}
              register={register}
              placeholderText={"Choose Track"}
              errors={errors}
            >
              <span className="bg-[#F8F8FD] w-fit shadow-sm px-3 py-1 text-primary text-sm mt-3 block font-medium rounded-sm ms-1">
                {currentStudentData?.track}
              </span>
            </SelectInput>

            <SelectInput
              items={selectionOptions["coachingRounds"]}
              name="coachingRounds"
              label="Coaching Rounds"
              register={register}
              placeholderText={"Choose Coaching Round"}
              errors={errors}
            >
              <span className="bg-[#F8F8FD] w-fit shadow-sm px-3 py-1 text-primary text-sm mt-3 block font-medium rounded-sm ms-1">
                {currentStudentData?.coaching_rounds}
              </span>
              <p className="text-[#DC2626] text-sm mt-2 w-fit ms-2 font-normal ">
                Note: Each student can be assigned to only one coaching round
              </p>
            </SelectInput>
            <SelectInput
              items={selectionOptions["jobProfiles"]}
              name={"jobProfile"}
              label={"Job Profile"}
              register={register}
              placeholderText={"Choose Job Profile"}
              errors={errors}
            >
              <span className="bg-[#F8F8FD] w-fit shadow-sm px-3 py-1 text-primary text-sm mt-3 block font-medium rounded-sm ms-1">
                {currentStudentData?.job_profile_round}
              </span>
              <p className="text-[#DC2626] text-sm mt-2 w-fit ms-2 font-normal ">
                Note: Each student can be assigned to only one job profile
              </p>
            </SelectInput>
            <SelectInput
              items={["1", "2"]}
              name={"group"}
              label={"Group"}
              register={register}
              placeholderText={"Choose Group"}
              errors={errors}
            >
              <span className="bg-[#F8F8FD] w-fit shadow-sm px-3 py-1 text-primary text-sm mt-3 block font-medium rounded-sm ms-1">
                {currentStudentData?.group}
              </span>
            </SelectInput>

            {errors.root && (
              <p className="text-[#DC2626] text-sm mt-2 w-fit mx-auto">
                {errors.root.message}
              </p>
            )}
          </form>
          <div className="flex flex-wrap ms-auto gap-x-2 gap-y-4 justify-end items-center">
            <CopyId id={currentStudentData?.id} />
            <ChangePasswordDialog
              role={"Student"}
              email={currentStudentData?.user?.email}
            />
          </div>
        </div>
      </div>
      <CustomButton
        form="edit-student-form"
        type="submit"
        className="font-bold px-4 text-sm block ms-auto py-4 mt-5"
        isLoading={isPending}
      >
        Save Changes
      </CustomButton>
    </div>
  );
};
