import React from "react";
import { apiGetDashboardStats } from "../../../Services/AdminDashboard/apiGetDashboardStats";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../shared/Loading";
import StudentProgress from "./StudentProgress";
import AdminUserInfo from "../../../NewPages/AdminDashboard/components/AdminUserInfo";
import WebinarChart from "./WebinarsChart";
import QAStats from "./QAStats";
import AIInterviewStats from "./AIInterviewStats";
import ReviewsStats from "./ReviewsStats";
import GigsStats from "./GigsPage/GigsStats";
import ProposalsStats from "./ProposalsStats";

const AdminDashboardPage = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["dashboardStats"],
    queryFn: apiGetDashboardStats,
  });
  if (isLoading) {
    <Loading />;
  }
  return (
    <>
      <div className="w-full flex justify-between tablet:mt-0 mt-[2rem] gap-[1rem]">
        <StudentProgress data={data} />
        <AdminUserInfo className="tablet:flex hidden mr-[5%]" />
      </div>
      <div className="w-full flex flex-wrap items-start gap-[2rem] mt-[2rem]">
        <WebinarChart webinars={data?.webinars} />
        <QAStats data={data} />
        <AIInterviewStats data={data} />
        <ReviewsStats data={data} />
        <ProposalsStats data={data} />
        <GigsStats data={data} />
      </div>
    </>
  );
};

export default AdminDashboardPage;
