import React, { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "../../../../hooks/useClickOutside";
import ArrowDown from "../../../../Components/svgs/ArrowDown";
import { useSearchParams } from "react-router-dom";
import ErrorMessage from "../../../../Components/shared/ErrorMessage";
const tracks = [
  "Web development",
  "Digital Arts",
  "Data Analysis",
  "Digital marketing",
  "Generative AI",
  "Cybersecurity",
  "Software testing",
];
function AIReviewerFilter({ count }) {
  const [searchParams, setSearchParams] = useSearchParams();

  // Static filter options
  const statuses = ["Pending", "Accepted", "Rejected"];
  const statusDropdownRef = useRef();
  const videoDropdownRef = useRef();
  const textDropdownRef = useRef();
  const questionsDropdownRef = useRef();
  const trackDropdownRef = useRef();

  const [isStatusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [isVideoScoreDropdownOpen, setVideoScoreDropdownOpen] = useState(false);
  const [isTextScoreDropdownOpen, setTextScoreDropdownOpen] = useState(false);
  const [isQuestionsScoreDropdownOpen, setQuestionsScoreDropdownOpen] =
    useState(false);
  const [videoScore, setVideoScore] = useState(5);
  const [videoScoreError, setVideoScoreError] = useState(null);
  const [textScore, setTextScore] = useState(5);
  const [textScoreError, setTextScoreError] = useState(null);
  const [questionsScore, setQuestionsScore] = useState(5);
  const [questionsScoreError, setQuestionsScoreError] = useState(null);
  const [isTrackDropdown, setIsTrackDropdown] = useState(false);
  const [videoScoreFilterSign, setVideoScoreFilterSign] = useState("=");
  const [textScoreFilterSign, setTextScoreFilterSign] = useState("=");
  const [questionsScoreFilterSign, setQuestionsScoreFilterSign] = useState("=");

  // Handle outside click
  useOutsideClick(trackDropdownRef, () => setIsTrackDropdown(false));
  useOutsideClick(statusDropdownRef, () => setStatusDropdownOpen(false));
  useOutsideClick(videoDropdownRef, () => setVideoScoreDropdownOpen(false));
  useOutsideClick(textDropdownRef, () => setTextScoreDropdownOpen(false));
  useOutsideClick(questionsDropdownRef, () =>
    setQuestionsScoreDropdownOpen(false)
  );

  // Handle filter selections
  const handleSelect = (key, value) => {
    const updatedParams = new URLSearchParams(searchParams);
    if (value) {
      updatedParams.set(key, value);
    } else {
      updatedParams.delete(key);
    }
    setSearchParams(updatedParams);
    localStorage.setItem("filters", updatedParams.toString());
  };

  const applyVideoFilter = () => {
    const updatedParams = new URLSearchParams(searchParams);
    if (videoScoreFilterSign === "=") {
      updatedParams.set("video_score", videoScore);
      updatedParams.delete("video_score_gt");
      setSearchParams(updatedParams);
    } else {
      updatedParams.set("video_score_gt", videoScore);
      updatedParams.delete("video_score");
    }
    setVideoScoreDropdownOpen(false);
    setSearchParams(updatedParams);
  };

  const deleteVideoFilter = () => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete("video_score");
    updatedParams.delete("video_score_gt");
    setSearchParams(updatedParams);
  };
  const deleteTextFilter = () => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete("text_score");
    updatedParams.delete("text_score_gt");
    setSearchParams(updatedParams);
  };
  const deleteQuestionsFilter = () => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete("questions_score");
    updatedParams.delete("questions_score_gt");
    setSearchParams(updatedParams);
  };
  const applyQuestionsFilter = () => {
    const updatedParams = new URLSearchParams(searchParams);
    if (questionsScoreFilterSign === "=") {
      updatedParams.set("questions_score", questionsScore);
      updatedParams.delete("questions_score_gt");
      setSearchParams(updatedParams);
    } else {
      updatedParams.set("questions_score_gt", questionsScore);
      updatedParams.delete("questions_score");
    }
    setQuestionsScoreDropdownOpen(false);
    setSearchParams(updatedParams);
  };
  const handleQuestionsScoreChange = (e) => {
    const score = parseInt(e.target.value);
    setQuestionsScore(score);
    if (score < 0) {
      setQuestionsScoreError("Score cannot be negative");
      return;
    }
    if (score > 10) {
      setQuestionsScoreError("Score cannot be greater than 10");
      return;
    }
    setQuestionsScoreError(null);
  };
  const handleVideoScoreChange = (e) => {
    const score = parseInt(e.target.value);
    setVideoScore(score);
    if (score < 0) {
      setVideoScoreError("Score cannot be negative");
      return;
    }
    if (score > 10) {
      setVideoScoreError("Score cannot be greater than 10");
      return;
    }
    setVideoScoreError(null);
  };
  const handleTextScoreChange = (e) => {
    const score = parseInt(e.target.value);
    setTextScore(score);
    if (score < 0) {
      setTextScoreError("Score cannot be negative");
      return;
    }
    if (score > 10) {
      setTextScoreError("Score cannot be greater than 10");
      return;
    }
    setVideoScoreError(null);
  };
  const applyTextFilter = () => {
    const updatedParams = new URLSearchParams(searchParams);
    if (textScoreFilterSign === "=") {
      updatedParams.set("text_score", textScore);
      updatedParams.delete("text_score_gt");
      setSearchParams(updatedParams);
    } else {
      updatedParams.set("text_score_gt", textScore);
      updatedParams.delete("text_score");
    }
    setTextScoreDropdownOpen(false);
    setSearchParams(updatedParams);
  };
  const handleClearFilters = () => {
    setSearchParams(new URLSearchParams());
  };
  const openTrackDropdown = () => {
    setIsTrackDropdown(true);
    setTextScoreDropdownOpen(false);
    setStatusDropdownOpen(false);
    setVideoScoreDropdownOpen(false);
    setQuestionsScoreDropdownOpen(false);
  };
  const openStatusDropdown = () => {
    setStatusDropdownOpen(true);
    setTextScoreDropdownOpen(false);
    setIsTrackDropdown(false);
    setVideoScoreDropdownOpen(false);
    setQuestionsScoreDropdownOpen(false);
  };
  const openVideoScoreDropdown = () => {
    setVideoScoreDropdownOpen(true);
    setTextScoreDropdownOpen(false);
    setIsTrackDropdown(false);
    setStatusDropdownOpen(false);
    setQuestionsScoreDropdownOpen(false);
  };
  const openTextScoreDropdown = () => {
    setTextScoreDropdownOpen(true);
    setVideoScoreDropdownOpen(false);
    setIsTrackDropdown(false);
    setStatusDropdownOpen(false);
    setQuestionsScoreDropdownOpen(false);
  };
  const openQuestionsScoreDropdown = () => {
    setQuestionsScoreDropdownOpen(true);
    setTextScoreDropdownOpen(false);
    setIsTrackDropdown(false);
    setStatusDropdownOpen(false);
    setVideoScoreDropdownOpen(false);
  };
  // Extract selected filters
  const selectedStatus = searchParams.get("status");
  const selectedVideoScore = searchParams.get("video_score");
  const selectedVideoScoreGt = searchParams.get("video_score_gt");
  const selectedTextScore = searchParams.get("text_score");
  const selectedTextScoreGt = searchParams.get("text_score_gt");
  const selectedQuestionsScore = searchParams.get("questions_score");
  const selectedQuestionsScoreGt = searchParams.get("questions_score_gt");
  const selectedTrack = searchParams.get("track");

  useEffect(() => {
    if (localStorage.getItem("filters")) {
      setSearchParams(new URLSearchParams(localStorage.getItem("filters")));
    }
  }, []);
  return (
    <div className="w-fit">
      <div className="w-fit flex items-center gap-4 flex-wrap pb-[1.5rem] border-b border-[#CCD1D2]">
        <div className="relative dropdown" ref={trackDropdownRef}>
          <button
            onClick={openTrackDropdown}
            className="px-6 py-4 bg-white border border-gray-300 rounded-full text-sm font-medium flex items-center gap-4"
          >
            <span className="block">Technical Track</span>
            <ArrowDown className="size-[1rem] text-[#868686]" />
          </button>
          {isTrackDropdown && (
            <div className="absolute top-12 left-0 w-48 bg-white border border-gray-200 rounded-lg shadow-md z-10">
              {tracks.map((track) => (
                <div
                  key={track}
                  onClick={() => {
                    handleSelect("track", track);
                    setIsTrackDropdown(false);
                  }}
                  className="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                >
                  {track}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="relative dropdown" ref={videoDropdownRef}>
          <button
            onClick={openVideoScoreDropdown}
            className="px-6 py-4 bg-white border border-gray-300 rounded-full text-sm font-medium flex items-center gap-4"
          >
            <span className="block">English Score (Video)</span>
            <ArrowDown className="size-[1rem] text-[#868686]" />
          </button>
          {isVideoScoreDropdownOpen && (
            <div className="absolute top-12 left-0 w-48 bg-white border border-gray-200 rounded-lg shadow-md z-10 py-4 px-2">
              <div className="bg-[#F2F4F7] mx-auto rounded-full w-[10rem]">
                <button
                  className={`text-[#98A0B4] w-1/2 py-1 text-lg font-medium  ${
                    videoScoreFilterSign === "="
                      ? "bg-primary text-white rounded-full"
                      : ""
                  } `}
                  onClick={() => setVideoScoreFilterSign("=")}
                >
                  =
                </button>
                <button
                  className={`text-[#98A0B4] w-1/2 py-1 text-lg font-medium  ${
                    videoScoreFilterSign === ">"
                      ? "bg-primary text-white rounded-full"
                      : ""
                  } `}
                  onClick={() => setVideoScoreFilterSign(">")}
                >
                  {">"}
                </button>
              </div>
              <label
                htmlFor="videoScore"
                className="text-sm text-[#9E9E9E] mx-auto block w-fit mt-3"
              >
                Score
              </label>
              <input
                id="videoScore"
                type="number"
                min={0}
                max={10}
                className="outline-none border border-[#CCD1D2] rounded-md w-full text-center mt-2 block h-[2.5rem] px-0"
                value={videoScore}
                onChange={handleVideoScoreChange}
              />
              {videoScoreError && <ErrorMessage message={videoScoreError} />}
              <button
                className="mx-auto bg-primary text-white mt-3 w-[70%] py-2 rounded-xl text-sm block  hover:bg-primary-light"
                onClick={applyVideoFilter}
              >
                Apply
              </button>
            </div>
          )}
        </div>
        <div className="relative dropdown" ref={textDropdownRef}>
          <button
            onClick={openTextScoreDropdown}
            className="px-6 py-4 bg-white border border-gray-300 rounded-full text-sm font-medium flex items-center gap-4"
          >
            <span className="block">English Score (Text)</span>
            <ArrowDown className="size-[1rem] text-[#868686]" />
          </button>
          {isTextScoreDropdownOpen && (
            <div className="absolute top-12 left-0 w-48 bg-white border border-gray-200 rounded-lg shadow-md z-10 py-4 px-2">
              <div className="bg-[#F2F4F7] mx-auto rounded-full w-[10rem]">
                <button
                  className={`text-[#98A0B4] w-1/2 py-1 text-lg font-medium  ${
                    textScoreFilterSign === "="
                      ? "bg-primary text-white rounded-full"
                      : ""
                  } `}
                  onClick={() => setTextScoreFilterSign("=")}
                >
                  =
                </button>
                <button
                  className={`text-[#98A0B4] w-1/2 py-1 text-lg font-medium  ${
                    textScoreFilterSign === ">"
                      ? "bg-primary text-white rounded-full"
                      : ""
                  } `}
                  onClick={() => setTextScoreFilterSign(">")}
                >
                  {">"}
                </button>
              </div>
              <label
                htmlFor="textScore"
                className="text-sm text-[#9E9E9E] mx-auto block w-fit mt-3"
              >
                Score
              </label>
              <input
                id="textScore"
                type="number"
                min={0}
                max={10}
                className="outline-none border border-[#CCD1D2] rounded-md w-full text-center mt-2 block h-[2.5rem] px-0"
                value={textScore}
                onChange={handleTextScoreChange}
              />
              {textScoreError && <ErrorMessage message={textScoreError} />}
              <button
                className="mx-auto bg-primary text-white mt-3 w-[70%] py-2 rounded-xl text-sm block  hover:bg-primary-light"
                onClick={applyTextFilter}
              >
                Apply
              </button>
            </div>
          )}
        </div>
        <div className="relative dropdown" ref={questionsDropdownRef}>
          <button
            onClick={openQuestionsScoreDropdown}
            className="px-6 py-4 bg-white border border-gray-300 rounded-full text-sm font-medium flex items-center gap-4"
          >
            <span className="block">Technical Score</span>
            <ArrowDown className="size-[1rem] text-[#868686]" />
          </button>
          {isQuestionsScoreDropdownOpen && (
            <div className="absolute top-12 left-0 w-48 bg-white border border-gray-200 rounded-lg shadow-md z-10 py-4 px-2">
              <div className="bg-[#F2F4F7] mx-auto rounded-full w-[10rem]">
                <button
                  className={`text-[#98A0B4] w-1/2 py-1 text-lg font-medium  ${
                    questionsScoreFilterSign === "="
                      ? "bg-primary text-white rounded-full"
                      : ""
                  } `}
                  onClick={() => setQuestionsScoreFilterSign("=")}
                >
                  =
                </button>
                <button
                  className={`text-[#98A0B4] w-1/2 py-1 text-lg font-medium  ${
                    questionsScoreFilterSign === ">"
                      ? "bg-primary text-white rounded-full"
                      : ""
                  } `}
                  onClick={() => setQuestionsScoreFilterSign(">")}
                >
                  {">"}
                </button>
              </div>
              <label
                htmlFor="textScore"
                className="text-sm text-[#9E9E9E] mx-auto block w-fit mt-3"
              >
                Score
              </label>
              <input
                id="textScore"
                type="number"
                min={0}
                max={10}
                className="outline-none border border-[#CCD1D2] rounded-md w-full text-center mt-2 block h-[2.5rem] px-0"
                value={questionsScore}
                onChange={handleQuestionsScoreChange}
              />
              {questionsScoreError && <ErrorMessage message={textScoreError} />}
              <button
                className="mx-auto bg-primary text-white mt-3 w-[70%] py-2 rounded-xl text-sm block  hover:bg-primary-light"
                onClick={applyQuestionsFilter}
              >
                Apply
              </button>
            </div>
          )}
        </div>

        <div className="relative dropdown" ref={statusDropdownRef}>
          <button
            onClick={openStatusDropdown}
            className="px-6 py-4 bg-white border border-gray-300 rounded-full text-sm font-medium flex items-center gap-4"
          >
            <span className="block">Status</span>
            <ArrowDown className="size-[1rem] text-[#868686]" />
          </button>
          {isStatusDropdownOpen && (
            <div className="absolute top-12 left-0 w-48 bg-white border border-gray-200 rounded-lg shadow-md z-10">
              {statuses.map((status) => (
                <div
                  key={status}
                  onClick={() => {
                    handleSelect("status", status);
                    setStatusDropdownOpen(false);
                  }}
                  className="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                >
                  {status}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="mt-4 flex items-center flex-wrap gap-3 ms-4">
        {selectedTrack && (
          <div className="flex items-center px-3 py-2 text-sm border border-gray-5f rounded-full">
            {selectedTrack}
            <button
              onClick={() => handleSelect("track", "")}
              className="ml-2 text-gray-500 hover:text-red-500"
            >
              ✖
            </button>
          </div>
        )}
        {(selectedVideoScore || selectedVideoScoreGt) && (
          <div className="flex items-center px-3 py-2 text-sm border border-gray-5f rounded-full">
            English Score (Video): {selectedVideoScore || selectedVideoScoreGt}
            <button
              onClick={deleteVideoFilter}
              className="ml-2 text-gray-500 hover:text-red-500"
            >
              ✖
            </button>
          </div>
        )}
        {(selectedTextScore || selectedTextScoreGt) && (
          <div className="flex items-center px-3 py-2 text-sm border border-gray-5f rounded-full">
            English Score (Text): {selectedTextScore || selectedTextScoreGt}
            <button
              onClick={deleteTextFilter}
              className="ml-2 text-gray-500 hover:text-red-500"
            >
              ✖
            </button>
          </div>
        )}
        {(selectedQuestionsScore || selectedQuestionsScoreGt) && (
          <div className="flex items-center px-3 py-2 text-sm border border-gray-5f rounded-full">
            Technical Score:
            {selectedQuestionsScore || selectedQuestionsScoreGt}
            <button
              onClick={deleteQuestionsFilter}
              className="ml-2 text-gray-500 hover:text-red-500"
            >
              ✖
            </button>
          </div>
        )}
        {selectedStatus && (
          <div className="flex items-center px-3 py-2 text-sm border border-gray-5f rounded-full">
            {selectedStatus}
            <button
              onClick={() => handleSelect("status", "")}
              className="ml-2 text-gray-500 hover:text-red-500"
            >
              ✖
            </button>
          </div>
        )}
        {searchParams.size !== 0 && (
          <>
            <h2 className="bg-primary text-base rounded-full text-center text-white px-4 py-2 ">
              Count : {count}
            </h2>
            <button
              onClick={handleClearFilters}
              className="px-4 py-2 text-sm font-medium bg-[#EFEFEF] border border-gray-300 rounded-full hover:bg-gray-200"
            >
              Clear All
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default AIReviewerFilter;
