import { useState } from "react";
import { FiUpload } from "react-icons/fi";
import CustomButton from "../../shared/CustomButton";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

const DownloadCsvButton = ({ text, endpoint, csvFileName }) => {
  const [searchParams] = useSearchParams();
  const [isDownloading, setIsDownloading] = useState(false);

  const triggerDownload = async () => {
    setIsDownloading(true);
    const updatedParams = new URLSearchParams(searchParams);

    updatedParams.set("csv", "True");

    const response = await axios.get(
      `${endpoint}?${updatedParams.toString()}`,
      {
        responseType: "blob",
      }
    );
    downloadApplications(response);
    setIsDownloading(false);
  };
  const downloadApplications = (response) => {
    const blob = new Blob([response.data], {
      type: "application/csv",
    });

    const contentDisposition = response.headers["content-disposition"];
    const fileName =
      contentDisposition?.split("filename=")[1]?.replace(/['"]/g, "") ||
      `${csvFileName}.csv`;

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
  };
  return (
    <CustomButton
      className="btn-primary ms-auto w-fit py-3 mb-3 flex items-center justify-center gap-2 whitespace-nowrap"
      onClick={triggerDownload}
      isLoading={isDownloading}
    >
      <FiUpload className="text-lg size-[1.3rem]" />
      {text}
    </CustomButton>
  );
};

export default DownloadCsvButton;
