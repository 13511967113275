import React from 'react';

export default function StudentQADialog({ dialogRef, onClose, dialogData }) {
    return (
        <dialog
            ref={dialogRef}
            className='fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[30rem] min-h-[15rem] border-none bg-white rounded-xl outline-none p-5 overflow-visible shadow-lg'
        >
            <button
                onClick={onClose}
                className='text-[#71747B] hover:text-gray-900 flex ml-auto'
            >
                ✖
            </button>
            <div className='mt-2'>
                <h2 className='text-lg font-semibold text-[#202020]'>
                    Asked by: <span className='font-normal'>{dialogData?.student}</span>
                </h2>
                <span className='text-[#828282] font-normal text-lg '>{dialogData?.question_text}</span>
                <h2 className='text-lg font-semibold text-[#202020] '>Coach Answer:</h2>
                <span className='text-[#828282] font-normal text-lg'>{dialogData?.answer?.answer}</span>
            </div>
        </dialog>
    );
}
