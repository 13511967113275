import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import studentPlaceholder from "../../../assets/defultUserImage.svg";
import instructorPlaceholder from "../../../assets/instructorProfileImage.png";
import ChangePassword from "../../../Components/ChangePssword/ChangePassword";
import { roles } from "../../../constants";
import useFetch from "../../../hooks/useFetch";
import putChangeProfileImage from "../../../Services/apiPutChangeProfileImage";
import { UserProfileURL } from "../../../Services/endPoints";
import RequestSupport from "./RequestSupport/RequestSupport";
import styles from "./Settings.module.css";

function Settings() {
  const [activeTab, setActiveTab] = useState("profilePhoto");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [prevImageUrl, setPrevImageUrl] = useState(null);
  const [cookies] = useCookies(["role"]);
  const placeholderImage =
    cookies.role === roles.studentRole
      ? studentPlaceholder
      : instructorPlaceholder;
  const {
    data: UserProfileData,
    loading: UserProfileLoading,
    // error: UserProfileError,
  } = useFetch(UserProfileURL);

  // Change profile image
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const validTypes = ["image/jpeg", "image/png"];

    // Validate file type
    if (file && validTypes.includes(file.type)) {
      setSelectedImage(file);
      setErrorMessage("");
      setUploadSuccess(false);

      // Create a preview URL for the selected image
      const imagePreviewUrl = URL.createObjectURL(file);

      // Revoke previous object URL to prevent memory leaks
      if (prevImageUrl) {
        URL.revokeObjectURL(prevImageUrl);
      }
      setSelectedImagePreview(imagePreviewUrl);
      setPrevImageUrl(imagePreviewUrl);
    } else {
      setErrorMessage("Only JPG and PNG files are allowed");
      setSelectedImage(null);
      if (prevImageUrl) {
        URL.revokeObjectURL(prevImageUrl);
      }
      setSelectedImagePreview(null);
      setPrevImageUrl(null);
    }
  };

  const handleUpload = async () => {
    if (!selectedImage) {
      setErrorMessage("Please select a valid image");
      return;
    }

    try {
      setUploadingImage(true);
      await putChangeProfileImage(selectedImage);
      setUploadingImage(false);
      setUploadSuccess(true);
      toast.success("Profile Image changed successfully!");
      setErrorMessage("");
      // Clean up the object URL
      if (prevImageUrl) {
        URL.revokeObjectURL(prevImageUrl);
      }
      // Reload the page to update the profile image
      window.location.reload();
    } catch (error) {
      toast.error("Failed to upload image. Please try again.");
      setErrorMessage("Failed to upload image. Please try again.");
      setUploadSuccess(false);
    }
  };

  // Trigger file input click for choosing another image
  const handleChooseAnotherImage = () => {
    document.getElementById("fileInput").click();
  };

  // Clean up object URL when component unmounts
  useEffect(() => {
    return () => {
      if (prevImageUrl) {
        URL.revokeObjectURL(prevImageUrl);
      }
    };
  }, [prevImageUrl]);

  const renderTabContent = () => {
    switch (activeTab) {
      case "profilePhoto":
        return (
          <div className={styles.tabContent}>
            <h2 className="text-[#202020] font-semibold text-left text-2xl md:text-lg mb-5">
              Change profile photo
            </h2>
            <div className="flex px-4 gap-12 md:gap-20 items-center mt-10 flex-col md:flex-row">
              <div className="w-40 h-40 rounded-full flex items-center justify-center border border-gray-250 overflow-hidden">
                {UserProfileLoading && (
                  <span className="border-gray-300 h-10 w-10 animate-spin rounded-full border-4 border-t-blue-600 block"></span>
                )}
                {!UserProfileLoading && (
                  <img
                    src={UserProfileData?.profile_image || placeholderImage}
                    alt="Profile-Image"
                    className="object-cover w-full h-full"
                  />
                )}
              </div>

              {/* Upload Box */}
              <div className={styles.uploadBox}>
                {/* Hidden file input */}
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={handleImageChange}
                  className="hidden"
                  id="fileInput"
                />

                {/* Upload Placeholder */}
                <div>
                  {selectedImagePreview ? (
                    <div className="flex gap-4 items-center">
                      {/* Image Preview */}
                      <img
                        src={selectedImagePreview}
                        alt="Selected Profile"
                        className="w-24 h-24 object-cover mb-2"
                      />
                      <div className="flex flex-col gap-2">
                        {/* Change Profile Image */}
                        <button
                          className="btn-primary px-3 py-3 flex items-center gap-2"
                          disabled={uploadingImage}
                          onClick={handleUpload}
                        >
                          Change profile image
                          {uploadingImage && (
                            <span className=" border-gray-300 h-5 w-5 animate-spin rounded-full border-2 border-t-blue-600 block"></span>
                          )}
                        </button>
                        {/* Choose Another Image */}
                        <span
                          className="btn-primary px-3 py-3"
                          onClick={handleChooseAnotherImage}
                        >
                          Choose another image
                        </span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {/* Default Upload Prompt */}
                      <label
                        htmlFor="fileInput"
                        className="cursor-pointer text-xl"
                      >
                        <span>Click to replace or drag and drop</span>
                        <p>PNG, JPG (max. 400 x 400px)</p>
                      </label>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Error Message */}
            {errorMessage && (
              <p className="text-red-500 text-sm mt-2 text-center">
                {errorMessage}
              </p>
            )}
          </div>
        );
      case "resetPassword":
        return <ChangePassword />;
      case "requestSupport":
        return <RequestSupport />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.settingsContainer}>
      <div className={styles.tabs}>
        <button
          className={`${styles.tabButton} ${
            activeTab === "profilePhoto" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("profilePhoto")}
        >
          Change profile photo
        </button>
        <button
          className={`${styles.tabButton} ${
            activeTab === "resetPassword" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("resetPassword")}
        >
          Reset Password
        </button>
        <button
          className={`${styles.tabButton} ${
            activeTab === "requestSupport" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("requestSupport")}
        >
          Request Support
        </button>
      </div>

      {renderTabContent()}
    </div>
  );
}

export default Settings;
