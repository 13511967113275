import { Dialog, DialogActions, DialogContent } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ErrorMessage from "../../../../Components/shared/ErrorMessage";
import Loading from "../../../../Components/shared/Loading";
import CalenderIcon from "../../../../Components/svgs/CalenderIcon";
import EditIcon from "../../../../Components/svgs/EditIcon";
import NetworkingIcon from "../../../../Components/svgs/NetworkingIcon";
import Person from "../../../../Components/svgs/Person";
import QuestionIcon from "../../../../Components/svgs/QuestionIcon";
import {
  DeliverablesFeedBackEndPoint,
  DeliverablesInstructorEndPoint,
  DeliverablesUpdateFeedBackEndPoint,
} from "../../../../Services/endPoints";
import useFetch from "../../../../hooks/useFetch";
import usePagination from "../../../../hooks/usePagination";
import Card from "../../Components/Common/Card/Card";
import CardSkeleton from "../Common/Card/CardSkeleton";

const reviewSchema = Yup.object({
  review: Yup.string()
    .required("Review is required"),

  degree: Yup.number()
    .min(0, "Degree must be at least 0")
    .max(10, "Degree cannot exceed 10")
    .required("Degree is required"),

})

const DeliverablesComponent = ({ isPaginationDisabled = false, pageTitle }) => {
  const [isReviewDialogOpen, setReviewDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const [isStatusChanged, setIsStatusChanged] = useState(false);

  const {
    data: deliverablesDataPaginated,
    isLoading: paginationLoading,
    error: paginationError,
  } = usePagination(
    `${DeliverablesInstructorEndPoint}?${searchParams.toString()}`,
    isStatusChanged,
    isPaginationDisabled
  );

  const {
    data: deliverablesData,
    loading: deliverablesLoading,
    error: fetchError,
  } = useFetch(
    `${DeliverablesInstructorEndPoint}?page=1&page_size=5`,
    [isStatusChanged],
    !isPaginationDisabled
  );

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(reviewSchema),
    defaultValues: {
      review: "",
      degree: "",
    }
  })

  if (deliverablesLoading && isPaginationDisabled) {
    return <Loading />;
  }
  if (paginationError || fetchError) {
    return (
      <p className="text-gray-5f text-center font-semibold w-full text-xl my-3">
        Something went wrong !
      </p>
    );
  }
  const dataToRender = isPaginationDisabled
    ? deliverablesData?.results
    : deliverablesDataPaginated;

  if (
    dataToRender?.length === 0 &&
    (!deliverablesLoading || !paginationLoading)
  ) {
    return (
      <p className="text-gray-5f text-center font-semibold w-full text-lg mt-[1rem]">
        No Deliverables submitted yet
      </p>
    );
  }

  const handleReviewClick = (review) => {
    setSelectedReview(review);
    reset({
      review: "",
      degree: "",
    });
    setError("");
    setReviewDialogOpen(true);
  };

  const handleEditClick = async (review) => {
    setReviewDialogOpen(true);
    setSelectedReview(review);
    setError("");

    if (review.has_feedback && review.feedback_id) {
      try {
        const response = await axios.get(
          `${DeliverablesUpdateFeedBackEndPoint}${review.feedback_id}/`
        );
        if (response.status === 200) {
          const feedback = response.data;
          reset({
            review: feedback.description || "",
            degree: feedback.degree?.toString() || "",
          });
          setError("");
        } else {
          setError("No feedback available.");
        }
      } catch (error) {
        setError("Failed to fetch feedback.");
      }
    } else {
      reset({
        review: "",
        degree: "",
      });
    }
  };

  const handleSaveReview = (data) => {
    if (!data.review || !data.degree) {
      setError("Both Review and Degree are required.");
      return;
    }
    setError("");
    setReviewDialogOpen(false);
    handleConfirmSave(data);
  };

  const handleConfirmSave = async (data) => {
    const payload = {
      deliverable_result: selectedReview?.id,
      description: data.review,
      degree: parseInt(data.degree, 10),
    };

    try {
      if (selectedReview?.has_feedback) {
        // PATCH request for editing feedback
        const response = await axios.patch(
          `${DeliverablesUpdateFeedBackEndPoint}${selectedReview.feedback_id}/`,
          {
            description: data.review,
            degree: parseInt(data.degree, 10),
          }
        );
        if (response.status === 200) {
          toast.success("Feedback updated successfully!");
        } else {
          toast.error("Failed to update feedback.");
        }
      } else {
        // POST request for new feedback
        const response = await axios.post(
          DeliverablesFeedBackEndPoint,
          payload
        );
        if (response.status === 201) {
          toast.success("Feedback saved successfully!");
        } else {
          toast.error("Failed to save feedback.");
        }
      }
      setIsStatusChanged((prev) => !prev);
    } catch (error) {
      toast.error("Error saving feedback: " + error.message);
    }
  };

  return (
    <div>
      {pageTitle && (
        <h1 className="mt-5 mb-3 font-medium text-base">{pageTitle}</h1>
      )}

      <div className="grid 2xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
        {dataToRender?.map((review, index) => (
          <Card
            key={index}
            content={[
              {
                icon: Person,
                title: "Student : ",
                content: review.student_email || "N/A",
              },
              {
                icon: CalenderIcon,
                title: "Created On : ",
                content:
                  new Date(review.created_on).toLocaleDateString("en-GB") ||
                  "N/A",
              },
              {
                icon: NetworkingIcon,
                title: "Coaching Round : ",
                content: review.coaching_rounds || "N/A",
              },
              {
                icon: QuestionIcon,
                title: "Task : ",
                content: review.deliverable_task_name || "N/A",
              },
            ]}
            buttonClassName={`bg-blue-800 ${review.has_feedback
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-blue-800"
              } text-white py-2 w-full text-center rounded-xl`}
            buttonContent={
              review.has_feedback ? "Feedback Given" : "Add Your Review"
            }
            disabledButton={review.has_feedback}
            editIcon={review.has_feedback ? EditIcon : null}
            onEditClick={
              review.has_feedback ? () => handleEditClick(review) : undefined
            }
            onClick={
              !review.has_feedback ? () => handleReviewClick(review) : undefined
            }
          />
        ))}
        {paginationLoading && !isPaginationDisabled && (
          <>
            <CardSkeleton />
            <CardSkeleton />
          </>
        )}
      </div>

      {/* Review Dialog */}
      <Dialog
        open={isReviewDialogOpen}
        onClose={() => setReviewDialogOpen(false)}
      >
        <DialogContent className="p-5">
          <h2 className="text-center p-5">
            {selectedReview?.deliverable_task_name || "Task Name"}
          </h2>
          <p>
            <strong>Student:</strong> {selectedReview?.student_email}
          </p>
          {selectedReview?.deliverable_attachments?.map((attachment, index) => (
            <div key={index}>
              <p className="my-2">
                <strong>File:</strong>
                <a
                  className="text-primary"
                  href={attachment.attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View File
                </a>
              </p>
              <p className="my-2">
                <strong>URL:</strong>{" "}
                <a
                  className="text-primary underline"
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Link
                </a>
              </p>
            </div>
          ))}
          <div className="w-[30rem] mb-2">
            <label
              htmlFor="review"
              className="text-black-12 mb-2 block text-base "
            >
              Review
            </label>
            <textarea
              id="review"
              className="textarea outline-none border border-solid border-gray-300 w-full resize-none "
              rows={4}
              placeholder="Your Review..."
              {...register('review')}
            />
            {errors.review && <ErrorMessage message={errors.review.message} />}
          </div>
          <div className="mb-2">
            <label
              htmlFor="degree"
              className="text-black-12 block mb-2 text-base"
            >
              Grade
            </label>

            <input
              id="degree"
              className="input input-bordered w-[30rem]"
              placeholder="Grade"
              type="number"
              {...register('degree')}
            />
            {errors.degree && <ErrorMessage message={errors.degree.message} />}
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-primary"
            onClick={handleSubmit(handleSaveReview)}
          >
            Submit
          </button>
          <button
            style={{ border: "1px solid #EE201C" }}
            className="btn bg-white hover:bg-white text-[#EE201C]"
            onClick={() => setReviewDialogOpen(false)}
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isConfirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogContent>
          <h3 className="flex items-center py-5">
            Are you sure you want to save this feedback?
          </h3>
          <p>
            <strong>Task:</strong> {selectedReview?.deliverable_task_name}
          </p>
          <p>
            <strong>Student:</strong> {selectedReview?.student_email}
          </p>
          <p>
            <strong>Task Grade:</strong> {selectedReview?.feedback?.degree}
          </p>
          <h2 className="font-bold text-base mb-2">Your Review:</h2>
          <p className="break-words max-w-full h-[10rem] overflow-y-auto">
            {selectedReview?.feedback?.description}
          </p>
        </DialogContent>
        <DialogActions>
          <button
            style={{ border: "1px solid #EE201C" }}
            className="btn bg-white hover:bg-white text-[#EE201C]"
            onClick={() => setConfirmDialogOpen(false)}
          >
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleConfirmSave}>
            Confirm
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeliverablesComponent;
