import { Navigate, Route } from "react-router-dom";
import CPGigsPage from "../Components/AdminDashboard/ControlPanel/CPGigsPage/CPGigsPage";
import CPInstructorsPage from "../Components/AdminDashboard/ControlPanel/CPInstructorsPage/CPInstructorsPage";
import CPStudentDetailsPage from "../Components/AdminDashboard/ControlPanel/CPStudentsPage/CPStudentDetails/CPStudentDetailsPage";
import CPStudentsPage from "../Components/AdminDashboard/ControlPanel/CPStudentsPage/CPStudnetsPage";
import { pageRoutes } from "../constants";
import AdminRoutes from "./AdminRoutes";
import CPInstructorDetailsPage from "../Components/AdminDashboard/ControlPanel/CPInstructorsPage/CPInstructorDetails/CPInstructorDetailsPage";
import CPWebinarsPage from "../Components/AdminDashboard/ControlPanel/CPWebinarsPage/CPWebinarsPage";
import CreateNewGroupLayout from "../Components/AdminDashboard/ControlPanel/CreateNewGroup/CreateNewGroupLayout";
import JobProfilePage from "../Components/AdminDashboard/ControlPanel/CreateNewGroup/JobProfilePage/JobProfilePage";
import CoachRoundPage from "../Components/AdminDashboard/ControlPanel/CreateNewGroup/CoachRoundPage/CoachRoundPage";
import UploadStudentsPage from "../Components/AdminDashboard/ControlPanel/CreateNewGroup/UplodStudentsPage/UploadStudentsPage";

const AdminEditorRoutes = () => {
  return (
    <AdminRoutes>
      <Route
        path={pageRoutes.controlPanelStudents}
        element={<CPStudentsPage />}
      />
      <Route
        path={`${pageRoutes.controlPanelStudents}/:id`}
        element={<CPStudentDetailsPage />}
      />
      <Route
        path={pageRoutes.controlPanelInstructors}
        element={<CPInstructorsPage />}
      />

      <Route
        path={`${pageRoutes.controlPanelInstructors}/:id`}
        element={<CPInstructorDetailsPage />}
      />
      <Route
        path={pageRoutes.controlPanelCreateNewGroup}
        element={<CreateNewGroupLayout />}
      >
        <Route
          index
          element={<Navigate to={`${pageRoutes.controlPanelJobProfile}`} />}
        />
        <Route
          path={pageRoutes.controlPanelJobProfile}
          element={<JobProfilePage />}
        />
        <Route
          path={pageRoutes.controlPanelCoachRound}
          element={<CoachRoundPage />}
        />
        <Route
          path={pageRoutes.controlPanelUploadStudents}
          element={<UploadStudentsPage />}
        />
      </Route>
      <Route path={pageRoutes.controlPanelGigs} element={<CPGigsPage />} />
      <Route path={pageRoutes.controlPanelWebinars} element={<CPWebinarsPage />} />
    </AdminRoutes>
  );
};

export default AdminEditorRoutes;
