import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import CustomButton from "../../../Components/shared/CustomButton";
import BuildinTwoIcon from "../../../Components/svgs/BuildingTwoIcon";
import CalenderIcon from "../../../Components/svgs/CalenderIcon";
import EditIcon from "../../../Components/svgs/EditIcon";
import NoteIcon from "../../../Components/svgs/NoteIcon";
import PriceIcon from "../../../Components/svgs/PriceIcon";
import { apiGetGigs } from "../../../Services/apiGetGigs";
import {
  freelancePlatformsEndpoint,
  getJobsCategoryEndPoint,
  patchStatusMyJobsEndPoint,
  postFreelanceJobEndPoint,
} from "../../../Services/endPoints";
import useFetch from "../../../hooks/useFetch";
import CardSkeleton from "../../InsturctorDashboard/Components/Common/Card/CardSkeleton";
import styles from "./Jobs.module.css";

function Jobs() {
  const { ref, inView } = useInView();
  const queryClient = useQueryClient();

  const [formOpen, setFormOpen] = useState(false);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    url: "",
    category: "",
    status: "",
    price: "",
    proposal_file: "",
    platformId: "",
  });

  // accepted status
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [statusProofFile, setStatusProofFile] = useState(null);
  const [fileNameStatusProofFile, setFileNameStatusProofFile] = useState("");
  const [fileUploadErrorStatusProofFile, setFileUploadErrorStatusProofFile] =
    useState("");
  // edits jobs
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const [errors, setErrors] = useState({});
  // eslint-disable-next-line
  const { data: jobCatigoryData } = useFetch(getJobsCategoryEndPoint);

  const {
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["gigs"],
    queryFn: ({ pageParam = "1" }) => apiGetGigs(pageParam),
    initialPageParam: "1",
    getNextPageParam: (lastPage) => {
      if (lastPage.next === null) return undefined;
      const parsedUrl = new URL(lastPage.next);
      const pageNumber = parsedUrl.searchParams.get("page");
      return pageNumber;
    },
  });
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const { data: UserFreelanceplatformsExternal } = useFetch(
    freelancePlatformsEndpoint
  );

  const validateForm = () => {
    let errors = {};

    if (!formData.title) errors.title = "Job title is required";
    if (!formData.url) errors.url = "Job URL is required";
    if (!formData.category) errors.category = "Category is required";
    if (!formData.status) errors.status = "Status is required";
    if (!formData.price || isNaN(formData.price))
      errors.price = "Valid price is required";
    if (!formData.proposal_file) {
      errors.proposal_file = "File upload is required";
    } else {
      const allowedTypes = ["application/pdf", "image"];
      const maxFileSize = 5 * 1024 * 1024;
      if (!allowedTypes.includes(formData.proposal_file.type)) {
        errors.proposal_file = "Only PDF, JPG, or PNG files are allowed";
      } else if (formData.proposal_file.size > maxFileSize) {
        errors.proposal_file = "File size must be less than 5MB";
      }
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const submitPostJob = async () => {
    if (!validateForm()) return;
    const formDataRequest = new FormData();
    const data = {
      title: formData.title,
      url: formData.url,
      category: formData.category,
      price: formData.price,
      proposal_file: formData.proposal_file,
      platform: formData.platformId,
    };
    Object.entries(data).forEach(([key, value]) => {
      formDataRequest.append(key, value);
    });
    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await axios.post(
        postFreelanceJobEndPoint,
        formDataRequest
      );
      setLoading(false);

      closeForm();
      queryClient.invalidateQueries("gigs");
    } catch (error) {
      console.error("Error posting job:", error);
      if (error.response && error.response.status === 400) {
        const backendErrors = error.response.data;
        const newErrors = {};

        for (const [field, messages] of Object.entries(backendErrors)) {
          newErrors[field] = Array.isArray(messages)
            ? messages.join(" ")
            : messages;
        }
        setErrors(newErrors);
      } else {
        setErrors({
          general: "An unexpected error occurred. Please try again later.",
        });
      }
    }
  };

  const openForm = () => {
    setFormOpen(true);
  };

  const closeForm = () => {
    setFormOpen(false);
    setFormData({
      title: "",
      url: "",
      category: "",
      status: "",
      price: "",
      proposal_file: "",
      platform: "",
    });
    setErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, proposal_file: e.target.files[0] });
  };

  const handleCommentsOpen = () => {
    setCommentsOpen(true);
  };

  const handleCommentsClose = () => {
    setCommentsOpen(false);
  };

  const updateJobStatus = async (jobId, newStatus) => {
    try {
      await axios.patch(`${patchStatusMyJobsEndPoint}/${jobId}/`, {
        status: newStatus,
      });
      queryClient.invalidateQueries("gigs");
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleStatusChange = async (index, event, jobId) => {
    const newStatus = event.target.value;

    if (newStatus === "Accepted") {
      setFileDialogOpen(true);
      setSelectedJobId(jobId);
    } else {
      await updateJobStatus(jobId, newStatus);
    }
  };

  const handleFileChangeStatusProofFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileNameStatusProofFile(file.name);
      setStatusProofFile(file);
      setFileUploadErrorStatusProofFile("");
    }
  };

  const handleAcceptedFileSubmit = async () => {
    if (!statusProofFile) {
      setFileUploadErrorStatusProofFile(
        "Please submit file to review for accepted Jobs."
      );
      return;
    }

    const formData = new FormData();
    formData.append("status", "Accepted");
    formData.append("status_proof_screenshot", statusProofFile);

    try {
      setLoading(true);
      await axios.patch(
        `${patchStatusMyJobsEndPoint}/${selectedJobId}/`,
        formData
      );
      setLoading(false);
      setFileDialogOpen(false);
      setStatusProofFile(null);
      setFileNameStatusProofFile("");
      setSelectedJobId(null);
      setFileUploadErrorStatusProofFile("");
      queryClient.invalidateQueries("gigs");
    } catch (error) {
      console.error("Error updating job status with file:", error);
    }
  };

  // handle  handle
  const handleEditClick = (job) => {
    setSelectedJob(job);
    setEditDialogOpen(true);
  };

  const handleEditJobInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedJob((prevJob) => ({ ...prevJob, [name]: value }));
  };

  const handleEditSubmit = async () => {
    try {
      const formData = new FormData();

      formData.append("price", selectedJob.price);

      if (statusProofFile) {
        formData.append("status_proof_screenshot", statusProofFile);
      }

      setLoading(true);
      const response = await axios.patch(
        `${patchStatusMyJobsEndPoint}/${selectedJob.id}/`,
        formData
      );
      setLoading(false);

      setEditDialogOpen(false);
      queryClient.invalidateQueries("gigs");
    } catch (error) {
      console.error("Error updating job:", error);
    }
  };

  return (
    <>
      <div className={styles.topSection}>
        <div className={styles.addOpportunity} onClick={openForm}>
          <div className={styles.addIconContainer}>
            <AddIcon className={styles.addIcon} fontSize="small" />
          </div>
          <span className={styles.addOpportunityText}>
            Add any freelance opportunities you've had or completed!
          </span>
        </div>
        <div className={`${styles.guideSection} w-[60%]`}>
          <h2>Job Status Guide:</h2>
          <div className={styles.statusGuide}>
            <div className={styles.yellow}></div>
            <h3>Pending Review: Job submitted, awaiting instructor review.</h3>
          </div>
          <div className={styles.statusGuide}>
            <div className={styles.green}></div>
            <h3>Completed: Reviewed and approved by instructor.</h3>
          </div>
          <div className={styles.statusGuide}>
            <div className={styles.red}></div>
            <h3>Rejected: Reviewed by instructor, not approved.</h3>
          </div>
        </div>
      </div>
      <div className={styles.jobCards}>
        {isLoading || isFetching ? (
          Array(10)
            .fill(0)
            .map((_, index) => <CardSkeleton />)
        ) : isError ? (
          <p className="text-gray-5f text-center font-semibold w-full text-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            Something went wrong !
          </p>
        ) : (
          !isLoading &&
          !isFetching &&
          data?.pages?.map((page, pageIndex) => (
            <React.Fragment key={pageIndex}>
              {page.results.map((job, index) => (
                <div key={job.id} className={styles.jobCard}>
                  <div className="mb-3">
                    <div className={styles.cardHeader}>
                      {job.status === "Accepted" ? (
                        <>
                          {job.is_approved === false &&
                            job.waiting_for_approval === true ? (
                            <div className={styles.statusTextInReview}>
                              In Review
                            </div>
                          ) : job.is_approved === true &&
                            job.waiting_for_approval === false ? (
                            <div className={styles.statusTextAccepted}>
                              Accepted
                            </div>
                          ) : (
                            <div className={styles.statusTextRejected}>
                              Rejected
                            </div>
                          )}
                        </>
                      ) : (
                        <Select
                          value={job.status}
                          onChange={(e) => handleStatusChange(index, e, job.id)}
                          variant="outlined"
                          className={styles.JobSelect}
                          style={{
                            border: "1px solid blue",
                            color: "blue",
                            borderRadius: "5px",
                            padding: "0px",
                            marginInline: "0px",
                          }}
                        >
                          {job.status !== "In Progress" &&
                            job.status !== "Accepted" &&
                            job.status !== "Rejected" && (
                              <MenuItem value="Proposal Submitted">
                                Proposal Submitted
                              </MenuItem>
                            )}
                          {job.status !== "Accepted" &&
                            job.status !== "Rejected" && (
                              <MenuItem value="In Progress">
                                In Progress
                              </MenuItem>
                            )}
                          <MenuItem value="Accepted">Accepted</MenuItem>
                          <MenuItem value="Rejected">Rejected</MenuItem>
                        </Select>
                      )}
                      {job.status === "Accepted" &&
                        job.is_approved === false &&
                        job.waiting_for_approval === false && (
                          <EditIcon
                            onClick={() => handleEditClick(job)}
                            className="w-[2.4rem] h-[2.4rem] ml-2"
                          />
                        )}
                    </div>
                    <h3 className="text-black font-medium text-[1.3rem] max-w-[100%] break-words">
                      {job.title}
                    </h3>
                  </div>

                  <div className={styles.cardDetails}>
                    <div className="flex justify-between">
                      <p>
                        <BuildinTwoIcon className="w-[1rem] h-[1rem]" />{" "}
                        {job.platform}
                      </p>
                      <p className="w-[50%]">
                        <CalenderIcon className="w-[1rem] h-[1rem]" />{" "}
                        {new Date(job.created_on).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="w-[50%]">
                        <NoteIcon className="w-[1rem] h-[1rem]" />{" "}
                        {job.category}
                      </p>
                      <p className="w-[50%]">
                        <PriceIcon className="w-[1.2rem] h-[1.2rem] text-gray-7e " />
                        {job.price} USD
                      </p>
                    </div>
                  </div>

                  <a
                    className="btn-primary w-full py-3"
                    href={job.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Link
                  </a>

                  {job.is_approved === false &&
                    job.waiting_for_approval === false && (
                      <button
                        className={styles.commentsBtn}
                        onClick={handleCommentsOpen}
                      >
                        See Instructor Comments
                      </button>
                    )}
                </div>
              ))}
            </React.Fragment>
          ))
        )}
        {isFetchingNextPage &&
          Array(10)
            .fill(0)
            .map((_, index) => <CardSkeleton />)}
        <div ref={ref} style={{ height: "20px", background: "transparent" }} />
      </div>

      {/* Add New Job Dialog  */}
      <Dialog
        open={formOpen}
        onClose={closeForm}
        className="rounded-xl"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Add New Job
          <IconButton
            onClick={closeForm}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Job Title"
            name="title"
            variant="outlined"
            value={formData.title}
            onChange={handleInputChange}
            error={!!errors.title}
            helperText={errors.title}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Job URL"
            name="url"
            variant="outlined"
            value={formData.url}
            onChange={handleInputChange}
            error={!!errors.url}
            helperText={errors.url}
          />
          <TextField
            fullWidth
            select
            margin="dense"
            label="Category"
            name="category"
            variant="outlined"
            value={formData.category}
            onChange={handleInputChange}
            error={!!errors.category}
            helperText={errors.category}
          >
            {jobCatigoryData &&
              jobCatigoryData.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            fullWidth
            select
            margin="dense"
            label="Status"
            name="status"
            variant="outlined"
            value={formData.status}
            onChange={handleInputChange}
            error={!!errors.status}
            helperText={errors.status}
          >
            <MenuItem value="Proposal Submitted">Proposal Submitted</MenuItem>
          </TextField>
          <TextField
            fullWidth
            margin="dense"
            label="Price (USD)"
            name="price"
            variant="outlined"
            value={formData.price}
            onChange={handleInputChange}
            error={!!errors.price}
            helperText={errors.price}
          />
          <select
            name="platformId"
            value={formData.platformId}
            onChange={handleInputChange}
            className="w-[100%] select select-bordered my-4"
          >
            <option value="">Select Platform</option>
            {UserFreelanceplatformsExternal &&
              UserFreelanceplatformsExternal.map((platform) => (
                <option key={platform.id} value={platform.id}>
                  {platform.name}
                </option>
              ))}
          </select>

          <p className="my-2">Upload Proposal File</p>
          <input
            className="file-input border border-gray-300"
            type="file"
            accept="application/pdf"
            onChange={(e) => handleFileChange(e)}
          />
          {errors.proposal_file && (
            <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
              {errors.proposal_file}
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <CustomButton
            className="py-3"
            onClick={submitPostJob}
          // isLoading={loading}
          >
            Add Job
          </CustomButton>
          <CustomButton
            onClick={closeForm}
            className="bg-white border border-solid hover:bg-gray-100 border-gray-300 text-red-500 hover"
          >
            Cancel
          </CustomButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={commentsOpen}
        onClose={handleCommentsClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Instructor Comment
          <IconButton
            onClick={handleCommentsClose}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <p>
            These are the comments from the instructor regarding your job
            application...
          </p>
        </DialogContent>
        <DialogActions className={styles.commentsBtnSection}>
          <button
            className={styles.viewLinkButton}
            onClick={handleCommentsClose}
          >
            OK
          </button>
        </DialogActions>
      </Dialog>

      {/* Upload Proof of Acceptance Dialog */}
      <Dialog
        open={fileDialogOpen}
        onClose={() => setFileDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <h2 className="p-3 text-xl text-center text-[#273C97] font-bold  my-5">
          Amazing Work! 🚀
        </h2>
        <p className="font-[1.5rem] text-center w-[85%] m-auto ">
          Upload proof of your client’s acceptance to celebrate this milestone
          and keep moving forward in your journey!
        </p>
        <DialogContent>
          <div className="w-full max-w-xs mx-auto">
            {fileNameStatusProofFile && (
              <p className="my-4 flex items-center  ">
                <TaskOutlinedIcon /> {fileNameStatusProofFile}
              </p>
            )}
            <label className="flex flex-col items-center px-4 py-2 bg-[#EBEBEB] text-black rounded-lg cursor-pointer ">
              <span className="text-base leading-normal">
                {!fileNameStatusProofFile ? (
                  <div className="flex items-center">Upload File</div>
                ) : (
                  <div className="flex items-center">Upload Other file</div>
                )}
              </span>
              <input
                type="file"
                accept="application/pdf,image/*"
                onChange={handleFileChangeStatusProofFile}
                className="hidden"
              />
            </label>
            <p className="text-center text-[.8rem] text-[#999898]">
              You can upload a file (PDF, JPG, PNG, etc...)
            </p>

            {fileUploadErrorStatusProofFile && (
              <p className="mt-2 text-sm text-red-600 text-center">
                {fileUploadErrorStatusProofFile}
              </p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <div className="flex items-center gap-2 mb-2">
            <CustomButton
              onClick={handleAcceptedFileSubmit}
              className="py-3 text-sm"
              isLoading={loading}
            >
              Submit
            </CustomButton>
            <button
              style={{ border: "1px solid red" }}
              onClick={() => setFileDialogOpen(false)}
              className="text-red-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Close
            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/* edit job  Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Edit Job</DialogTitle>
        <DialogContent>
          <div className="my-5">
            <TextField
              fullWidth
              label="Price"
              name="price"
              value={selectedJob?.price || ""}
              onChange={handleEditJobInputChange}
            />
          </div>
          <div className="my-5">
            <div className="w-full max-w-xs mx-auto">
              <label className="flex flex-col items-center px-4 py-2 bg-blue-600 text-white rounded-lg cursor-pointer hover:bg-blue-700 transition duration-200">
                <span className="text-base leading-normal">
                  Upload Proof Screenshot
                </span>
                <input
                  type="file"
                  accept="application/pdf,image/*"
                  onChange={handleFileChangeStatusProofFile}
                  className="hidden"
                />
              </label>

              {fileNameStatusProofFile && (
                <span className="mt-2 text-sm text-gray-700">
                  Selected file: <strong>{fileNameStatusProofFile}</strong>
                </span>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditSubmit} color="primary">
            Save
          </Button>
          <Button onClick={() => setEditDialogOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Jobs;
