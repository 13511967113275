import React, { useEffect, useState } from "react";
import ArrowWhite from "../../../Components/svgs/ArrowWhite";
import { UserDeliverablesStudents } from "../../../Services/endPoints";
import useFetch from "../../../hooks/useFetch";
import DeliverableDetails from "./DeliverableDetails";
import styles from "./Tasks.module.css";
import { useWindowSize } from "../../../hooks/useWindowSize";
import arrow from "../../../assets/arrow-down.svg";
import Loading from "../../../Components/shared/Loading";
import { useSearchParams } from "react-router-dom";

function StudentDeliverablesPage() {
  const [selectedDeliverable, setSelectedDeliverable] = useState(null);
  const [searchParams] = useSearchParams();

  const { width } = useWindowSize();
  const {
    data: UserDeliverablesStudentsData,
    loading: UserDeliverablesStudentsLoading,
    error: UserDeliverablesStudentsError,
  } = useFetch(UserDeliverablesStudents);

  useEffect(() => {
    if (
      UserDeliverablesStudentsData &&
      UserDeliverablesStudentsData.length > 0
    ) {
      if (searchParams.has("deliverable")) {
        setSelectedDeliverable(
          UserDeliverablesStudentsData?.find(
            (deliverable) => deliverable.id === searchParams.get("deliverable")
          )
        );
        return;
      }
      setSelectedDeliverable(UserDeliverablesStudentsData[0]);
    }
  }, [UserDeliverablesStudentsData]);

  const getArrowColor = (completed) => {
    if (completed === true) return "#273C97";
    if (completed === false) return "#F0F0F0";
    return "#ADD8E6";
  };

  const handleCardClick = (deliverable) => {
    setSelectedDeliverable(deliverable);
  };
  const onSelectDeliverable = (e) => {
    const selected = UserDeliverablesStudentsData.find(
      (deliverable) => deliverable.id === e.target.value
    );
    setSelectedDeliverable(selected);
  };

  if (UserDeliverablesStudentsLoading) return <Loading />;
  if (UserDeliverablesStudentsError) return <p>Error loading data</p>;

  return (
    <div className={styles.dashboardOverview}>
      <h1 className="text-center text-5xl md:text-2xl my-6 text-[#202020] font-semibold">
        Delivers Timeline
      </h1>
      <div className="w-full grid gap-x-4 justify-items-center grid-cols-[repeat(auto-fill,minmax(10rem,1fr))] max-[500px]:hidden">
        {UserDeliverablesStudentsData?.map((deliverable, index) => (
          <ArrowWhite
            key={index}
            color={getArrowColor(deliverable.completed)}
            className="w-40 h-40"
            text={deliverable.week}
          />
        ))}
      </div>

      {/* Deliverable Cards */}

      {width > 500 && (
        <div className="w-full grid my-5 gap-4 justify-center grid-cols-[repeat(auto-fill,minmax(200px,1fr))]">
          {UserDeliverablesStudentsData?.map((deliverable, index) => (
            <div
              key={index}
              className={`border rounded-3xl py-5 px-2 flex items-center justify-center gap-3 cursor-pointer`}
              style={{ backgroundColor: "#273C97" }}
              onClick={() => handleCardClick(deliverable)}
            >
              <h1 className="text-2xl md:text-base my-6 text-[#F0F0F0] font-semibold">
                {deliverable.week} Deliverables
              </h1>
            </div>
          ))}
        </div>
      )}

      {width <= 500 && (
        <select
          value={selectedDeliverable?.id}
          onChange={onSelectDeliverable}
          className={`w-full mt-4 text-sm py-2 px-4 bg-white border border-gray-350 rounded-lg appearance-none focus:border-gray-7e bg-no-repeat bg-[95%_center] bg-[length:0.8rem] cursor-pointer`}
          style={{
            backgroundImage: `url(${arrow})`,
          }}
        >
          {UserDeliverablesStudentsData?.map((deliverable) => (
            <option key={deliverable.id} value={deliverable.id}>
              {deliverable?.week} Deliverables
            </option>
          ))}
        </select>
      )}

      {selectedDeliverable?.id && (
        <DeliverableDetails deliverable={selectedDeliverable} />
      )}
    </div>
  );
}

export default StudentDeliverablesPage;
