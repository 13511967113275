import React, { useRef, useState } from 'react';
import EditWebinarForm from './EditWebinarForm/EditWebinarForm';

export default function EditWebinarDialog({ id, webinar }) {
    const dialogRef = useRef(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const openDialog = () => {
        setDialogOpen(true);
        dialogRef.current?.showModal();
    };

    const closeDialog = () => {
        setDialogOpen(false);
        dialogRef.current?.close();
    };

    return (
        <>
            <span>
                <button
                    onClick={openDialog}
                    className='btn-primary bg-[#273C97] font-medium px-5 py-3 rounded-lg'
                >
                    Edit
                </button>
            </span>
            <dialog
                ref={dialogRef}
                className='fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[30rem] min-h-[15rem] border-none bg-[#fff] rounded-xl outline-none p-5 overflow-visible'
            >
                <div className='flex justify-between items-center mb-4'>
                    <h1 className='text-[#3F3F3F] font-bold text-base'>Edit Webinar</h1>
                    <button
                        onClick={closeDialog}
                        className='text-[#71747B] hover:text-gray-900'
                    >
                        ✖
                    </button>
                </div>
                {dialogOpen &&
                    <div className='w-full text-left'>
                        <EditWebinarForm
                            id={id}
                            dialogOpen={dialogOpen}
                            closeDialog={closeDialog}
                            webinar={webinar}
                        />
                    </div>
                }
            </dialog>
        </>
    );
}
