import React from 'react'

export default function QAClock({ className }) {
    return (
        <svg
            width={73}
            height={73}
            viewBox="0 0 73 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle
                cx={36.0992}
                cy={36.0992}
                r={36.0992}
                fill="url(#paint0_linear_1424_4484)"
            />
            <path
                d="M37.1875 34C37.1875 34.6558 36.6558 35.1875 36 35.1875C35.3442 35.1875 34.8125 34.6558 34.8125 34C34.8125 33.3442 35.3442 32.8125 36 32.8125C36.6558 32.8125 37.1875 33.3442 37.1875 34Z"
                fill="#273C97"
            />
            <path
                d="M37.1875 34C37.1875 34.6558 36.6558 35.1875 36 35.1875C35.3442 35.1875 34.8125 34.6558 34.8125 34C34.8125 33.3442 35.3442 32.8125 36 32.8125M37.1875 34C37.1875 33.3442 36.6558 32.8125 36 32.8125M37.1875 34H43.125M36 32.8125V25.2917M50.25 34C50.25 41.8701 43.8701 48.25 36 48.25C28.1299 48.25 21.75 41.8701 21.75 34C21.75 26.1299 28.1299 19.75 36 19.75C43.8701 19.75 50.25 26.1299 50.25 34Z"
                stroke="#273C97"
                strokeWidth={2.2807}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1424_4484"
                    x1={64.076}
                    y1={0.00000184103}
                    x2={36.0992}
                    y2={72.1983}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#859CFF" stopOpacity={0.44} />
                    <stop offset={1} stopColor="#F1F8FF" />
                </linearGradient>
            </defs>
        </svg>
    )
}
