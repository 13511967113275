import BurgerIcon from "../../../Components/svgs/BurgerIcon";
import { useWindowSize } from "../../../hooks/useWindowSize";
import NavigationTabs from "../NavigationTabs";
const MobileNavMenu = () => {
  const closeDrawer = () => {
    document.getElementById("mobile-nav-drawer").checked = false;
    localStorage.setItem("isDrawerOpen", false);
  };
  const openDrawer = () => {
    document.getElementById("mobile-nav-drawer").toggleAttribute("checked");
    localStorage.setItem("isDrawerOpen", true);
  };

  return (
    <div className="drawer tablet:hidden w-fit">
      <input
        id="mobile-nav-drawer"
        type="checkbox"
        onChange={openDrawer}
        className="drawer-toggle"
      />
      <div className="drawer-content">
        <label
          htmlFor="mobile-nav-drawer"
          className="drawer-button cursor-pointer"
        >
          <BurgerIcon />
        </label>
      </div>
      <div className="drawer-side">
        <label
          htmlFor="mobile-nav-drawer"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>
        <ul className="menu bg-base-200 text-base-content min-h-full w-80 px-4 py-2rem flex flex-col gap-3">
          <NavigationTabs closeDrawer={closeDrawer} />
        </ul>
      </div>
    </div>
  );
};

export default MobileNavMenu;
