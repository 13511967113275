import React, { useEffect, useMemo, useState } from "react";
import FilterGroup from "../../../Components/AdminDashboard/shared/Filters/FilterGroup";
import useFetch from "../../../hooks/useFetch";
import { CoachingRoundEndPoint } from "../../../Services/endPoints";

const initialFilters = [
  {
    name: "Coaching Round",
    key: "coaching_round",
  },
  {
    name: "Mockups",
    key: "deliverable",
  },
  {
    name: "Reviewed",
    key: "has_feedback",
    options: [
      {
        name: "Yes",
        value: "True",
      },
      {
        name: "No",
        value: "False",
      },
    ],
  },
];

export default function ReviewsFilter() {
  const [filters, setFilters] = useState(initialFilters);
  const { data: coachingRoundsData } = useFetch(CoachingRoundEndPoint);
  const coachingRounds = Array.isArray(coachingRoundsData)
    ? coachingRoundsData
    : [];

  const staticMockups = useMemo(() => ["Q2", "Q3"], []);

  useEffect(() => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => {
        switch (filter.key) {
          case "coaching_round":
            return {
              ...filter,
              options: coachingRounds.map((round, index) => ({
                name: round.code || `Unknown ${index}`,
                value: round.code || `unknown_${index}`,
              })),
            };
          case "deliverable":
            return {
              ...filter,
              options: staticMockups.map((mockup) => ({
                name: mockup,
                value: mockup,
              })),
            };
          default:
            return filter;
        }
      })
    );
  }, [JSON.stringify(coachingRounds), JSON.stringify(staticMockups)]);

  return <FilterGroup filters={filters} />;
}
