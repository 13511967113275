import React, { useEffect, useState } from "react";
import SearchIcon from "../../../../Components/svgs/SearchIcon";
import { useSearchParams } from "react-router-dom";

const SearchInput = ({ name = "email", placeholder = "Search by student email" }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm]);
  const handleSearch = (term) => {
    const updatedParams = new URLSearchParams(searchParams);

    if (term !== "") {
      updatedParams.set(name, term);
    } else {
      updatedParams.delete(name);
    }
    setSearchParams(updatedParams);
    localStorage.setItem("filters", updatedParams.toString());
  };
  useEffect(() => {
    handleSearch(debouncedTerm);
  }, [debouncedTerm]);
  return (
    <div className="relative w-fit rounded-lg overflow-hidden shadow bg-white h-fit ms-auto block ">
      <SearchIcon className="absolute top-[50%] text-gray-7e translate-y-[-50%] left-2 size-[1.5rem] block" />
      <input
        type="search"
        className="w-[17rem] ps-[2rem] py-[1rem] input"
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  );
};

export default SearchInput;
