import React from "react";
import { IoClose } from "react-icons/io5";
import SelectInput from "../../../../../shared/SelectInput";
import { useGetSelectionData } from "./useGetSelectionData";

const SelectInputEdit = ({
  label,
  placeholderText,
  inputKey,
  getValues,
  setValue,
  watch,
  errors,
  apiCurrentValues,
}) => {
  watch(inputKey);

  const { selectionOptions } = useGetSelectionData();
  const currentValues = apiCurrentValues?.map((value) => value?.code);
  const filteredValues = selectionOptions[inputKey].filter((round) => {
    return (
      !currentValues?.includes(round) && !getValues(inputKey)?.includes(round)
    );
  });

  const removeValue = (key, valueToRemove) => {
    const currentValues = getValues(key);
    const updatedValues = currentValues
      .split(",")
      .filter((value) => value !== valueToRemove);

    setValue(key, updatedValues.join(","));
  };
  const changeValue = (e) => {
    if (getValues(inputKey) === "") {
      setValue(inputKey, e.target.value);
      return;
    }
    const inputValues = getValues(inputKey).split(",");
    inputValues.unshift(e.target.value);
    setValue(inputKey, `${inputValues.join(",")}`);
  };
  return (
    <SelectInput
      items={filteredValues}
      name={inputKey}
      label={label}
      value={""}
      onChange={changeValue}
      placeholderText={placeholderText}
      errors={errors}
    >
      <div className="flex items-center max-w-full flex-wrap">
        {getValues(inputKey) &&
          getValues(inputKey)
            .split(",")
            .map((value) => (
              <span className="bg-[#F8F8FD] flex gap-2 w-fit shadow-sm px-3 py-1 text-primary text-sm mt-3 font-medium rounded-sm ms-1">
                {value}
                <IoClose
                  className="size-[1.2rem] text-[#ACACAC] cursor-pointer"
                  onClick={() => removeValue(inputKey, value)}
                />
              </span>
            ))}
        {currentValues.map((value) => (
          <span className="bg-[#F8F8FD] w-fit shadow-sm px-3 py-1 text-primary text-sm mt-3 block font-medium rounded-sm ms-1">
            {value}
          </span>
        ))}
      </div>
      <p className="text-[#DC2626] text-sm mt-2 w-fit ms-2 font-normal ">
        Note: Job profiles and coaching rounds cannot be modified
      </p>
    </SelectInput>
  );
};

export default SelectInputEdit;
