import { useInfiniteQuery } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import dateFormat from 'dateformat';
import { apiGetSingleStudentQuestion } from '../../../../Services/apiGetSingleStudentQA';
import DonutChart from '../DonutChart';
import CollapsibleCard from './CollapsibleCard';
import StudentQADialog from './StudentQADialog';

export default function StudentDetailsQA({ id, statistics }) {
    const studentQADialog = useRef(null);
    const [studentQAData, setStudentQAData] = useState(null);

    const {
        data,
        isLoading,
        isError,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: ['studentWebinars', id],
        queryFn: ({ pageParam = 1 }) => apiGetSingleStudentQuestion(id, pageParam),
        getNextPageParam: (lastPage) => {
            if (!lastPage.next) {
                return undefined;
            }
            const nextURL = new URL(lastPage.next);
            const nextPage = nextURL.searchParams.get('page');
            return nextPage;
        },
    });

    const openStudentQADialog = (studentQAData) => {
        setStudentQAData(studentQAData);
        studentQADialog.current?.showModal();
    };

    const closeStudentQADialog = () => {
        setStudentQAData(null);
        studentQADialog.current?.close();
    };

    if (!statistics) {
        return <p>Loading...</p>;
    }

    const { asked_questions, answered_questions, not_answered_questions } = statistics;
    const chartColors = ["#273C97", "#FFC107"];

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (isError) {
        return <p>Something went wrong while loading questions.</p>;
    }

    return (
        <div className='flex flex-row gap-4 lg:flex-nowrap flex-wrap'>
            <div className='flex flex-row gap-4 mt-4 flex-grow basis-3/4'>
                <div className='flex-grow basis-3/4'>
                    {data.pages.map((page, pageIndex) => (
                        <React.Fragment key={pageIndex}>
                            {page.results.map((questions) => (
                                <CollapsibleCard
                                    key={questions.id}
                                    title={questions.category}
                                    subtitle={dateFormat(questions.created_at, "dd/mm/yyyy")}
                                    status={questions.answer ? 'Answered' : 'Not Answered'}
                                    statusColor={
                                        questions.answer
                                            ? 'text-[#259800]'
                                            : 'text-[#FFC107]'
                                    }
                                    details={[
                                        { label: 'Coach ID', value: questions.coach_id },
                                        { label: 'Coach Name', value: questions.coach_name },
                                        {
                                            label: 'Response time',
                                            value: questions.answer?.created_at
                                                ? dateFormat(questions.answer.created_at, "dd/mm/yyyy")
                                                : "No response time available",
                                        },
                                    ]}
                                    buttonText={questions.answer ? "view answer" : null}
                                    onButtonClick={() => openStudentQADialog(questions)}
                                />
                            ))}
                        </React.Fragment>
                    ))}

                    {hasNextPage && (
                        <button
                            onClick={() => fetchNextPage()}
                            disabled={isFetchingNextPage}
                            className='text-[#2D7CDE] text-base mt-2'
                        >
                            {isFetchingNextPage ? 'Loading more...' : 'Show more...'}
                        </button>
                    )}
                </div>
            </div>
            <div className="flex-grow basis-1/4">
                <h2 className="text-[#000000] text-xl font-medium mb-2">
                    Asked Questions
                </h2>
                <h3 className="text-lg text-[#273C97] font-normal mb-4">
                    total number of Questions:
                    <span className="text-lg text-[#263238] font-medium">
                        {asked_questions}
                    </span>
                </h3>
                <DonutChart
                    data={[answered_questions, not_answered_questions]}
                    labels={['Answered', 'Not Answered']}
                    customOptions={{
                        colors: chartColors,
                    }}
                />
            </div>

            <StudentQADialog
                dialogRef={studentQADialog}
                onClose={closeStudentQADialog}
                dialogData={studentQAData}
            />
        </div>
    );
}
