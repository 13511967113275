import { useState } from "react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { twMerge } from "tailwind-merge";
import ErrorMessage from "./ErrorMessage";

const PasswordInput = ({
  label,
  labelClassName,
  id,
  wrapperClassName,
  fieldName,
  register,
  errors,
  className,

  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={wrapperClassName}>
      <label
        htmlFor={id}
        className={twMerge(
          labelClassName,
          "text-primary text-base font-medium mb-2 block"
        )}
      >
        {label}
      </label>
      <div className="relative w-full">
        <input
          type={showPassword ? "text" : "password"}
          id={id}
          className={twMerge(
            "input input-bordered rounded mt-0 border-[#DFEAF2] w-full pr-10",
            className
          )}
          {...register(fieldName)}
          {...props}
        />
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="absolute right-3 inset-y-0 flex items-center "
        >
          {showPassword ? (
            <IoEyeOff className="text-gray-7e size-[1.2rem]" />
          ) : (
            <IoEye className="text-gray-7e size-[1.2rem]" />
          )}
        </button>
      </div>
      <ErrorMessage
        message={errors[fieldName]?.message}
        isVisible={Boolean(errors[fieldName])}
      />
    </div>
  );
};

export default PasswordInput;
