import { Navigate, Route, Routes } from "react-router-dom";
import { pageRoutes } from "../constants";
import AdminLayout from "./AdminDashboard/components/AdminLayout";
import AIReviewer from "./AdminDashboard/pages/AIReviewer";
import AiInterviewDetailsPage from "./AdminDashboard/pages/AiInterviewDetailsPage";
import DashboardGigsPage from "./AdminDashboard/pages/DashboardGigsPage";
import DashboardInstructorsPage from "./AdminDashboard/pages/DashboardInstructorsPage";
import InstructorDetails from "./AdminDashboard/pages/InstructorDetails";
import QA from "./AdminDashboard/pages/QA";
import Reviews from "./AdminDashboard/pages/Reviews";
import StudentDetails from "./AdminDashboard/pages/StudentDetails";
import Students from "./AdminDashboard/pages/Students";
import Webinars from "./AdminDashboard/pages/Webinars";
import NotFoundPage from "./Common/NotFoundPage";
import AdminDashboardPage from "../Components/AdminDashboard/AdminDashboardPage/AdminDashboardPage";

const AdminRoutes = ({ children }) => {
  return (
    <Routes>
      <Route index element={<Navigate to={`/${pageRoutes.dashboard}`} />} />
      <Route element={<AdminLayout />}>
        <Route path={pageRoutes.dashboard} element={<AdminDashboardPage />} />
        <Route path={pageRoutes.students} element={<Students />} />
        <Route
          path={pageRoutes.instructors}
          element={<DashboardInstructorsPage />}
        />
        <Route
          path={`${pageRoutes.students}/${pageRoutes.studentDetails}/:id`}
          element={<StudentDetails />}
        />
        <Route
          path={pageRoutes.instructors}
          element={<DashboardInstructorsPage />}
        />
        <Route
          path={`${pageRoutes.instructors}/${pageRoutes.instructorDetails}/:id`}
          element={<InstructorDetails />}
        />
        <Route
          path={`ai-interviewing/:id`}
          element={<AiInterviewDetailsPage />}
        />
        <Route path={pageRoutes.webinars} element={<Webinars />} />
        <Route path={pageRoutes.reviews} element={<Reviews />} />
        <Route path={pageRoutes.aiReviewer} element={<AIReviewer />} />

        <Route path={pageRoutes.qa} element={<QA />} />
        <Route path={pageRoutes.gigs} element={<DashboardGigsPage />} />

        {children}

        <Route path={pageRoutes.settings} element={<></>} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
