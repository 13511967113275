import AssignmentIcon from "@mui/icons-material/Assignment";
import useFetch from "../../../hooks/useFetch";
import { GetDeliverableResultsById } from "../../../Services/endPoints";
import styles from "./Tasks.module.css";

const DeliverableFeedback = ({ hideFeedback, deliverableResult, taskName }) => {
  const { data: feedbackData } = useFetch(
    `${GetDeliverableResultsById}${deliverableResult}/`
  );
  return (
    <div className={styles.taskCard}>
      <p className={styles.description}>
        <AssignmentIcon /> {taskName}
      </p>

      <div className="flex gap-4">
        <div className="w-3/4 flex flex-col gap-2">
          <h4 className="text-black-12 font-semibold">Review Comment:</h4>
          <p className="max-w-full break-words max-h-[20rem] overflow-y-auto mb-2">
            {feedbackData && feedbackData[0]?.description}
          </p>
        </div>

        <div className="w-1/4 flex flex-col gap-2 border-l pl-3 border-[#C7C7C7]">
          <h4 className="text-black-12 font-semibold">Review Degree:</h4>
          <p className="max-w-full m-auto break-words text-[#343A40] text-6xl">
            {feedbackData && feedbackData[0]?.degree}
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center gap-5 mt-5">
        <button className="btn-primary py-2 px-3 w-40" onClick={hideFeedback}>
          Back to Task
        </button>
      </div>
    </div>
  );
};

export default DeliverableFeedback;
