import React from 'react'

export default function GigsIcon({ className }) {
    return (
        <svg
            width={73}
            height={73}
            viewBox="0 0 73 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle
                cx={36.0992}
                cy={36.0992}
                r={36.0992}
                fill="url(#paint0_linear_1961_7172)"
            />
            <path
                d="M34.2188 19.5625H40.7812C41.5579 19.5625 42.1875 20.1921 42.1875 20.9688V24.25H32.8125V20.9688C32.8125 20.1921 33.4421 19.5625 34.2188 19.5625ZM30 20.9688V24.25H26.7188C23.3533 24.25 20.625 26.9783 20.625 30.3438V33.1562C20.625 34.9684 22.0941 36.4375 23.9062 36.4375H33.75V35.5C33.75 34.4644 34.5894 33.625 35.625 33.625H39.375C40.4106 33.625 41.25 34.4644 41.25 35.5V36.4375H51.0938C52.9059 36.4375 54.375 34.9684 54.375 33.1562V30.3438C54.375 26.9783 51.6467 24.25 48.2812 24.25H45V20.9688C45 18.6388 43.1111 16.75 40.7812 16.75H34.2188C31.8888 16.75 30 18.6388 30 20.9688ZM54.375 38.2921C53.4279 38.8984 52.3018 39.25 51.0938 39.25H41.25C41.25 40.2856 40.4106 41.125 39.375 41.125H35.625C34.5894 41.125 33.75 40.2856 33.75 39.25H23.9062C22.6981 39.25 21.5722 38.8984 20.625 38.2921V44.4062C20.625 47.7717 23.3533 50.5 26.7188 50.5H48.2812C51.6467 50.5 54.375 47.7717 54.375 44.4062V38.2921Z"
                fill="#273C97"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1961_7172"
                    x1={64.076}
                    y1={0.00000184103}
                    x2={36.0992}
                    y2={72.1983}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#859CFF" stopOpacity={0.44} />
                    <stop offset={1} stopColor="#F1F8FF" />
                </linearGradient>
            </defs>
        </svg>
    )
}
