import React from 'react'

export default function QAAllAnswer({ className }) {
    return (
        <svg
            width={73}
            height={73}
            viewBox="0 0 73 73"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle
                cx={36.0992}
                cy={36.0992}
                r={36.0992}
                fill="url(#paint0_linear_1424_4457)"
            />
            <path
                d="M51.9931 56.852C50.1374 55.4378 48.8286 53.403 48.0574 51.9448C47.7812 51.4224 47.5518 50.9342 47.3684 50.5129H49.0425C50.8206 50.5129 52.2673 49.0662 52.2673 47.2881V36.3278C52.2673 34.5498 50.8206 33.103 49.0425 33.103H39.2402V34.4941H49.0425C50.0537 34.4941 50.8763 35.3167 50.8763 36.3278V47.2881C50.8763 48.2993 50.0537 49.1219 49.0425 49.1219H45.4341L45.4476 49.3775L45.4735 49.4604C45.4827 49.4904 45.6072 49.8877 45.8594 50.5129C46.0782 51.0559 46.3936 51.7712 46.8132 52.5672C47.3439 53.5743 47.9334 54.5041 48.5652 55.3313C48.7855 55.6195 49.0114 55.8957 49.2425 56.159C48.6132 55.9682 47.8894 55.7163 47.1074 55.3855C45.1385 54.5532 42.4501 53.0642 39.9928 50.5129C39.5766 50.0802 39.1668 49.6172 38.7683 49.1219H25.225C24.2139 49.1219 23.3913 48.2993 23.3913 47.2881V42.8808H22.0002V47.2881C22.0002 49.0662 23.447 50.5129 25.225 50.5129H38.1151C39.2822 51.8785 40.6098 53.0988 42.0725 54.1493C43.4747 55.1565 45.0031 56.0102 46.6155 56.688C49.3701 57.8454 51.4079 58.0865 51.4932 58.0962L54.0002 58.3813L51.9931 56.852Z"
                fill="#273C97"
            />
            <path
                d="M38 38.4495V27.4894C38 25.7114 36.5533 24.2646 34.7752 24.2646H10.958C9.18 24.2646 7.73324 25.7114 7.73324 27.4894V38.4495C7.73324 40.2275 9.17996 41.6743 10.958 41.6743H12.6318C12.4485 42.0956 12.2191 42.584 11.9432 43.1061C11.172 44.5643 9.86319 46.5991 8.00715 48.0133L6 49.5426L8.50729 49.2575C8.5927 49.2478 10.6304 49.0068 13.385 47.8494C14.9974 47.1716 16.5258 46.3178 17.928 45.3107C18.9451 44.58 19.8972 43.7674 20.7746 42.8808C21.1593 42.4926 21.5298 42.0902 21.8851 41.6743H34.7752C36.5533 41.6743 38 40.2276 38 38.4495ZM27.5841 38.8279C26.8823 38.8279 26.0486 38.7548 25.6099 38.638C24.7664 38.4058 23.4222 37.4886 22.3119 36.7074C19.6604 36.696 17.5373 34.6374 17.5373 31.8231C17.5373 29.2053 19.3945 26.7777 22.4804 26.7777C24.9812 26.7777 27.2185 28.6934 27.2185 31.6037C27.2185 34.0373 25.9043 35.7611 24.0629 36.4161C25.3228 37.3115 26.6001 38.0756 27.6867 38.4772L27.5841 38.8279Z"
                fill="#273C97"
            />
            <path
                d="M25.727 32.013C25.727 28.9419 24.1038 27.3184 22.188 27.3184C20.5499 27.3184 19.0583 28.6931 19.0583 31.428C19.0583 33.943 20.32 35.8271 22.138 36.1135L23.1677 35.7568C23.2817 35.8436 23.3959 35.9296 23.5108 36.0147C24.7562 35.6123 25.727 34.3124 25.727 32.013Z"
                fill="#273C97"
            />
            <path
                d="M37.5464 46.4014H40.9246V45.9919C39.7254 45.8457 39.5207 45.7287 39.8424 44.7781C40.0471 44.1785 40.2666 43.6082 40.5005 42.9501H43.6594C43.9665 43.8714 44.259 44.6026 44.4491 45.173C44.6538 45.7287 44.5369 45.8749 43.5862 45.9919V46.4014H47.1984V45.9919C46.1601 45.875 46.0285 45.7579 45.5605 44.4856C44.6392 41.9556 43.6302 39.1917 42.7088 36.6909L42.2409 36.7787L39.2136 44.4564C38.7019 45.7726 38.4678 45.8749 37.5465 45.9919V46.4014H37.5464ZM42.1237 38.6067L43.4546 42.3505H40.7198C41.1586 41.1952 41.6558 39.8644 42.1237 38.6067Z"
                fill="#273C97"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1424_4457"
                    x1={64.076}
                    y1={0.00000184103}
                    x2={36.0992}
                    y2={72.1983}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#859CFF" stopOpacity={0.44} />
                    <stop offset={1} stopColor="#F1F8FF" />
                </linearGradient>
            </defs>
        </svg>
    )
}
