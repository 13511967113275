import React from "react";
import arrowImage from "../../../assets/arrow-up 1.png";
import { ArrowDownward } from "@mui/icons-material";

function CardsInfo({ cards }) {
  return (
    <div
      className={`flex flex-wrap items-center justify-between bg-white p-4 rounded-xl shadow-md`}
    >
      {cards.map((card, index) => (
        <div
          key={index}
          className={`flex flex-1 items-center gap-4 p-6  ${
            index !== cards.length - 1 ? "tablet:border-r-2" : "border-none"
          }`}
        >
          {card.img}
          <div>
            <p className="text-xs text-gray-400">{card.title}</p>
            <p className="text-2xl font-bold text-gray-900 my-1">
              {card.value}
            </p>
            {card.rejectedNumber > 0 && (
              <div className="flex gap-1 items-center">
                <ArrowDownward
                  fontSize="small"
                  className="text-red-250 font-bold block"
                />
                <span className="block text-red-250 font-[700] text-xs">
                  {card.rejectedNumber}
                </span>
                <span className="text-[#292D32] text-xs block">Rejected</span>
              </div>
            )}
            {card.active && (
              <p className="text-sm">
                <span className="font-semibold text-green-600 me-1">
                  <img src={arrowImage} className="inline-block mb-1" />
                  {card.active}
                </span>
                Active
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default CardsInfo;
